import React, { useState } from 'react';

import { Theme, styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  animated,
  useSpring,
  config
} from '@react-spring/web'

import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import BedroomIcon from '../assets/svg/propy-bedroom-icon.svg';
import BathRoomIcon from '../assets/svg/propy-bathroom-icon.svg';
import SquareSizeIcon from '../assets/svg/propy-size-icon.svg';

import ExternalLink from './ExternalLink';

import HoverShiftContainer from '../containers/HoverShiftContainer';

import {
  IListing
} from '../interfaces';

import {
  extractPropertyLotSize,
  priceFormat,
} from '../utils';

const StyledCardActionArea = styled(CardActionArea)(({theme}) => `
    border-radius: 20px;
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
    card: {
      width: '100%',
      maxWidth: 300,
      height: '100%',
    },
    cardDescriptionClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
    },
    cardText: {
      paddingTop: theme.spacing(2),
    },
    cardPriceClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      fontWeight: 'bold',
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      fontSize: 16,
    },
    statIconsRow: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    statIconSpacer: {
      marginRight: theme.spacing(2),
    },
    singleStatContainer: {
      display: 'flex',
    },
    statIconText: {
      marginLeft: theme.spacing(1)
    },
    statIcon: {

    },
    selectionBackground: {
      padding: theme.spacing(1.5),
      borderRadius: 20,
    },
    cardMedia: {
      borderRadius: 8,
    },
    cardButton: {
      borderRadius: 50,
      padding: theme.spacing(1),
      display: 'inline-block',
    }
  }),
);

interface IListingCardUncontainedProps {
  listing?: IListing
}

const ListingCardUncontained = (props: IListingCardUncontainedProps) => {
    const classes = useStyles();

    const [hovered, setHovered] = useState(false);

    const {
      listing,
    } = props;

    const hoverSpring = useSpring({
      from: { backgroundColor: "#ffffff" },
      to: { backgroundColor: hovered ? "#ebebeb" : "#ffffff" },
      config: config.stiff,
    })

    const buttonSpring = useSpring({
      from: {
        backgroundColor: "#EFEFEF",
        color: '#111111',
      },
      to: { 
        backgroundColor: hovered ? "#000000" : "#EFEFEF",
        color: hovered ? "#FFF" : '#111111',
      },
      config: config.stiff,
    })

    return (
      <div className={classes.root}>
        <ExternalLink fullWidth={true} href={`https://propy.com/property/${listing?.id}`}>
          <HoverShiftContainer>
            <StyledCardActionArea 
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onTouchStart={() => setHovered(true)}
              onTouchEnd={() => setHovered(false)}
            >
              <animated.div className={classes.selectionBackground} style={hoverSpring}>
                <CardMedia
                  component="img"
                  height="250"
                  image={listing?.imageUrls[0]}
                  alt="featured property media"
                  className={[classes.cardMedia, "box-shadow-default"].join(' ')}
                />
                <div className={classes.cardText}>
                  <Typography className={classes.cardPriceClamp} gutterBottom variant="h5" component="div">
                    {priceFormat(`${listing?.price?.value}`, 0)}
                  </Typography>
                  <Typography className={classes.cardTitleClamp} gutterBottom variant="h5" component="div">
                    {listing?.address}
                  </Typography>
                  <div className={classes.statIconsRow}>
                    {listing?.bedrooms &&
                      <div className={[classes.statIconSpacer, classes.singleStatContainer].join(" ")}>
                        <img src={BedroomIcon} className={classes.statIcon} alt="property bedroom count icon" />
                        <Typography style={{fontWeight: 'bold'}} className={classes.statIconText} variant="subtitle1">
                          {listing?.bedrooms}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\u00A0bd`}
                        </Typography>
                      </div>
                    }
                    {listing?.bathrooms &&
                      <div className={[classes.statIconSpacer, classes.singleStatContainer].join(" ")}>
                        <img src={BathRoomIcon} className={classes.statIcon} alt="property bathroom count icon" />
                        <Typography style={{fontWeight: 'bold'}} className={classes.statIconText} variant="subtitle1">
                          {listing?.bathrooms}
                        </Typography>
                        <Typography variant="subtitle1">
                          {`\u00A0ba`}
                        </Typography>
                      </div>
                    }
                    {listing?.size &&
                      <div className={classes.singleStatContainer}>
                        <img src={SquareSizeIcon} className={classes.statIcon} alt="property size icon" />
                        <Typography style={{fontWeight: 'bold'}} className={classes.statIconText} variant="subtitle1">
                          {listing?.size && extractPropertyLotSize(listing?.size, 'value')}
                        </Typography>
                        <Typography variant="subtitle1">
                          {listing?.size && `\u00A0${extractPropertyLotSize(listing?.size, 'label')}`}
                        </Typography>
                      </div>
                    }
                  </div>
                  {/* <Typography className={classes.cardDescriptionClamp} variant="body2" color="text.secondary">
                    {listing?.description}
                  </Typography> */}
                  <animated.div className={classes.cardButton} style={buttonSpring}>
                    View Listing
                  </animated.div>
                </div>
              </animated.div>
            </StyledCardActionArea>
          </HoverShiftContainer>
        </ExternalLink>
      </div>
    )
};

export default ListingCardUncontained;