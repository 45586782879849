import React, {useState, useEffect} from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Card from '@mui/material/Card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: '#FFFFFF',
      // boxShadow: '0px 6px 20px 0px #8e98a063',
    },
    lightToneContainer: {
      width: '100%',
      background: '#FFFFFF',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    darkToneOuterContainer: {
      width: '100%',
      background: '#f7f8fa',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'auto',
    },
    darkToneInnerContainer: {
      minWidth: 405,
    }
  }),
);

type ISimpleCardProps = {
  defaultOrientation?: "row" | "column"
  elevation?: number
  isMobile?: Boolean
  selectedBlock?: Boolean
  children?: any
  className?: string
}

const SimpleCard = (props: ISimpleCardProps) => {
  const classes = useStyles();

  const { 
    defaultOrientation = 'column',
    isMobile,
    children,
    className = '',
    elevation = 0,
  } = props;

  const [flexDirection, setFlexDirection] = useState(defaultOrientation);

  useEffect(() => {
    if(isMobile) {
      setFlexDirection('column');
    } else {
      setFlexDirection(defaultOrientation);
    }
  }, [defaultOrientation, isMobile])
  
  return (
    <Card elevation={elevation} className={[classes.root, className].join(" ")} style={{display: 'flex', flexDirection: flexDirection}}>
      {children}
    </Card>
  )
}

export default SimpleCard