import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import { PropsFromRedux } from '../containers/HighlightedVideoContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    youtubeEmbedInner: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      position: 'relative',
    },
    youtubeIframe: {
      aspectRatio: '16/9',
      borderRadius: '15px',
    }
  }),
);

interface IHighlightedVideo {
  initialDelay?: number
  hideHeading?: boolean
  useSingular?: boolean
  showVideo?: boolean
  title: string
  subtitle: string
  videoUrl: string
}

const HighlightedVideo = (props: IHighlightedVideo & PropsFromRedux) => {
    const classes = useStyles();

    const {
      // isConsideredMobile,
      initialDelay = 0,
      title,
      subtitle,
      videoUrl,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: initialDelay
    })

    return (
      <div className={classes.root} ref={ref}>
        <animated.div style={titleSpring}>
          <div className={classes.headingText}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              {title}
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              {subtitle}
            </Typography>
          </div>
          <div className={classes.youtubeEmbedInner}>
            {/* @ts-ignore */}
            <iframe className={classes.youtubeIframe} width="100%" height="auto" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </animated.div>
      </div>
    )
};

export default HighlightedVideo;