import { connect, ConnectedProps } from 'react-redux';

import AgentOnboardingStep4ScheduleMeeting from '../../components/AgentOnboardingSteps/AgentOnboardingStep4ScheduleMeeting';

import { IAgentFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    agentFlow: IAgentFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    agentFlow: state.agentFlow,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentOnboardingStep4ScheduleMeeting)