import { connect, ConnectedProps } from 'react-redux';

import {
  setAgentFlowVersion
} from '../state/actions';

import ABGate from '../components/ABGate';

import { IAgentFlowData } from '../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  agentFlow: IAgentFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  agentFlow: state.agentFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setAgentFlowVersion,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ABGate)