import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import RefreshIcon from '@mui/icons-material/Refresh';

import { Link } from "react-router-dom";

import { animated, useSpring, useInView } from '@react-spring/web';

import ExternalLink from '../ExternalLink';
import FloatingActionButton from '../FloatingActionButton';

import { PropsFromRedux } from '../../containers/BuyerOnboardingSteps/BuyerOnboardingThankYouContainer';

import {
  BASE_URL,
  PROJECT_ROOT,
  STATE_TO_MAP_QUERY,
} from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
    },
    formSubtitle: {
      fontSize: '18px',
    },
    formGetCertifiedTitle: {
      fontSize: '26px',
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    helperTextContainer: {
      marginTop: theme.spacing(2),
      fontSize: '22px',
    },
    createListingButton: {
      marginTop: theme.spacing(2),
    },
    courseCardContainer: {
      marginBottom: theme.spacing(2),
    },
    quickLinkCardContainer: {
      marginBottom: theme.spacing(4),
    },
    postTitleSpacing: {
      marginTop: theme.spacing(6),
    }
  }),
);

interface IBuyerOnboadingThankYou {
  showBrowseNow?: boolean;
  showWelcomeAboard?: boolean;
}

export default function BuyerOnboardingThankYou(props: IBuyerOnboadingThankYou & PropsFromRedux) {

  let {
    showBrowseNow = false,
    showWelcomeAboard = false,
    buyerFlow,
  } = props;

  const classes = useStyles();

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  const buildMapParams = () => {
    let params = '';
    if(buyerFlow.desiredLocation && STATE_TO_MAP_QUERY[buyerFlow.desiredLocation]) {
      params += STATE_TO_MAP_QUERY[buyerFlow.desiredLocation];
    }
    let priceFilter = '';
    if(buyerFlow.priceRange) {
      if(buyerFlow.priceRange === '<$200k') {
        priceFilter = 'price=0,200000,1'
      } else if(buyerFlow.priceRange === '$200k - $500k') {
        priceFilter = 'price=200000,500000,1'
      } else if(buyerFlow.priceRange === '$500k - $999k') {
        priceFilter = 'price=500000,999999,1'
      } else if(buyerFlow.priceRange === '$1m+') {
        priceFilter = 'price=,1000000,1'
      }
      if(priceFilter.length > 0) {
        if(params.length > 0) {
          params += `&${priceFilter}`;
        } else {
          params += `?${priceFilter}`;
        }
      }
    }

    return params;
  }

  return (
    <animated.div ref={ref} style={stepperSpring}>
      {showWelcomeAboard &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Welcome aboard!
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            Now you can embrace Propy to take your real estate business to the next level.
          </Typography>
        </>
      }
      {showBrowseNow &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Thank you!
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            Your Propy account has been created and you may now browse listings.
          </Typography>
          <ExternalLink href={`${BASE_URL}/map${buildMapParams()}`}>
            <FloatingActionButton
              className={classes.createListingButton}
              text="Browse Listings"
            />
          </ExternalLink>
        </>
      }
      <Link replace to={`${PROJECT_ROOT}/get-started/buyer/reset`} style={{color: 'inherit'}}>
        <Typography className={[classes.helperTextContainer, 'flex-vertical-center', 'interactive'].join(' ')} gutterBottom variant="h6" component="div">
          <RefreshIcon style={{marginRight: 8}} /> Start over
        </Typography>
      </Link>
    </animated.div>
  );
}