import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

// import AgentOnboardingStepsSingleFormContainer from '../containers/AgentOnboardingStepsSingleFormContainer';
import ABGateContainer from '../containers/ABGateContainer';
import BuyerOnboardingStepsContainer from '../containers/BuyerOnboardingStepsContainer';
import SellerOnboardingStepsContainer from '../containers/SellerOnboardingStepsContainer';
import PersonaSelectionCardContainer from '../containers/PersonaSelectionCardContainer';
import OnboardingFlowCookieGateContainer from '../containers/OnboardingFlowCookieGateContainer';

import { PropsFromRedux } from '../containers/GetStartedSurveyPageContainer';

import OnboardingFlowWrapper from '../components/OnboardingFlowWrapper';

import {
  RECAPTCHA_KEY,
} from '../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
    },
    card: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '250px',
    },
    exampleImage: {
        width: '30%',
        margin: theme.spacing(4),
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
    },
    personaCardContainerMobile: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    recaptchaContainer: {
      display: 'none',
    }
  }),
);

const GetStartedSurveyPage = (props: PropsFromRedux) => {
    const classes = useStyles();

    let { 
      userType,
      onboardingStep = "",
    } = useParams();

    let {
      consentToCookies,
    } = props;

    const recaptchaRef = useRef(null);

    return (
      <OnboardingFlowWrapper>
        <Container maxWidth="lg" className={classes.container}>
          {!consentToCookies &&
            <OnboardingFlowCookieGateContainer />
          }
          {consentToCookies &&
            <>
              {!userType &&
                <>
                  <div className={classes.personaCardContainerMobile}>
                    <PersonaSelectionCardContainer />
                  </div>
                </>
              }
              {/* {userType === 'agent' &&
                <AgentOnboardingStepsSingleFormContainer 
                  onboardingStep={onboardingStep}
                  recaptchaRef={recaptchaRef}
                />
              } */}
              {userType === 'agent' &&
                <ABGateContainer
                  type="agent"
                  onboardingStep={onboardingStep}
                  recaptchaRef={recaptchaRef}
                />
              }
              {userType === 'buyer' &&
                <BuyerOnboardingStepsContainer
                  onboardingStep={onboardingStep}
                  recaptchaRef={recaptchaRef}
                />
              }
              {userType === 'seller' &&
                <SellerOnboardingStepsContainer
                  onboardingStep={onboardingStep}
                  recaptchaRef={recaptchaRef}
                />
              }
              <div 
                className={classes.recaptchaContainer}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  badge="bottomleft"
                  sitekey={RECAPTCHA_KEY}
                />
              </div>
            </>
          }
        </Container>
      </OnboardingFlowWrapper>
    )
};

export default GetStartedSurveyPage;