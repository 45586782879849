import React, {useState, useEffect, Fragment} from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { PropsFromRedux } from '../containers/NavigationLeftSideBarContainer';
import { 
  MENU_ENTRIES,
  BASE_URL,
} from '../constants';
import LinkWrapper from './LinkWrapper';
import ExternalLink from './ExternalLink';
import FloatingActionButton from './FloatingActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    menuEntryTopLevelIcon: {
      marginRight: theme.spacing(1),
      color: "#37a6fb",
    },
    menuEntryTopLevelIconLight: {
      marginRight: theme.spacing(1),
      opacity: 0.4,
    },
    mobileLaunchAppButton: {
      width: '100%',
    },
    signInButtonContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    closeButtonText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  })
);

function NavigationLeftSideBar(props: PropsFromRedux) {
  const classes = useStyles();

  let {
    //   darkMode
    // isConsideredMobile,
    signedIn,
  } = props;

  const [openCollapseSections, setOpenCollapseSections] = useState<Number[]>([]);

  const [localShowLeftMenu, setLocalShowLeftMenu] = useState(false)

  useEffect(() => {
    setLocalShowLeftMenu(props.showLeftMenu)
  }, [props.showLeftMenu])

  const toggleDrawer = (setOpen: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    props.setShowLeftMenu(setOpen)
  };

  const toggleOpenCollapseState = (navigationMenuIndex: number) => {
    // Check if the navigationMenuIndex is currently in openCollapseSections
    let indexInCurrentState = openCollapseSections.indexOf(navigationMenuIndex)
    if(indexInCurrentState === -1) {
      // Currently not open
      // We add this index to the list of open collapsable areas to open it
      let newOpenCollapseSections = [...openCollapseSections, navigationMenuIndex];
      setOpenCollapseSections(newOpenCollapseSections);
    } else {
      // Currently open
      // We remove this index from the list of open collapsable areas to close it
      let newOpenCollapseSections = [...openCollapseSections].filter(item => item !== navigationMenuIndex);
      setOpenCollapseSections(newOpenCollapseSections);
    }
  }

  return (
    <div>
        <React.Fragment key={'left'}>
            <Drawer 
              elevation={0} 
              anchor={'right'}
              open={localShowLeftMenu}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  background: '#ffffff',
                  borderRadius: '16px',
                  backdropFilter: 'blur(10px)',
                }
              }}
            >
                <div
                    className={classes.list}
                    role="presentation"
                >
                  <div className={classes.closeButtonContainer}>
                    {/* <CloseIcon onClick={() => props.setShowLeftMenu(false)}/> */}
                    <div className={"grey-chip-interactive-icon"} onClick={() => props.setShowLeftMenu(false)}>
                      <Typography className={classes.closeButtonText} variant="subtitle2" component="div">
                        <ArrowBackIcon style={{marginRight: 4, fontSize: 20}}/> Back
                      </Typography>
                    </div>
                  </div>
                  <List>
                    {MENU_ENTRIES.map((item, index) => 
                        <Fragment key={`parent-${index}`}>
                          <LinkWrapper key={`desktop-menu-${index}`} link={item.link ? item.link : ''} external={item.external}>
                            <ListItem 
                              onClick={() => {
                                if(item.link) {
                                  // navigate(item.link)
                                  props.setShowLeftMenu(false)
                                } else if (item.innerEntries) {
                                  toggleOpenCollapseState(index)
                                }
                              }}
                              button
                            >
                              {/* {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>} */}
                              {!item.innerEntries && item.img &&
                                <img alt={`${item.label} icon`} src={item.img} className={classes.menuEntryTopLevelIcon}/>
                              }
                              <ListItemText primary={<Typography variant="h6" style={{ fontWeight: 'bold' }}>{item.label}</Typography>} />
                              {item.innerEntries &&
                                <>
                                  {openCollapseSections.indexOf(index) > -1 ? <ExpandLess /> : <ExpandMore />}
                                </>
                              }
                            </ListItem>
                          </LinkWrapper>
                          {item.innerEntries &&
                            <Collapse in={openCollapseSections.indexOf(index) > -1} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {item.innerEntries.map((column, columnIndex) =>
                                    <Fragment key={`mobile-menu-${columnIndex}`}>
                                      {column.map((child, childIndex) => 
                                        <LinkWrapper key={`mobile-menu-${index}-${childIndex}`} link={child.link ? child.link : ''} external={child.external}>
                                          <ListItemButton
                                            onClick={() => {
                                              if(child.link && child.link.length > 0) {
                                                // navigate(child.link)
                                                props.setShowLeftMenu(false)
                                              }
                                            }}
                                            key={`child-${index}-${childIndex}`}
                                            sx={{ pl: 4 }}
                                          >
                                            {/* {child.icon && <ListItemIcon>{child.icon}</ListItemIcon>} */}
                                            <ListItemText primary={child.label} />
                                          </ListItemButton>
                                        </LinkWrapper>
                                      )}
                                    </Fragment>
                                )}
                              </List>
                            </Collapse>
                          }
                        </Fragment>
                    )}
                  </List>
                  <div className={classes.signInButtonContainer}>
                    {signedIn ?
                      <ExternalLink target="_self" style={{width: '100%'}} href={`${BASE_URL}/dashboard`}>
                        <FloatingActionButton
                          text="Go To Platform"
                          className={classes.mobileLaunchAppButton}
                        />
                      </ExternalLink>
                    :
                      <ExternalLink target="_self" style={{width: '100%'}} href={`${BASE_URL}/login`}>
                        <FloatingActionButton
                          text="Sign in"
                          className={classes.mobileLaunchAppButton}
                        />
                      </ExternalLink>
                    }
                  </div>
                </div>
            </Drawer>
        </React.Fragment>
    </div>
  );
}

export default NavigationLeftSideBar;