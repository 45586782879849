import { connect, ConnectedProps } from 'react-redux';

import {
  setBuyerFlowProfileInfo,
  setBuyerFlowProfileInfoFirstName,
  setBuyerFlowProfileInfoLastName,
  setBuyerFlowProfileInfoEmailAddress,
  setBuyerFlowProfileInfoPhoneNumber,
  setBuyerFlowProfileInfoPhoneNumberCountry,
  setBuyerFlowCurrentStep,
  setBuyerFlowProfileAvailability,
  setSignedIn,
} from '../../state/actions';

import BuyerOnboardingStep3ProfileInfo from '../../components/BuyerOnboardingSteps/BuyerOnboardingStep3ProfileInfo';

import { IBuyerFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    buyerFlow: IBuyerFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    buyerFlow: state.buyerFlow,
})

const mapDispatchToProps = {
  setBuyerFlowProfileInfo,
  setBuyerFlowProfileInfoFirstName,
  setBuyerFlowProfileInfoLastName,
  setBuyerFlowProfileInfoEmailAddress,
  setBuyerFlowProfileInfoPhoneNumber,
  setBuyerFlowProfileInfoPhoneNumberCountry,
  setBuyerFlowCurrentStep,
  setBuyerFlowProfileAvailability,
  setSignedIn,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(BuyerOnboardingStep3ProfileInfo)