import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import LinkWrapper from './LinkWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    card: {
      width: '100%',
    },
    cardDescriptionClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
    },
    badgeImage: {
      width: 115,
      position: 'absolute',
      zIndex: 10,
      top: '-20px',
      left: '30px',
    },
  }),
);

interface IVerticalImageCard {
  image: string
  title: string
  description?: string
  link: string
  external?: boolean
  actionNameGA?: string
}

const VerticalImageCard = (props: IVerticalImageCard) => {
    const classes = useStyles();

    const {
      image,
      title,
      description,
      link,
      external = false,
      actionNameGA,
    } = props;

    return (
      <div className={classes.root}>
        <LinkWrapper actionNameGA={actionNameGA} link={link} external={external} className={classes.card}>
          <Card elevation={3} className={classes.card}>
            <CardActionArea>
              <div>
                <CardMedia
                  component="img"
                  height="100"
                  image={image}
                  alt={`${title} media`}
                />
              </div>
              <div>
                <CardContent>
                  <Typography className={classes.cardTitleClamp} variant="h5" component="div">
                    {title}
                  </Typography>
                  {description && 
                    <Typography className={classes.cardDescriptionClamp} variant="body2" color="text.secondary">
                      {description}
                    </Typography>
                  }
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </LinkWrapper>
      </div>
    )
};

export default VerticalImageCard;