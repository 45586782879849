import { createStore } from 'redux'
import rootReducer, { RootState } from './reducers'
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
    setCookie,
  } from '../utils';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ["darkMode"],
    stateReconciler: autoMergeLevel2
};

//@ts-ignore
const pReducer = persistReducer<RootState>(persistConfig, rootReducer);

const storeExport = createStore(
    pReducer,
    composeWithDevTools(
        //applyMiddleware(sagaMiddleware),
    )
);

storeExport.subscribe(() => {
    const state = store.getState();
    
    // Check if the consent value is true and the page hasn't been reloaded yet
    if (state.consentToCookies && !localStorage.getItem('cookieConsentHasReloaded')) {
        // Set the flag to indicate that the page has been reloaded
        localStorage.setItem('cookieConsentHasReloaded', 'true');
        
        // Reload the web page
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }
    if(state.consentToCookies && localStorage.getItem('cookieConsentHasReloaded')) {
        setCookie("cookieconsent_status", "dismiss", 365);
    }
  });

//sagaMiddleware.run(rootSaga);

export const store = storeExport;
export const persistor = persistStore(storeExport);

export default store;