import React, { useState, useEffect } from 'react';

import { toast } from 'sonner'

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { MuiTelInput } from 'mui-tel-input';
import parsePhoneNumberFromString from 'libphonenumber-js';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

import { animated, useSpring, useInView } from '@react-spring/web';
import * as yup from 'yup';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import { PropsFromRedux } from '../../containers/AgentOnboardingSteps/AgentOnboardingStep2ProfileInfoContainer';

import { 
  PROPY_BLUE_DARK,
  FLOW_TYPE_TO_LEAD_TYPE,
} from '../../constants';

import FloatingActionButton from '../FloatingActionButton';
// import ExternalLink from '../ExternalLink';

import { 
  FlowService,
  UserService,
} from '../../services/api';

import {
  storeAuthTokens,
  IdentityServerPropyAuthority
} from '../../services/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    formSubtitle: {
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      fontSize: '18px',
    },
    formInfo: {
      fontWeight: '300',
      fontSize: '16px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    submitButtonContainer: {
      marginTop: theme.spacing(4),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    }
  }),
);

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any
}

export default function AgentOnboardingStep3(props: PropsFromRedux & IStepProps) {

  const {
    setAgentFlowAgentProfileInfo,
    setAgentFlowProfileInfoFirstName,
    setAgentFlowProfileInfoLastName,
    setAgentFlowProfileInfoEmailAddress,
    setAgentFlowProfileInfoPhoneNumber,
    setAgentFlowProfileInfoPhoneNumberCountry,
    setAgentFlowCurrentStep,
    setAgentFlowAgentProfileAvailability,
    agentFlow,
    setStepSubmitting,
    setSignedIn,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const [lastCheckedNumber, setLastCheckedNumber] = useState('');
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [hasTriedSubmit, setHasTriedSubmit] = useState(false);

  const ValidationSchema = yup.object().shape({
    firstName: yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    emailAddress: yup.string().email('Invalid email').required('Required'),
    hiddenField: yup.string().test('hiddenField', 'hiddenField', function (value) { setHasTriedSubmit(true); return true }),
    phoneNumber: yup.string()
    .test("validPhone", 'Invalid phone number', 
      function (value) {
        if(value) {
          let parsed = parsePhoneNumberFromString(value);
          if(parsed?.nationalNumber && parsed?.countryCallingCode) {
            let currentNumber = `${parsed?.number}`;
            let currentCountryCallingCode = `${parsed?.countryCallingCode}`;
            let currentNationalNumber = `${parsed?.nationalNumber}`;
            if(lastCheckedNumber !== parsed?.number) {
              setIsValidatingPhone(true);
              return new Promise(async (resolve) => {
                if(currentNationalNumber && currentCountryCallingCode) {
                  setIsValidPhone(false);
                  await UserService.getPhoneInfo(currentNationalNumber, currentCountryCallingCode)
                    .then(response => {
                      if(response?.data?.message === "Success") {
                        setIsValidPhone(true);
                        if(parsed?.number) {
                          setLastCheckedNumber(currentNumber);
                        }
                        resolve(true);
                      } else {
                        if(parsed?.number) {
                          setLastCheckedNumber(currentNumber);
                        }
                        resolve(false);
                      }
                    })
                    .catch(e => {
                      if(parsed?.number) {
                        setLastCheckedNumber(currentNumber);
                      }
                      resolve(false);
                    });
                  setIsValidatingPhone(false);
                }
              });
            } else {
              return isValidPhone;
            }
          } else {
            setIsValidatingPhone(false);
            return false;
          }
        } else {
          setIsValidPhone(false);
          setIsValidatingPhone(false);
          return false;
        }
      }
    )
  });

  const classes = useStyles();

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  useEffect(() => {
    if(!agentFlow.phoneNumber || agentFlow.phoneNumber.length === 0) {
      setIsValidPhone(false);
      setLastCheckedNumber('');
    }
  }, [agentFlow.phoneNumber])

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} variant="h5" component="div">
        Almost there!
        {/* <br/>Please tell us more about yourself */}
      </Typography>
      {/* <Typography className={classes.formSubtitle} variant="h6" component="h6">
        Let’s make an account for you.
      </Typography> */}
      <Formik
        validateOnChange={hasTriedSubmit}
        validateOnBlur={hasTriedSubmit}
        initialValues={{
          firstName: typeof agentFlow.firstName === 'string' ? agentFlow.firstName : '',
          lastName: typeof agentFlow.lastName === 'string' ? agentFlow.lastName : '',
          emailAddress: typeof agentFlow.emailAddress === 'string' ? agentFlow.emailAddress : '',
          phoneNumber: typeof agentFlow.phoneNumber === 'string' ? agentFlow.phoneNumber : '',
          phoneNumberCountry: typeof agentFlow.phoneNumberCountry === 'string' ? agentFlow.phoneNumberCountry : '',
          hiddenField: "hidden",
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if(!recaptchaRef?.current) {
              toast.error("Recaptcha expired, please refresh the page.");
            }
            if(agentFlow.sessionId && recaptchaRef.current) {
              setSubmitting(true);
              setAgentFlowAgentProfileInfo(values);
              let successMessage = 'Saved progress!';
              setStepSubmitting(true);
              const tokenPatch = await getToken();
              await FlowService.patchSession(agentFlow.sessionId, {
                Type: FLOW_TYPE_TO_LEAD_TYPE["agent"],
                ...(agentFlow.version && { Version: agentFlow.version }),
                ...(agentFlow.location && { Location: { placeDesc: agentFlow.location } }),
                ...(agentFlow.dealsClosedBracket && { DealsClosed: agentFlow.dealsClosedBracket }),
                ...(values.firstName && { FirstName: values.firstName }),
                ...(values.lastName && { LastName: values.lastName }),
                ...(values.emailAddress && { Email: values.emailAddress }),
                ...(values.phoneNumber && { PhoneNumber: values.phoneNumber }),
                ...(values.phoneNumberCountry && { PhoneCountryCode: values.phoneNumberCountry }),
              }, tokenPatch);
              if(values.emailAddress) {
                let registerEmailStatus = "Existing";
                let canRegisterPhone = false;
                let parsed = parsePhoneNumberFromString(values.phoneNumber);
                if(parsed?.nationalNumber && parsed?.countryCallingCode) {
                  let currentCountryCallingCode = `${parsed?.countryCallingCode}`;
                  let currentNationalNumber = `${parsed?.nationalNumber}`;
                  let countryCode = `${parsed?.country}`;
                  let [canRegisterEmailResponse, phoneExistsResponse] = await Promise.all([
                    UserService.canRegister(values.emailAddress),
                    UserService.phoneExists(currentNationalNumber, currentCountryCallingCode, countryCode)
                  ]);
                  registerEmailStatus = canRegisterEmailResponse?.data?.status;
                  canRegisterPhone = !phoneExistsResponse?.data;
                }
                if(registerEmailStatus) {
                  setAgentFlowAgentProfileAvailability(registerEmailStatus);
                }
                // if available, register account
                if(registerEmailStatus === "Available" && canRegisterPhone && recaptchaRef.current) {
                  const token = await getToken();
                  // run user registration
                  await UserService.registerUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    isAgent: true,
                    leadUUID: agentFlow.sessionId,
                  }, token);
                  // Log user in
                  let loginResponse = await UserService.loginFreshlyUnclaimedUser(values.emailAddress);
                  let {
                    refresh_token,
                    access_token,
                    expires_in,
                    auth_time,
                    profile,
                    scope,
                    token_type,
                  } = loginResponse;
                  if(refresh_token && access_token && expires_in && auth_time && profile) {
                    storeAuthTokens(access_token, refresh_token, expires_in, auth_time);
                    let idServer = new IdentityServerPropyAuthority();
                    idServer.setUser({
                      access_token,
                      refresh_token,
                      token_type,
                      scope,
                      profile,
                      expires_at: (Number(auth_time) + expires_in).toString(),
                    })
                    successMessage = 'Saved progress & created account!';
                    setSignedIn(true);
                  } else {
                    setSignedIn(false);
                  }
                }
              }
              setStepSubmitting(false);
              setSubmitting(false);
              toast.success(successMessage);
              // if submit success
              setAgentFlowCurrentStep("deals-closed");
            }
          } catch(e) {
            console.log({e})
            setSubmitting(false);
            setStepSubmitting(false);
            toast.error("Unable to save progress, please try again or contract support");
            // TODO handle errors
          }
        }}
      >
        {({ submitForm, isSubmitting, handleChange, setFieldValue, errors }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  fullWidth
                  required
                  name="firstName"
                  type="text"
                  label="First name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAgentFlowProfileInfoFirstName(event.target.value)
                    handleChange(event)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  fullWidth
                  required
                  name="lastName"
                  type="text"
                  label="Last name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAgentFlowProfileInfoLastName(event.target.value)
                    handleChange(event)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={TextField}
                  fullWidth
                  required
                  name="emailAddress"
                  type="text"
                  label="Email Address"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAgentFlowProfileInfoEmailAddress(event.target.value)
                    handleChange(event)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  component={MuiTelInput}
                  defaultCountry="US"
                  focusOnSelectCountry
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      isValidatingPhone ? 
                        <InputAdornment position="end">
                          <CircularProgress style={{width: 25, height: 25}}/>
                        </InputAdornment>
                      : 
                        <InputAdornment position="end">
                          {isValidPhone ? <CheckCircleOutlineIcon style={{fontSize: 28, color: '#00d000'}} /> : <></>}
                        </InputAdornment>
                    ),
                  }}
                  name="phoneNumber"
                  type="tel"
                  label="Phone number"
                  disabled={isSubmitting}
                  helperText={
                    errors["phoneNumber"] ? 
                      errors["phoneNumber"] 
                    : isValidatingPhone ? "Validating phone number..." : ""}
                  error={errors["phoneNumber"] ? true : false}
                  value={agentFlow.phoneNumber ? agentFlow.phoneNumber : ''}
                  onPaste={(event: ClipboardEvent) => {
                    if(event.clipboardData) {
                      let paste = (event.clipboardData).getData("text").replace(/ /g, '');
                      if(paste && paste.indexOf("+") === 0) {
                        setAgentFlowProfileInfoPhoneNumber(paste)
                        let parsedData = parsePhoneNumberFromString(paste);
                        if(parsedData?.country) {
                          let country = parsedData.country.toLowerCase();
                          setAgentFlowProfileInfoPhoneNumberCountry(country);
                          setFieldValue('phoneNumberCountry', country)
                        }
                        setFieldValue('phoneNumber', paste)
                      }
                    }
                  }}
                  onChange={(value: string, info: any) => {
                    let useFieldValue = !info.numberValue ? value : info.numberValue;
                    let parsedData = parsePhoneNumberFromString(useFieldValue);
                    if(parsedData?.country) {
                      let country = parsedData.country.toLowerCase();
                      setAgentFlowProfileInfoPhoneNumberCountry(country);
                      setFieldValue('phoneNumberCountry', country)
                    }
                    setAgentFlowProfileInfoPhoneNumber(useFieldValue);
                    setFieldValue('phoneNumber', useFieldValue)
                  }}
                />
              </Grid>
              <div style={{display: 'none'}}>
                <Field
                  component={TextField}
                  name="hiddenField"
                  type="text"
                  label="hidden"
                />
                <Field
                  component={TextField}
                  name="phoneNumberCountry"
                  type="text"
                  label="hidden"
                />
              </div>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.formInfo} variant="h6" component="div">
                  Your information is stored securely and never used to send spam. By completing this form you agree to our <ExternalLink href="https://propy.com/browse/terms-and-conditions/">Terms of Service</ExternalLink>.
                </Typography>
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                {isSubmitting && <LinearProgress />}
              </Grid> */}
              <div className={classes.submitButtonContainer}>
                <FloatingActionButton
                  className={classes.submitButton}
                  disabled={isSubmitting || isValidatingPhone}
                  onClick={submitForm}
                  text="Next"
                />
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </animated.div>
  );
}