import { connect, ConnectedProps } from 'react-redux';

import Navigation from '../components/Navigation';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
  consentToCookies: boolean;
  dismissedPopupPropyAI: boolean;
  dismissedPopupAIRealEstateSummit2023: boolean;
  signedIn: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
  consentToCookies: state.consentToCookies,
  dismissedPopupPropyAI: state.dismissedPopupPropyAI,
  dismissedPopupAIRealEstateSummit2023: state.dismissedPopupAIRealEstateSummit2023,
  signedIn: state.signedIn,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Navigation)