import React from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import { animated, useSpring, useInView } from '@react-spring/web';

import parsePhoneNumberFromString from 'libphonenumber-js';

import { PropsFromRedux } from '../../containers/SellerOnboardingSteps/SellerOnboardingStep3TitleAndEscrowEligibilityContainer';

import {
  PROPY_BLUE_DARK,
  PROPY_BLUE,
  BASE_URL,
} from '../../constants';

import {
  IQueryParamCollection,
} from '../../interfaces'

import {
  buildQueryStringFromParams,
} from '../../utils';

import ExternalLink from '../ExternalLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      fontSize: '26px',
    },
    formSubtitle: {
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      fontSize: '18px',
    },
    bodyText: {
      fontWeight: '400',
      marginTop: theme.spacing(3),
      fontSize: '18px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    card: {
      width: '100%',
      // height: 170,
      display: 'flex',
      justifyContent: 'center'
    },
    cardSelected: {
      backgroundColor: PROPY_BLUE,
      color: 'white',
    },
    imageUnselected: {
      opacity: 0.3,
    },
    imageSelected: {
      filter: 'brightness(0) invert(1)',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    cardTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    cardSubtitle: {
      textAlign: 'center',
    },
    listingOptionIcon: {
      height: 70,
      width: 70,
    },
    submitButtonContainer: {
      marginTop: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
  }),
);

const buildListPropertyParams = (sellerFlow: PropsFromRedux['sellerFlow']) => {
  let paramsArray : IQueryParamCollection[] = [];
  if(sellerFlow.propertyMLS) {
    paramsArray.push({
      key: 'mlsId',
      value: sellerFlow.propertyMLS
    });
  }
  if(sellerFlow.propertyLocationPlaceId) {
    paramsArray.push({
      key: 'placeId',
      value: sellerFlow.propertyLocationPlaceId
    });
  }
  if(sellerFlow.propertyLocationDescription) {
    paramsArray.push({
      key: 'placeDesc',
      value: sellerFlow.propertyLocationDescription
    });
  }
  if(sellerFlow.agentEmailAddress) {
    paramsArray.push({
      key: 'agentEmail',
      value: sellerFlow.agentEmailAddress
    });
  }
  if(sellerFlow.agentFirstName) {
    paramsArray.push({
      key: 'agentFirstName',
      value: sellerFlow.agentFirstName
    });
  }
  if(sellerFlow.agentLastName) {
    paramsArray.push({
      key: 'agentLastName',
      value: sellerFlow.agentLastName
    });
  }
  if(sellerFlow.agentPhoneNumber) {
    let parsed = parsePhoneNumberFromString(sellerFlow.agentPhoneNumber);
    if(parsed?.nationalNumber && parsed?.countryCallingCode) {
      paramsArray.push({
        key: 'agentPhone',
        value: parsed?.nationalNumber
      });
      paramsArray.push({
        key: 'agentCountryCode',
        value: parsed?.countryCallingCode
      });
    }
  }
  return buildQueryStringFromParams(paramsArray);
}

const buildPreListParams = (sellerFlow: PropsFromRedux['sellerFlow']) => {
  let paramsArray : IQueryParamCollection[] = [];
  if(sellerFlow.propertyMLS) {
    paramsArray.push({
      key: 'mlsId',
      value: sellerFlow.propertyMLS
    });
  }
  if(sellerFlow.propertyLocationPlaceId) {
    paramsArray.push({
      key: 'placeId',
      value: sellerFlow.propertyLocationPlaceId
    });
  }
  if(sellerFlow.propertyLocationDescription) {
    paramsArray.push({
      key: 'placeDesc',
      value: sellerFlow.propertyLocationDescription
    });
  }
  if(sellerFlow.emailAddress) {
    paramsArray.push({
      key: 'email',
      value: sellerFlow.emailAddress
    });
  }
  return buildQueryStringFromParams(paramsArray);
}

const listingOptions = [
  {
    link: `${BASE_URL}/pre-list`,
    label: "Pre-list",
    description: 'Complete listing & price evaluations',
    icon: RequestQuoteIcon,
    parameterBuilder: buildPreListParams,
  },
  {
    link: `${BASE_URL}/list-property`,
    label: "List Live",
    description: 'Publish to map & receive offers',
    icon: PublishedWithChangesIcon,
    parameterBuilder: buildListPropertyParams,
  },
]

export default function SellerOnboardingStep5ListingOptions(props: PropsFromRedux) {

  const classes = useStyles();

  const {
    sellerFlow,
  } = props;

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Welcome to Propy!
      </Typography>
      <Typography className={classes.formSubtitle} variant="h6" component="h6">
        You may now decide how you wish to list your home.
      </Typography>
      <Grid container spacing={3}>
        {listingOptions.map((item, index) => (
          <Grid key={`location-quick-selection-entry-map-${index}`} item xs={12} sm={6} md={6} lg={6}>
            <Card elevation={3} className={[classes.card].join(" ")}>
              <ExternalLink href={item.parameterBuilder ? `${item.link}${item.parameterBuilder(sellerFlow)}` : item.link}>
                <CardActionArea className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {item.icon && <item.icon height="40" className={[classes.listingOptionIcon, classes.imageUnselected].join(" ")} />}
                    <Typography className={classes.cardTitle} gutterBottom={false} variant="h4" component="div">
                      {item.label}
                    </Typography>
                    <Typography className={classes.cardSubtitle} gutterBottom={false} variant="h6" component="div">
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </ExternalLink>
            </Card>
          </Grid>
        ))}
      </Grid>
    </animated.div>
  );
}