import { connect, ConnectedProps } from 'react-redux';

import {
  setSellerFlowCurrentStep,
  setSellerFlowPropertyLocation,
  setSellerFlowPropertyMLS,
} from '../../state/actions';

import SellerOnboardingStep3CommunityNftLocationFinder from '../../components/SellerOnboardingSteps/SellerOnboardingStep3CommunityNftLocationFinder';

import { ISellerFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    sellerFlow: ISellerFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    sellerFlow: state.sellerFlow,
})

const mapDispatchToProps = {
  setSellerFlowCurrentStep,
  setSellerFlowPropertyLocation,
  setSellerFlowPropertyMLS,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingStep3CommunityNftLocationFinder)