import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'
// import { ChainId, DAppProvider } from '@usedapp/core'

import { IntercomProvider } from 'react-use-intercom';

import './styles/index.scss';
import AppContainer from './containers/AppContainer';
import store, { persistor } from './state';

const INTERCOM_APP_ID = 'zpladqap';

// const mainnetReadOnlyUrl = () => {
//   if(process.env.REACT_APP_INFURA_RPC_URL) {
//     return `${process.env.REACT_APP_INFURA_RPC_URL}`;
//   } else if(process.env.REACT_APP_ALCHEMY_RPC_URL) {
//     return `${process.env.REACT_APP_ALCHEMY_RPC_URL}`;
//   }
//   return '';
// }

// const config = {
//   readOnlyChainId: ChainId.Mainnet,
//   readOnlyUrls: {
//     [ChainId.Mainnet]: mainnetReadOnlyUrl(),
//   },
//   autoConnect: false,
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <DAppProvider config={config}> */}
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <AppContainer />
          </PersistGate>
        </IntercomProvider>
      {/* </DAppProvider> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);