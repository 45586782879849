import React, { useState, useRef, useEffect } from 'react';

import { animated, useSpring, useSprings, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import SimpleCard from './SimpleCard';

import ColdwellBankerLogo from '../assets/img/coldwell-banker-testimonial.png';
import KellerWilliamsLogo from '../assets/img/keller-williams-testimonial.jpeg';
import ExpLogo from '../assets/img/exp-testimonial.webp';
import AmandaJoslinTestimonial from '../assets/img/testimonial-amanda-joslin.webp';
import GabrielleChannellTestiminial from '../assets/img/testimonial-gabrielle-channell.webp';
import PartriotPropertyLogo from '../assets/img/patriot-property-collective-testimonial.png';

import { PROPY_BLUE } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    vouchCard: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      justifyContent: 'space-between',
      minHeight: 340,
    },
    vouchQuoteContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    vouchQuote: {
      fontWeight: 300,
      lineHeight: 1.35,
      textAlign: 'center',
      fontSize: '1.35em',
      maxWidth: 800,
    },
    vouchTitle: {
      textAlign: 'center',
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
    },
    vouchSubtitle: {
      textAlign: 'center',
      lineHeight: 1.2,
    },
    vouchOrgContainer: {
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      marginBottom: theme.spacing(2)
    },
    vouchOrgLogo: {
      maxWidth: '150px',
      maxHeight: '100%',
    },
    vouchOrgTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    vouchTitleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    block: {
      position: 'relative',
    },
    absolute: {
      position: 'absolute',
      width:' calc(100% - 62px)',
    },
    navigationContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navigationCircle: {
      width: 15,
      cursor: 'pointer',
      color: '#CCCCCC',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    navigationCircleSpacer: {
      marginRight: theme.spacing(1),
    },
    selectedNavigationCircle: {
      color: PROPY_BLUE,
    },
    navigationArrow: {
      cursor: 'pointer',
      color: '#a9a9a9',
      width: 18,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigationPageCountContainer: {
      marginLeft: theme.spacing(1),
      color: '#979797',
      display: 'flex',
    }
  }),
);

interface IVouch {
  title?: string;
  subtitle?: string;
  body: string;
  logo: string;
  rounded?: boolean;
}

const vouches : IVouch[] = [
  {
    title: "Erin Blakeslee",
    subtitle: "Coldwell Banker Realty",
    body: "Propy is the future of real estate. It is cohesive in integrating all parties and all documents into one place. This is the platform that all realtors, lenders, escrow companies and title companies need to adapt.",
    logo: ColdwellBankerLogo
  },
  {
    title: "Amanda Joslin",
    subtitle: "NFT Buyer",
    body: "I’ve been a traditional homeowner in the past, I’ve gone through the traditional process, and then going through this process it is like night and day! It was not only a wonderful deal that we got in this purchase but also the process as I say was just super streamlined and easy.",
    logo: AmandaJoslinTestimonial,
    rounded: true,
  },
  {
    title: "Kyle Barbre",
    subtitle: "Keller Williams",
    body: "This platform has the ability to link your deed to the blockchain which makes it unique and immutable. Ultimately being able to sell your home as an NFT and convey ownership quicker & safer than traditional mediums!",
    logo: KellerWilliamsLogo,
  },
  {
    title: "Gabrielle & Ryan Channell",
    subtitle: "NFT Buyer",
    body: "We were able to buy this condo as an NFT because we were able to use the Propy platform and the whole team was there all the time. Any questions we had they were on it, and they were replying within minutes. It was a super cool experience.",
    logo: GabrielleChannellTestiminial,
    rounded: true,
  },
  {
    title: "Jeanie Halloran",
    subtitle: "MD Real Estate Agent",
    body: "Propy is revolutionizing my industry, and I am so excited and thankful to be a part of this movement!",
    logo: ExpLogo
  },
  {
    title: "Joshua DeDecker",
    subtitle: "Real Estate Operations Consultant",
    body: "Propy has laid ground work for the first real estate NFT transactions. We identified the need to become crypto/NFT certified at Patriot Property Collective to fill this new and growing demand!",
    logo: PartriotPropertyLogo
  },
]

interface ITestimonials {
  initialDelay?: number,
  hideHeading?: boolean,
}

const Testimonials = (props: ITestimonials) => {
    const classes = useStyles();

    const autoPageDelay = 109500;

    const [vouchIndex, setVouchIndex] = useState(0);

    const {
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const timeoutRef = useRef(null);

    const resetTimeout = () => {
      if (timeoutRef.current) {
        //@ts-ignore
        clearTimeout(timeoutRef.current);
      }
    }

    useEffect(() => {
      resetTimeout();
      //@ts-ignore
      timeoutRef.current = setTimeout(
        () =>
          setVouchIndex((prevIndex) =>
            prevIndex === vouches.length - 1 ? 0 : prevIndex + 1
          ),
          autoPageDelay
      );
  
      return () => {
        resetTimeout();
      };
    }, [vouchIndex]);

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const cardSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay + 750
    })

    const rootSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay
    })

    const [vouchSprings] = useSprings(
      vouches.length,
      (springIndex: number) => ({
        from: {
          display: 'none',
          opacity: 0,
          transform: 'translateX(100%)',
        },
        to: {
          display: 'block',
          opacity: vouchIndex === springIndex ? 1 : 0,
          transform: vouchIndex === springIndex ? 'translateX(0%)' : `translateX(${vouchIndex < springIndex ? '100%' : '-100%'})`,
        },
      }),
      [vouches, vouchIndex]
    )

    return (
      <>
        {!hideHeading &&
          <animated.div className={classes.headingText} style={rootSpring}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              From our Clients
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              Hear about Propy from the clients we work with
            </Typography>
          </animated.div>
        }
        <animated.div style={cardSpring} className={classes.root} ref={ref}>
          <SimpleCard
            className={['box-shadow-default', classes.vouchCard].join(' ')}
          >
            {
              vouches.map((vouch, index) =>
                <animated.div key={`multivouch-${index}`} style={vouchSprings[index]} className={vouchIndex === index ? classes.block : classes.absolute}>
                  <div className={classes.vouchTitleZone}>
                    <div className={[classes.vouchOrgContainer, "flex-center-all"].join(" ")}>
                      <img className={classes.vouchOrgLogo} style={{...(vouch['rounded'] ? {borderRadius: '50%'} : {})}} src={vouch.logo} alt="World Economic Forum Logo" />
                    </div>
                    <div>
                      <Typography className={classes.vouchTitle} variant="h6">
                        {vouch.title}
                      </Typography>
                      <Typography className={classes.vouchSubtitle} variant="body1">
                        {vouch.subtitle}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.vouchQuoteContainer}>
                    <Typography className={classes.vouchQuote} variant="body1">
                      <i>"{vouch.body}"</i>
                    </Typography>
                  </div>
                </animated.div>
              )
            }
            <div className={classes.navigationContainer}>
              {
                vouches.map((vouch, index) =>
                  <CircleIcon 
                    key={`multivouch-navigation-${index}`}
                    onClick={() => setVouchIndex(index)}
                    className={[
                      classes.navigationCircle, 
                      (index < vouches.length - 1) ? classes.navigationCircleSpacer : '',
                      (index === vouchIndex) ? classes.selectedNavigationCircle : ''
                    ].join(' ')} 
                  />
                )
              }
              <div className={classes.navigationPageCountContainer}>
                <ArrowBack onClick={() => setVouchIndex(vouchIndex === 0 ? (vouches.length - 1) : vouchIndex - 1)} className={classes.navigationArrow} />
                <Typography variant="button">
                  {vouchIndex + 1}/{vouches?.length}
                </Typography>
                <ArrowForward onClick={() => setVouchIndex(vouchIndex === (vouches.length - 1) ? 0 : vouchIndex + 1)} className={classes.navigationArrow}/>
              </div>
            </div>
          </SimpleCard>
        </animated.div>
      </>
    )

}

export default Testimonials;