import {
    IBuyerFlowData,
    ISellerFlowData,
} from "../../interfaces";

export const setShowLeftMenu = (visible: boolean) => ({
    type: "SHOW_LEFT_MENU",
    visible
})

export const setActiveAccount = (account: string) => ({
    type: "SET_ACTIVE_ACCOUNT",
    account
})

export const setDarkMode = (active: boolean) => ({
    type: "SET_DARK_MODE",
    active
})

export const setConsideredMobile = (mobile: boolean) => ({
    type: "IS_CONSIDERED_MOBILE",
    mobile
})

export const setConsideredMedium = (medium: boolean) => ({
    type: "IS_CONSIDERED_MEDIUM",
    medium
})

export const setAgentFlowVersion = (version: number) => ({
    type: "SET_AGENT_FLOW_VERSION",
    version
})

export const setAgentFlowTrialOrSkip = (trialOrSkip: string) => ({
    type: "SET_AGENT_FLOW_TRIAL_OR_SKIP",
    trialOrSkip
})

export const setAgentFlowSessionId = (sessionId: string) => ({
    type: "SET_AGENT_FLOW_SESSION_ID",
    sessionId
})

export const setAgentFlowCurrentStep = (currentStep: string) => ({
    type: "SET_AGENT_FLOW_CURRENT_STEP",
    currentStep
})

export const setAgentFlowAgentLocation = (location: string) => ({
    type: "SET_AGENT_FLOW_AGENT_LOCATION",
    location
})

export const setAgentFlowAgentDealsClosed = (dealsClosedBracket: string) => ({
    type: "SET_AGENT_FLOW_AGENT_DEALS_CLOSED",
    dealsClosedBracket
})

interface IAgentProfileInfo {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    phoneNumberCountry: string;
}

export const setAgentFlowAgentProfileInfo = (agentProfileInfo: IAgentProfileInfo) => ({
    type: "SET_AGENT_FLOW_AGENT_PROFILE_INFO",
    firstName: agentProfileInfo.firstName,
    lastName: agentProfileInfo.lastName,
    emailAddress: agentProfileInfo.emailAddress,
    phoneNumber: agentProfileInfo.phoneNumber,
})

export const setAgentFlowProfileInfoFirstName = (firstName: IAgentProfileInfo['firstName']) => ({
    type: "SET_AGENT_FLOW_PROFILE_INFO_FIRST_NAME",
    firstName,
})

export const setAgentFlowProfileInfoLastName = (lastName: IAgentProfileInfo['lastName']) => ({
    type: "SET_AGENT_FLOW_PROFILE_INFO_LAST_NAME",
    lastName,
})

export const setAgentFlowProfileInfoEmailAddress = (emailAddress: IAgentProfileInfo['emailAddress']) => ({
    type: "SET_AGENT_FLOW_PROFILE_INFO_EMAIL_ADDRESS",
    emailAddress,
})

export const setAgentFlowProfileInfoPhoneNumber = (phoneNumber: IAgentProfileInfo['phoneNumber']) => ({
    type: "SET_AGENT_FLOW_PROFILE_INFO_PHONE_NUMBER",
    phoneNumber,
})

export const setAgentFlowProfileInfoPhoneNumberCountry = (phoneNumber: IAgentProfileInfo['phoneNumberCountry']) => ({
    type: "SET_AGENT_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY",
    phoneNumber,
})

export const setAgentFlowAgentProfileAvailability = (profileAvailability: string) => ({
    type: "SET_AGENT_FLOW_AGENT_PROFILE_AVAILABILITY",
    profileAvailability,
})

export const resetAgentFlow = () => ({
    type: "RESET_AGENT_FLOW",
})

export const setBuyerFlowSessionId = (sessionId: string) => ({
    type: "SET_BUYER_FLOW_SESSION_ID",
    sessionId
})

export const setBuyerFlowCurrentStep = (currentStep: string) => ({
    type: "SET_BUYER_FLOW_CURRENT_STEP",
    currentStep
})

export const setBuyerFlowServiceRequirement = (serviceRequirement: IBuyerFlowData["serviceRequirement"]) => ({
    type: "SET_BUYER_FLOW_SERVICE_REQUIREMENT",
    serviceRequirement,
})

export const setBuyerFlowDesiredLocation = (desiredLocation: string) => ({
    type: "SET_BUYER_FLOW_DESIRED_LOCATION",
    desiredLocation
})

export const setBuyerFlowPurchaseType = (purchaseType: IBuyerFlowData["purchaseType"]) => ({
    type: "SET_BUYER_FLOW_PURCHASE_TYPE",
    purchaseType
})

export const setBuyerFlowProfileInfo = (agentProfileInfo: IAgentProfileInfo) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO",
    firstName: agentProfileInfo.firstName,
    lastName: agentProfileInfo.lastName,
    emailAddress: agentProfileInfo.emailAddress,
    phoneNumber: agentProfileInfo.phoneNumber,
    phoneNumberCountry: agentProfileInfo.phoneNumberCountry,
})

export const setBuyerFlowProfileInfoFirstName = (firstName: IAgentProfileInfo['firstName']) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO_FIRST_NAME",
    firstName,
})

export const setBuyerFlowProfileInfoLastName = (lastName: IAgentProfileInfo['lastName']) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO_LAST_NAME",
    lastName,
})

export const setBuyerFlowProfileInfoEmailAddress = (emailAddress: IAgentProfileInfo['emailAddress']) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO_EMAIL_ADDRESS",
    emailAddress,
})

export const setBuyerFlowProfileInfoPhoneNumber = (phoneNumber: IAgentProfileInfo['phoneNumber']) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO_PHONE_NUMBER",
    phoneNumber,
})

export const setBuyerFlowProfileInfoPhoneNumberCountry = (phoneNumberCountry: IAgentProfileInfo['phoneNumberCountry']) => ({
    type: "SET_BUYER_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY",
    phoneNumberCountry,
})

export const setBuyerFlowProfileAvailability = (profileAvailability: string) => ({
    type: "SET_BUYER_FLOW_PROFILE_AVAILABILITY",
    profileAvailability,
})

export const setBuyerFlowHasAgent = (hasAgent: IBuyerFlowData["hasAgent"]) => ({
    type: "SET_BUYER_FLOW_HAS_AGENT",
    hasAgent,
})

export const setBuyerFlowPriceRange = (priceRange: IBuyerFlowData["priceRange"]) => ({
    type: "SET_BUYER_FLOW_PRICE_RANGE",
    priceRange
})

interface IBuyerFlowAgentInfo {
    agentEmailAddress: false | string;
    agentPhoneNumber: false | string;
    agentPhoneNumberCountry: false | string;
    agentFirstName: false | string;
    agentLastName: false | string;
    agentMessage?: false | string;
}

export const setBuyerFlowAgentInfo = (agentInfo: IBuyerFlowAgentInfo) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO",
    agentInfo,
})

export const setBuyerFlowAgentInfoEmailAddress = (agentEmailAddress: IBuyerFlowAgentInfo['agentEmailAddress']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_EMAIL_ADDRESS",
    agentEmailAddress,
})

export const setBuyerFlowAgentInfoPhoneNumber = (agentPhoneNumber: IBuyerFlowAgentInfo['agentPhoneNumber']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_PHONE_NUMBER",
    agentPhoneNumber,
})

export const setBuyerFlowAgentInfoPhoneNumberCountry = (agentPhoneNumberCountry: IBuyerFlowAgentInfo['agentPhoneNumberCountry']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_PHONE_NUMBER_COUNTRY",
    agentPhoneNumberCountry,
})

export const setBuyerFlowAgentInfoFirstName = (agentFirstName: IBuyerFlowAgentInfo['agentFirstName']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_FIRST_NAME",
    agentFirstName,
})

export const setBuyerFlowAgentInfoLastName = (agentLastName: IBuyerFlowAgentInfo['agentLastName']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_LAST_NAME",
    agentLastName,
})

export const setBuyerFlowAgentInfoMessage = (agentMessage: IBuyerFlowAgentInfo['agentMessage']) => ({
    type: "SET_BUYER_FLOW_AGENT_INFO_MESSAGE",
    agentMessage,
})

export const resetBuyerFlow = () => ({
    type: "RESET_BUYER_FLOW",
})

export const setSellerFlowSessionId = (sessionId: string) => ({
    type: "SET_SELLER_FLOW_SESSION_ID",
    sessionId
})

export const setSellerFlowCurrentStep = (currentStep: string) => ({
    type: "SET_SELLER_FLOW_CURRENT_STEP",
    currentStep
})

export const setSellerFlowServiceRequirements = (serviceRequirements: ISellerFlowData["serviceRequirements"]) => ({
    type: "SET_SELLER_FLOW_SERVICE_REQUIREMENT",
    serviceRequirements,
})

export const setSellerFlowProfileInfo = (agentProfileInfo: IAgentProfileInfo) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO",
    firstName: agentProfileInfo.firstName,
    lastName: agentProfileInfo.lastName,
    emailAddress: agentProfileInfo.emailAddress,
    phoneNumber: agentProfileInfo.phoneNumber,
    phoneNumberCountry: agentProfileInfo.phoneNumberCountry,
})

export const setSellerFlowProfileInfoFirstName = (firstName: IAgentProfileInfo['firstName']) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO_FIRST_NAME",
    firstName,
})

export const setSellerFlowProfileInfoLastName = (lastName: IAgentProfileInfo['lastName']) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO_LAST_NAME",
    lastName,
})

export const setSellerFlowProfileInfoEmailAddress = (emailAddress: IAgentProfileInfo['emailAddress']) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO_EMAIL_ADDRESS",
    emailAddress,
})

export const setSellerFlowProfileInfoPhoneNumber = (phoneNumber: IAgentProfileInfo['phoneNumber']) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO_PHONE_NUMBER",
    phoneNumber,
})

export const setSellerFlowProfileInfoPhoneNumberCountry = (phoneNumberCountry: IAgentProfileInfo['phoneNumberCountry']) => ({
    type: "SET_SELLER_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY",
    phoneNumberCountry,
})

export const setSellerFlowProfileAvailability = (profileAvailability: string) => ({
    type: "SET_SELLER_FLOW_PROFILE_AVAILABILITY",
    profileAvailability,
})

export const setSellerFlowPropertyLocation = (propertyLocation: {placeId: string | false, description: string | false}) => ({
    type: "SET_SELLER_FLOW_PROPERTY_LOCATION",
    propertyLocation
})

export const setSellerFlowPropertyMLS = (propertyMLS: string | false) => ({
    type: "SET_SELLER_FLOW_PROPERTY_MLS",
    propertyMLS
})

export const setSellerFlowHasAgent = (hasAgent: IBuyerFlowData["hasAgent"]) => ({
    type: "SET_SELLER_FLOW_HAS_AGENT",
    hasAgent,
})

interface ISellerFlowAgentInfo {
    agentEmailAddress: false | string;
    agentPhoneNumber: false | string;
    agentPhoneNumberCountry: false | string;
    agentFirstName: false | string;
    agentLastName: false | string;
    agentMessage?: false | string;
}

export const setSellerFlowAgentInfo = (agentInfo: ISellerFlowAgentInfo) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO",
    agentInfo,
})

export const setSellerFlowAgentInfoEmailAddress = (agentEmailAddress: ISellerFlowAgentInfo['agentEmailAddress']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_EMAIL_ADDRESS",
    agentEmailAddress,
})

export const setSellerFlowAgentInfoPhoneNumber = (agentPhoneNumber: ISellerFlowAgentInfo['agentPhoneNumber']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_PHONE_NUMBER",
    agentPhoneNumber,
})

export const setSellerFlowAgentInfoPhoneNumberCountry = (agentPhoneNumberCountry: ISellerFlowAgentInfo['agentPhoneNumberCountry']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_PHONE_NUMBER_COUNTRY",
    agentPhoneNumberCountry,
})

export const setSellerFlowAgentInfoFirstName = (agentFirstName: ISellerFlowAgentInfo['agentFirstName']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_FIRST_NAME",
    agentFirstName,
})

export const setSellerFlowAgentInfoLastName = (agentLastName: ISellerFlowAgentInfo['agentLastName']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_LAST_NAME",
    agentLastName,
})

export const setSellerFlowAgentInfoMessage = (agentMessage: ISellerFlowAgentInfo['agentMessage']) => ({
    type: "SET_SELLER_FLOW_AGENT_INFO_MESSAGE",
    agentMessage,
})

export const resetSellerFlow = () => ({
    type: "RESET_SELLER_FLOW",
})

export const setSignedIn = (signedIn: boolean) => ({
    type: "SET_SIGNED_IN",
    signedIn
})

export const giveConsentToCookies = () => ({
    type: "GIVE_CONSENT_TO_COOKIES",
    consent: true,
})

export const dismissPopupPropyAI = () => ({
    type: "DISMISS_POPUP_PROPY_AI",
    dismiss: true,
})

export const dismissPopupAIRealEstateSummit2023 = () => ({
    type: "DISMISS_POPUP_AI_REAL_ESTATE_SUMMIT_2023",
    dismiss: true,
})