import React from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/AgentOnboardingStepsV2/AgentOnboardingStep4DealsClosedContainer';

import { PROPY_BLUE, FLOW_TYPE_TO_LEAD_TYPE } from '../../constants';
import FloatingActionButton from '../FloatingActionButton';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import { FlowService } from '../../services/api';

import { isPriorityLocation } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    optionContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    optionButton: {
      fontSize: '25px',
      padding: theme.spacing(4),
      borderRadius: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '50%',
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    }
  }),
);

const dealsClosedOptions = [
  {
    value: "0-5",
    label: "0 - 5",
  },
  {
    value: "5+",
    label: "5 +",
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function AgentOnboardingStep2(props: PropsFromRedux & IStepProps) {

  const {
    setAgentFlowAgentDealsClosed,
    setAgentFlowCurrentStep,
    agentFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const classes = useStyles();

  const [dealsClosed, setDealsClosed] = React.useState(agentFlow.dealsClosedBracket ? agentFlow.dealsClosedBracket : '');

  const setSelectedDealsClosed = async (dealsClosedBracket: string) => {
    setDealsClosed(dealsClosedBracket);
    setAgentFlowAgentDealsClosed(dealsClosedBracket);
    // submit data to backend
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(agentFlow.sessionId && recaptchaRef.current) {
      setStepSubmitting(true);
      const token = await getToken();
      try {
        await FlowService.patchSession(agentFlow.sessionId, {
          Type: FLOW_TYPE_TO_LEAD_TYPE["agent"],
          ...(agentFlow.version && { Version: agentFlow.version }),
          ...(agentFlow.location && { Location: { placeDesc: agentFlow.location } }),
          ...(dealsClosedBracket && { DealsClosed: dealsClosedBracket }),
          ...(agentFlow.firstName && { FirstName: agentFlow.firstName }),
          ...(agentFlow.lastName && { LastName: agentFlow.lastName }),
          ...(agentFlow.emailAddress && { Email: agentFlow.emailAddress }),
          ...(agentFlow.phoneNumber && { PhoneNumber: agentFlow.phoneNumber }),
          ...(agentFlow.phoneNumberCountry && { PhoneCountryCode: agentFlow.phoneNumberCountry }),
        }, token);
        setStepSubmitting(false);
        toast.success('Saved progress!');
        if(
          dealsClosedBracket && 
          (typeof dealsClosedBracket === 'string') && 
          (["5+", "6-30", "31-99", "100+"].indexOf(dealsClosedBracket) > -1) &&
          isPriorityLocation(agentFlow.location ? agentFlow.location : "")
        ) {
          setAgentFlowCurrentStep('schedule-onboarding');
          // setAgentFlowCurrentStep('thank-you');
        } else {
          setAgentFlowCurrentStep('profile-complete');
        }
      } catch (e) {
        console.log({e})
        setStepSubmitting(false);
        toast.error("Unable to save progress, please try again or contract support");
      }
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        How many deals do you close per year?
      </Typography>
      <div className={classes.optionContainer}>
        {dealsClosedOptions.map((entry, index) => 
          <FloatingActionButton 
            key={`deals-closed-option-${index}`}
            onClick={() => setSelectedDealsClosed(entry.value)}
            className={
              [
                classes.optionButton,
                (dealsClosed === entry.value) ? classes.optionButtonSelected : classes.optionButtonUnselected
              ].join(" ")
            }
            textColor={(dealsClosed === entry.value) ? 'white' : PROPY_BLUE}
            size="large"
            buttonColor={(dealsClosed !== entry.value) ? "default" : "primary"}
            text={entry.label}
          />
        )}
      </div>
    </animated.div>
  );
}