import React from 'react';

import { usePageTracking } from '../hooks';

const TrackingZone = () => {

  usePageTracking();
  
  return (
    <></>
  )
}

export default TrackingZone