import { connect, ConnectedProps } from 'react-redux';

import {
  setBuyerFlowDesiredLocation,
  setBuyerFlowCurrentStep,
} from '../../state/actions';

import BuyerOnboardingStep2PickLocation from '../../components/BuyerOnboardingSteps/BuyerOnboardingStep2PickLocation';

import { IBuyerFlowData } from '../../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  buyerFlow: IBuyerFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  buyerFlow: state.buyerFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setBuyerFlowDesiredLocation,
  setBuyerFlowCurrentStep,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(BuyerOnboardingStep2PickLocation)