import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';

import LinkWrapper from './LinkWrapper';

import FloatingActionButton from './FloatingActionButton';

import { PropsFromRedux } from '../containers/OnboardingFlowCookieGateContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
    },
    card: {
      width: '100%',
      maxWidth: 510,
      backgroundColor: '#ffffffa1',
      backdropFilter: 'blur(5px)',
    },
    cardPaddingDesktop: {
      padding: theme.spacing(2),
    },
    cardPaddingMobile: {
      padding: theme.spacing(1),
    },
    sectionSpacer: {
      marginBottom: theme.spacing(2)
    },
    logoTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    personaButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  }),
);

const OnboardingFlowCookieGate = (props: PropsFromRedux) => {
    const classes = useStyles();

    let {
      giveConsentToCookies,
      isConsideredMobile,
    } = props;

    const emitConsent = () => {
      giveConsentToCookies();
    }

    return (
      <div className={classes.root}>
        <Card elevation={3} className={[classes.card, isConsideredMobile ? classes.cardPaddingMobile : classes.cardPaddingDesktop].join(" ")}>
          <>
            <div className={[classes.logoTextContainer, classes.sectionSpacer].join(' ')}>
              <Typography style={{fontWeight: 'bold'}} variant={isConsideredMobile ? "h5" : "h4"}>
                Cookie Policy
              </Typography>
            </div>
            <Typography style={{textAlign: 'center'}}>
              We use cookies to personalize content and user experience, provide social media features and to analyze our traffic. For these reasons, we also share information about your use of our site with our partners. <LinkWrapper actionNameGA='privacy_policy_from_onboarding_flow_cookie_gate' link="https://propy.com/browse/privacy-policy/" external={true}>Learn more here.</LinkWrapper> By continuing to browse our website, you agree to our use of cookies.
            </Typography>
            <FloatingActionButton onClick={() => emitConsent()} className={[classes.personaButton].join(' ')} text="I accept" buttonColor="secondary" />
          </>
        </Card>
      </div>
    )
};

export default OnboardingFlowCookieGate;