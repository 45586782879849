import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import CryptoAgentBackgroundImage from '../assets/img/crypto-certified-card-background.webp';
import CryptoAgentCourseBadge from '../assets/img/crypto-certified-agent-badge.webp';

import MetaAgentBackgroundImage from '../assets/img/meta-agent-card-background.webp';
import MetaAgentCourseBadge from '../assets/svg/meta-agent-badge.svg';

import ExternalLink from './ExternalLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
      position: 'relative',
    },
    card: {
      width: '100%',
    },
    cardDescriptionClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
    },
    badgeImage: {
      width: 115,
      position: 'absolute',
      zIndex: 10,
      top: '-20px',
      left: '20px',
    }
  }),
);

interface ICourseConfig {
  title: string
  link: string
  description: string
  backgroundImage: string
  badgeImage: string
}

const AgentCourseConfigs : {[key: string]: ICourseConfig} = {
  "crypto-agent": {
    title: "Crypto Agent Certification",
    description: "Facilitate crypto & NFT deals",
    link: "https://propy.com/browse/crypto-for-real-estate/",
    backgroundImage: CryptoAgentBackgroundImage,
    badgeImage: CryptoAgentCourseBadge,
  },
  "meta-agent": {
    title: "Meta Agent Certification",
    description: "Flex your metaverse muscles online",
    link: "https://propy.com/browse/meta-agent/",
    backgroundImage: MetaAgentBackgroundImage,
    badgeImage: MetaAgentCourseBadge,
  },
}

interface IAgentCourseCard {
  courseId: string
}

const AgentCourseCard = (props: IAgentCourseCard) => {
    const classes = useStyles();

    const {
      courseId,
    } = props;

    const {
      title,
      description,
      link,
      backgroundImage,
      badgeImage,
    } = AgentCourseConfigs[courseId];

    return (
      <div className={classes.root}>
        <ExternalLink href={link} fullWidth>
          <img className={classes.badgeImage} src={badgeImage} alt="course badge" />
          <Card elevation={3} className={classes.card}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="100"
                image={backgroundImage}
                alt="featured property media"
              />
              <div>
                <CardContent>
                  <Typography className={classes.cardTitleClamp} gutterBottom variant="h5" component="div">
                    {title}
                  </Typography>
                  <Typography className={classes.cardDescriptionClamp} variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </ExternalLink>
      </div>
    )
};

export default AgentCourseCard;