import React, { useState } from 'react';

import {
  animated,
  useSpring,
} from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { PropsFromRedux } from '../containers/HoverShiftContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    inner: {
      width: 'auto',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }),
);

interface IHoverShiftProps {
  children: React.ReactNode,
  maxWidth?: number,
}

const getShiftAmount = (isHovered: boolean, isMouseDown: boolean) => {
  if(isHovered && !isMouseDown) {
    return -3
  }
  if(isMouseDown) {
    return 0;
  }
  return 0;
}

function HoverShift(props: IHoverShiftProps & PropsFromRedux) {

    const {
      children,
      maxWidth,
    } = props;

    const classes = useStyles();

    const [isHovered, setIsHovered] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const hoverShiftSpring = useSpring({
      from: {
        top:  0,
      },
      to: {
        top: getShiftAmount(isHovered, isMouseDown),
      },
    })

    return (
      <animated.div className={classes.root} style={hoverShiftSpring}>
        <div style={maxWidth ? {maxWidth: `${maxWidth}px`} : {}} className={classes.inner} onMouseDown={() => setIsMouseDown(true)} onMouseUp={() => setIsMouseDown(false)} onMouseEnter={() => {setIsHovered(true);setIsMouseDown(false)}} onMouseLeave={() => { setIsHovered(false);setIsMouseDown(false) }}>
          {children}
        </div>
      </animated.div>
    )
};

export default HoverShift;