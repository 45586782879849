interface ISignedInAction {
  type: string;
  signedIn: boolean;
}
const signedIn = (state = false, action: ISignedInAction) => {
  switch (action.type) {
    case 'SET_SIGNED_IN':
      return action.signedIn
    default:
      return state
  }
}

export default signedIn;