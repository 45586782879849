import React, { useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import {
  PROJECT_ROOT
} from '../constants';

import { PropsFromRedux } from '../containers/AgentOnboardingResetContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    formContainer: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
    },
    navigationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
    navigationButton: {

    },
    leftCornerContainer: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
    progressContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      opacity: 0.75,
    },
    circleProgressContainer: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    leftCornerTypography: {
      opacity: 0.7
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    innerContentFirstAndFinal: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    }
  }),
);

const AgentOnboardingSteps = (props: PropsFromRedux) => {
  let navigate = useNavigate();

  const {
    setAgentFlowCurrentStep,
    resetAgentFlow,
  } = props;

  useEffect(() => {
    resetAgentFlow()
    setTimeout(() => {
      setAgentFlowCurrentStep("");
      navigate(`${PROJECT_ROOT}/get-started/agent/`, { replace: true });
    }, 250)
  }, [setAgentFlowCurrentStep, resetAgentFlow, navigate])

  const classes = useStyles();

  return (
    <div 
      className={[classes.innerContent, "box-shadow-default"].join(" ")}
      style={{width: 700, maxWidth: '100%'}}
    >
      <Box className={[classes.formContainer].join(" ")} sx={{ width: '100%' }}>
        <div className={classes.progressContainer}>
          <LinearProgress variant="determinate" color="primary" value={100} />
        </div>
        <div className="flex-center-all">
          <CircularProgress/>
        </div>
        <Typography className="flex-center-all" style={{marginTop: 32}} gutterBottom variant="h5" component="div">
          Resetting Agent Flow
        </Typography>
      </Box>
    </div>
  )
};

export default AgentOnboardingSteps;