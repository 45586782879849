import React from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import Fab from '@mui/material/Fab';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/AgentOnboardingStepsV2/AgentOnboardingStep1GetStartedContainer';

import { PROPY_BLUE, FLOW_TYPE_TO_LEAD_TYPE } from '../../constants';

import { FlowService } from '../../services/api';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import {
  IAgentFlowData
} from "../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      fontSize: '26px',
      textAlign: 'center',
    },
    formSubtitle: {
      marginBottom: theme.spacing(3),
      fontSize: '18px',
      textAlign: 'center',
    },
    optionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      fontWeight: 'bold',
    },
    optionSpacer: {
      marginTop: theme.spacing(2),
    },
    optionButton: {
      fontSize: '20px',
      padding: theme.spacing(5),
      borderRadius: theme.spacing(5),
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    },
    highlightedFeatureContainer: {
      display: 'flex',
    },
    doneIcon: {
      marginRight: theme.spacing(1),
      color: PROPY_BLUE,
    }
  }),
);

interface IServiceRequirementOption {
  value: IAgentFlowData["trialOrSkip"];
  highlightedText: string;
  secondaryText: string;
}

const serviceRequirementOptions : IServiceRequirementOption[] = [
  {
    value: "trial",
    secondaryText: "Check eligibility for",
    highlightedText: "Free access",
  },
  {
    value: "skip",
    secondaryText: "Skip and",
    highlightedText: "Buy now ($20/month)",
  },
]

const highlightedAgentFeatures : string[] =  [
  "World-class title & escrow",
  "Support crypto transactions",
  "AI and Blockchain powered Forms and Closing Platform",
  "International community membership",
];

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function AgentOnboardingStep1(props: PropsFromRedux & IStepProps) {

  const {
    setAgentFlowTrialOrSkip,
    setAgentFlowCurrentStep,
    agentFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const classes = useStyles();

  const getToken = useRecaptchaToken(recaptchaRef);

  const setTrialOrSkipChange = async (trialOrSkip: string) => {
    setAgentFlowTrialOrSkip(trialOrSkip);
    // submit data to backend
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(agentFlow.sessionId && recaptchaRef.current) {
      setStepSubmitting(true);
      try {
        const token = await getToken();
        await FlowService.patchSession(agentFlow.sessionId, {
          Type: FLOW_TYPE_TO_LEAD_TYPE["agent"],
          ...(agentFlow.version && { Version: agentFlow.version }),
          ...(agentFlow.location && { Location: { placeDesc: agentFlow.location } }),
          ...(agentFlow.dealsClosedBracket && { DealsClosed: agentFlow.dealsClosedBracket }),
          ...(agentFlow.firstName && { FirstName: agentFlow.firstName }),
          ...(agentFlow.lastName && { LastName: agentFlow.lastName }),
          ...(agentFlow.emailAddress && { Email: agentFlow.emailAddress }),
          ...(agentFlow.phoneNumber && { PhoneNumber: agentFlow.phoneNumber }),
          ...(agentFlow.phoneNumberCountry && { PhoneCountryCode: agentFlow.phoneNumberCountry }),
        }, token);
        setStepSubmitting(false);
        if(trialOrSkip === 'skip') {
          setAgentFlowCurrentStep("thank-you");
        } else {
          toast.success('Saved progress!');
          setAgentFlowCurrentStep("location");
        }
      } catch (e) {
        console.log({e})
        setStepSubmitting(false);
        toast.error("Unable to save progress, please try again or contract support");
      }
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Let's get started
      </Typography>
      <Typography className={classes.formSubtitle} gutterBottom variant="body1" component="div">
        Propy will change your real estate business!
      </Typography>
      <div className={classes.optionContainer} id="agent-onboarding-step-1-service-requirement-option-list">
        {serviceRequirementOptions.map((entry, index) => 
          <>
            <Fab 
              component="button"
              variant="extended"
              className={
                [
                  classes.optionButton,
                  index > 0 ? classes.optionSpacer : "",
                  (agentFlow.trialOrSkip === entry.value) ? classes.optionButtonSelected : classes.optionButtonUnselected
                ].join(" ")
              }
              onClick={() => entry.value && setTrialOrSkipChange(entry.value)}
              size="large"
              color={(agentFlow.trialOrSkip !== entry.value) ? "default" : "primary"}
            >
              <div className="flex-col">
                <Typography style={{padding: 0, lineHeight: 1.1, marginBottom: 8, color: (agentFlow.trialOrSkip === entry.value) ? 'white' : 'black'}}>{entry.secondaryText}</Typography>
                <Typography variant="h6" style={{padding: 0, fontWeight: 'bold', color: (agentFlow.trialOrSkip === entry.value) ? 'white' : PROPY_BLUE, lineHeight: 1.1}}>{entry.highlightedText}</Typography>
              </div>
            </Fab>
          </>
        )}
      </div>
      <Grid container columnSpacing={2} rowSpacing={1}>
        {highlightedAgentFeatures && highlightedAgentFeatures.map((feature) => 
          <Grid item xs={12} sm={6}>
            <div className={classes.highlightedFeatureContainer}>
              <DoneIcon className={classes.doneIcon} />
              <Typography gutterBottom variant="body1" component="div">
                {feature}
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </animated.div>
  );
}