import {
  IMenuTopLevelEntry,
} from '../interfaces';

import TitleAndEscrowIcon from '../assets/svg/title-and-escrow.svg';

export const PROPY_BLUE = "#38a6fa";
export const PROPY_BLUE_DARK = "#007BDA";

export const FLOW_TYPE_TO_LEAD_TYPE = {
  "agent": 0,
  "buyer": 1,
  "seller": 2,
}

export const SERVICE_REQUIREMENT = {
  "looking": 0,
  "title-and-escrow": 1,
  "exposure-to-propy-community": 2,
  "mint-property-as-nft": 3,
}

export const PURCHASE_TYPE = {
  "cash": 0,
  "mortgage": 1,
  "crypto": 2,
}

export const STATE_TO_MAP_QUERY : {[key: string]: string} = {
  "Florida": "?latitude=27.80332577273298&longitude=-81.66531042928528&zoom=7&northEastLatitude=31.97379203359755&northEastLongitude=-76.91921667928528&southWestLatitude=23.46647748411022&southWestLongitude=-86.41140417928528&page=1&locationName=Florida&locationCountry=United%20States&locationState=FL&countryCode=US&locationAddress=Florida,%20USA&locationType=administrativearealevel1",
  "Colorado": "?latitude=39.5500507&longitude=-105.7820674&zoom=7&northEastLatitude=43.160551175536824&northEastLongitude=-101.03597365000002&southWestLatitude=35.74142548666965&southWestLongitude=-110.52816115000002&propertyStatuses=1&page=1&locationName=Colorado&locationCountry=United%20States&locationState=CO&countryCode=US&locationAddress=Colorado,%20USA&locationType=administrativearealevel1",
  "Arizona": "?latitude=34.0489281&longitude=-111.0937311&zoom=7&northEastLatitude=37.0042599&northEastLongitude=-109.0452231&southWestLatitude=31.3323448&southWestLongitude=-114.8163229&propertyStatuses=1&page=1&locationName=Arizona&locationCountry=United%20States&locationState=AZ&countryCode=US&locationAddress=Arizona,%20USA&locationType=administrativearealevel1",
}

export const GOOGLE_MAPS_API_KEY = "AIzaSyDzuZpv8iXJL2lPLqvKU0J4KdFONwSPjGk";

interface IAppConfig {
  PROJECT_ROOT: string;
  RECAPTCHA_KEY: string;
  API_BASE_URL: string;
  API_TP_BASE_URL: string;
  API_IDENTITY_SERVER_URL: string;
  API_IDENTITY_SERVICE_CLIENT_ID: string;
  BASE_URL: string;
  AGENT_HUBSPOT_LINK: string;
  BUYER_HUBSPOT_LINK_GENERIC: string;
  BUYER_HUBSPOT_LINK_ERIC: string;
  SELLER_HUBSPOT_LINK_GENERIC: string;
  SELLER_HUBSPOT_LINK_ERIC: string;
  GOOGLE_ANALYTICS_ID: string;
  GOOGLE_GTAG_ID: string;
  SMARTLOOK_ID?: string;
}

export const AppConfigOptions : Record<string, IAppConfig> = {
  "prod": {
    PROJECT_ROOT: process.env.REACT_APP_PROJECT_ROOT ? process.env.REACT_APP_PROJECT_ROOT : "",
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : "",
    API_BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "",
    API_TP_BASE_URL: process.env.REACT_APP_TP_API_URL ? process.env.REACT_APP_TP_API_URL : "",
    API_IDENTITY_SERVER_URL: process.env.REACT_APP_API_IDENTITY_SERVER_URL ? process.env.REACT_APP_API_IDENTITY_SERVER_URL : "",
    API_IDENTITY_SERVICE_CLIENT_ID: process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID ? process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID : "",
    BASE_URL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "",
    AGENT_HUBSPOT_LINK: "https://meetings.hubspot.com/us-meeting/get-a-demo-for-agents",
    BUYER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/propycs/buyer-meeting-link",
    BUYER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/eric-l-cruz/homepage-title-and-escrow",
    SELLER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/propycs/seller-meeting-link",
    SELLER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/eric-l-cruz/homepage-title-and-escrow",
    GOOGLE_ANALYTICS_ID: "G-TPR0EZ4CBK",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
    SMARTLOOK_ID: "6bb5a0bdab863c838a4ecf0000364eec693fa91e",
  },
  "stage": {
    PROJECT_ROOT: process.env.REACT_APP_PROJECT_ROOT ? process.env.REACT_APP_PROJECT_ROOT : "",
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : "",
    API_BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "",
    API_TP_BASE_URL: process.env.REACT_APP_TP_API_URL ? process.env.REACT_APP_TP_API_URL : "",
    API_IDENTITY_SERVER_URL: process.env.REACT_APP_API_IDENTITY_SERVER_URL ? process.env.REACT_APP_API_IDENTITY_SERVER_URL : "",
    API_IDENTITY_SERVICE_CLIENT_ID: process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID ? process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID : "",
    BASE_URL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "",
    AGENT_HUBSPOT_LINK: "https://meetings.hubspot.com/us-meeting/get-a-demo-for-agents",
    BUYER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/propycs/buyer-meeting-link",
    BUYER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/eric-l-cruz/homepage-title-and-escrow",
    SELLER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/propycs/seller-meeting-link",
    SELLER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/eric-l-cruz/homepage-title-and-escrow",
    GOOGLE_ANALYTICS_ID: "UA-65716380-5",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
    SMARTLOOK_ID: "6bb5a0bdab863c838a4ecf0000364eec693fa91e",
  },
  "dev": {
    PROJECT_ROOT: process.env.REACT_APP_PROJECT_ROOT ? process.env.REACT_APP_PROJECT_ROOT : "",
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : "",
    API_BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "",
    API_TP_BASE_URL: process.env.REACT_APP_TP_API_URL ? process.env.REACT_APP_TP_API_URL : "",
    API_IDENTITY_SERVER_URL: process.env.REACT_APP_API_IDENTITY_SERVER_URL ? process.env.REACT_APP_API_IDENTITY_SERVER_URL : "",
    API_IDENTITY_SERVICE_CLIENT_ID: process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID ? process.env.REACT_APP_API_IDENTITY_SERVICE_CLIENT_ID : "",
    BASE_URL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "",
    AGENT_HUBSPOT_LINK: "https://meetings.hubspot.com/ilko-boyadjiev/agent-schedule-onboarding-complete",
    BUYER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/ilko-boyadjiev/buyer-schedule-onboarding-complete",
    BUYER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/ilko-boyadjiev/buyer-schedule-onboarding-complete",
    SELLER_HUBSPOT_LINK_GENERIC: "https://meetings.hubspot.com/ilko-boyadjiev/seller-schedule-session-complete",
    SELLER_HUBSPOT_LINK_ERIC: "https://meetings.hubspot.com/ilko-boyadjiev/seller-schedule-session-complete",
    GOOGLE_ANALYTICS_ID: "UA-110127661-1",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
  }
}

export const AppConfig : IAppConfig = AppConfigOptions[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "dev"];

export const PROJECT_ROOT = AppConfig.PROJECT_ROOT; // using "/home" while site is on dev.propy.com/home/
export const RECAPTCHA_KEY = AppConfig.RECAPTCHA_KEY;
export const API_BASE_URL = AppConfig.API_BASE_URL;
export const API_TP_BASE_URL = AppConfig.API_TP_BASE_URL;
export const API_IDENTITY_SERVER_URL = AppConfig.API_IDENTITY_SERVER_URL;
export const API_IDENTITY_SERVICE_CLIENT_ID = AppConfig.API_IDENTITY_SERVICE_CLIENT_ID;
export const BASE_URL = AppConfig.BASE_URL;
export const WORDPRESS_URL = "https://propy.com";

export const MENU_ENTRIES : IMenuTopLevelEntry[] = [
  {
    label: "Buy",
    id: 'buy',
    desktopFlyoutTitle: "Buy with Propy",
    desktopFlyoutDescription: "Purchase your next home with next-generation tech and agents.",
    desktopFlyoutHeight: 320,
    ctaLink: `${PROJECT_ROOT}/get-started/buyer`,
    ctaText: "I'm a buyer",
    innerEntries: [
      [
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: "Crypto Escrow with Coinbase",
          link: "https://coinbase.propy.com/escrow/?q=homepage_menu",
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Browse All Listings',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Featured Listings',
          link: `${BASE_URL}/featured`,
          external: true,
        },
        {
          label: 'Find an Agent',
          link: `${BASE_URL}/search`,
          external: true,
        },
        {
          label: 'Crypto Certified Agent Course',
          link: `${WORDPRESS_URL}/browse/crypto-for-real-estate/`,
          external: true,
        },
      ],
      [
        {
          label: 'Pricing',
          link: `${WORDPRESS_URL}/browse/subscriptions/`,
          external: true,
        },
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
        {
          label: 'Meta Agent Course',
          link: `${WORDPRESS_URL}/browse/meta-agent/`,
          external: true,
        },
      ],
      [
        {
          label: '"How To" Guides',
          link: `${WORDPRESS_URL}/browse/knowledge-base/`,
          external: true,
        },
        {
          label: 'Case Studies',
          link: `${WORDPRESS_URL}/browse/case-studies/`,
          external: true,
        },
        {
          label: 'Propy Blog',
          link: `${WORDPRESS_URL}/browse/blog/`,
          external: true,
        },
        {
          label: 'Webinars',
          link: `${WORDPRESS_URL}/browse/webinars/`,
          external: true,
        },
      ]
    ]
  },
  {
    label: "Sell",
    id: 'sell',
    desktopFlyoutTitle: "Sell with Propy",
    desktopFlyoutDescription: "Promote your listing to the Propy community - close fast & securely, in USD or crypto.",
    desktopFlyoutHeight: 300,
    ctaLink: `${PROJECT_ROOT}/get-started/seller`,
    ctaText: "I'm a seller",
    innerEntries: [
      [
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'List A Property',
          link: `${BASE_URL}/list-property`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'View My Listings',
          link: `${BASE_URL}/settings?selectedTab=MY_LISTINGS`,
          external: true,
        },
        {
          label: 'Sell as an NFT',
          link: `${WORDPRESS_URL}/browse/real-estate-nft/`,
          external: true,
        },
        {
          label: 'Crypto Certified Agent Course',
          link: `${WORDPRESS_URL}/browse/crypto-for-real-estate/`,
          external: true,
        },
      ],
      [
        {
          label: 'Webinars',
          link: `${WORDPRESS_URL}/browse/webinars/`,
          external: true,
        },
        {
          label: 'Pricing',
          link: `${WORDPRESS_URL}/browse/subscriptions/`,
          external: true,
        },
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
      ],
      [
        {
          label: 'Meta Agent Course',
          link: `${WORDPRESS_URL}/browse/meta-agent/`,
          external: true,
        },
        {
          label: '"How To" Guides',
          link: `${WORDPRESS_URL}/browse/knowledge-base/`,
          external: true,
        },
        {
          label: 'Case Studies',
          link: `${WORDPRESS_URL}/browse/case-studies/`,
          external: true,
        },
        {
          label: 'Propy Blog',
          link: `${WORDPRESS_URL}/browse/blog/`,
          external: true,
        },
      ]
    ]
  },
  {
    label: "For Agents",
    id: 'for-agents',
    desktopFlyoutTitle: "Buy & Sell with Propy",
    desktopFlyoutDescription: "Our products cater to real estate buyers, sellers & agents",
    desktopFlyoutHeight: 370,
    ctaLink: `${PROJECT_ROOT}/get-started/agent`,
    ctaText: "I'm an agent",
    innerEntries: [
      [
        {
          label: 'List A Property',
          link: `${BASE_URL}/list-property`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Buy A Property',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Browse All Listings',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Record Blockchain Deed',
          link: `${BASE_URL}/tp/title-deed`,
          external: true,
        },
        {
          label: 'Membership',
          link: `${WORDPRESS_URL}/subscriptions/`,
          external: true,
        },
        {
          label: 'Earn Propy Tokens',
          link: `${WORDPRESS_URL}/browse/rewards/`,
          external: true,
        },
      ],
      [
        
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
        {
          label: 'Get Crypto Certified',
          link: `${WORDPRESS_URL}/browse/crypto-for-real-estate/`,
          external: true,
        },
        {
          label: 'Get Meta Certified',
          link: `${WORDPRESS_URL}/browse/meta-agent/`,
          external: true,
        },
        {
          label: 'Get AI Certified',
          link: `${WORDPRESS_URL}/browse/ai-certified-agent/`,
          external: true,
        },
        {
          label: 'Propy Blog',
          link: `${WORDPRESS_URL}/browse/blog/`,
          external: true,
        },
        {
          label: 'Webinars',
          link: `${WORDPRESS_URL}/browse/webinars/`,
          external: true,
        },
      ],
      [
        {
          label: '"How To" Guides',
          link: `${WORDPRESS_URL}/browse/knowledge-base/`,
          external: true,
        },
        {
          label: 'Case Studies',
          link: `${WORDPRESS_URL}/browse/case-studies/`,
          external: true,
        },
        {
          label: 'Newsletter',
          link: `${WORDPRESS_URL}/browse/newsletter/`,
          external: true,
        },
        {
          label: 'FAQ',
          link: `${WORDPRESS_URL}/browse/faq/`,
          external: true,
        },
        {
          label: 'Platform features',
          link: `${WORDPRESS_URL}/browse/newfeatures`,
          external: true,
        },
        {
          label: 'Download Propy App',
          link: `https://apps.apple.com/us/app/propy-real-estate-automated/id1017369540`,
          external: true,
        },
      ]
    ]
  },
  {
    label: "Title & Escrow",
    id: 'title-and-escrow',
    img: TitleAndEscrowIcon,
    external: true,
    link: "https://propy.com/browse/propytitle/"
  }
]