interface IPopupPropyAI {
  type: string;
  dismiss: boolean;
}
const dismissedPopupPropyAI = (state = false, action: IPopupPropyAI) => {
  switch (action.type) {
      case 'DISMISS_POPUP_PROPY_AI':
          return action.dismiss
      default:
          return state
  }
}

export default dismissedPopupPropyAI;