import { connect, ConnectedProps } from 'react-redux';

import {
  resetAgentFlow,
  setAgentFlowCurrentStep,
} from '../state/actions';

import AgentOnboardingReset from '../components/AgentOnboardingReset';

import { IAgentFlowData } from '../interfaces';
interface RootState {
    sellerFlow: IAgentFlowData;
    darkMode: boolean;
    isConsideredMobile: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setAgentFlowCurrentStep,
  resetAgentFlow,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentOnboardingReset)