import React from 'react';
// import { createPortal } from 'react-dom';

import { animated, useSpring } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import ModalVideo from 'react-modal-video'

import { PropsFromRedux } from '../containers/HomePageHeroContainer';
import PersonaSelectionCardContainer from '../containers/PersonaSelectionCardContainer';

import DropContractButton from './DropContractButton';

import homePageHeroImage from '../assets/img/lead-back.webp';
import homePageHeroHouseLayer from '../assets/img/lead-fore.webp';
import homePageHeroCloudLayer from '../assets/img/lead-mid-double.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 550,
      position: 'relative',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundSize: 'cover',
      overflow: 'hidden',
    },
    rootMobile: {
      width: '100%',
      position: 'relative',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundPosition: 'left',
      backgroundSize: 'cover',
      overflow: 'hidden',
      paddingTop: 2,
    },
    mobileTopPadding: {
      paddingTop: 78,
      zIndex: 1,
    },
    typographyContainer: {
      marginLeft: theme.spacing(12),
      marginTop: theme.spacing(4),
      maxWidth: 520,
      zIndex: 10,
    },
    typographyContainerMobile: {
      textAlign: 'center',
      maxWidth: 520,
      marginLeft: 'auto',
      marginRight: 'auto',
      zIndex: 10,
    },
    personaCardContainer: {
      zIndex: 2,
      marginRight: theme.spacing(12),
      marginTop: theme.spacing(4),
      width: '100%',
      maxWidth: 410,
    },
    personaCardContainerMobile: {
      zIndex: 2,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    typographySpacer: {
      marginBottom: theme.spacing(4)
    },
    watchVideoCta: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },
    watchVideoCtaMobile: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    watchVideoCtaIcon: {
      marginLeft: theme.spacing(1),
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    houseLayer: {
      height: '80%',
      width: '100%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      top: 'calc(100%)',
      transform: 'translateY(-100%)',
      backgroundSize: 'contain',
    },
    houseLayerMobile: {
      height: '80%',
      width: '90%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',
      top: 'calc(100%)',
      transform: 'translateY(-100%)',
      backgroundSize: 'contain',
      maxWidth: '600px',
      left: '100%',
    },
    cloudLayer: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      mixBlendMode: 'darken',
    },
    cloudLayerMobile: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      mixBlendMode: 'darken',
    },
    bgLayer: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'bottom',
      zIndex: -1,
    },
    bgLayerMobile: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      zIndex: -1,
    },
    dropContractButtonContainer: {
      marginTop: theme.spacing(2),
    },
    dropContractButtonContainerMobile: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface IHomePageHero {
  initialDelay?: number
}

const HomePageHeroAnimated = (props: IHomePageHero & PropsFromRedux) => {
    const classes = useStyles();

    // const [isVideoOpen, setVideoOpen] = useState(false);

    const {
      isConsideredMedium,
      initialDelay = 0,
    } = props;

    const heroSpring = useSpring({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    })

    const bgSpring = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(125px)'
      },
      to: {
        opacity: 0.75,
        transform: 'translateY(0px)'
      },
      delay: initialDelay,
    })

    const cloudLayerSpring = useSpring({
      from: {
        opacity: 0,
        // transform: 'translateY(800px)'
      },
      to: {
        opacity: 0.35,
        // transform: 'translateX(0px)'
      },
      delay: initialDelay + 500,
    })

    const houseLayerSpringDesktop = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-100%)translateX(200px)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(-100%)translateX(0px)'
      },
      delay: initialDelay + 250,
    })

    const houseLayerSpringMobile = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-100%)translateX(0%)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(-100%)translateX(-100%)'
      },
      delay: initialDelay + 250,
    })

    return (
      <animated.div className={[isConsideredMedium ? classes.rootMobile : classes.root, "box-shadow-default"].join(" ")} style={heroSpring}>
        {/* {createPortal(
          <ModalVideo channel='youtube' isOpen={isVideoOpen} videoId="JbRr2NKaQLE" onClose={() => setVideoOpen(false)} />,
          document.body
        )} */}
        <animated.div className={isConsideredMedium ? classes.bgLayerMobile : classes.bgLayer} style={{backgroundImage: `url(${homePageHeroImage})`, ...bgSpring}} />
        <div className={[isConsideredMedium ? classes.cloudLayerMobile : classes.cloudLayer, "cloud-scroller"].join(" ")}>
          <animated.div className={[isConsideredMedium ? classes.cloudLayerMobile : classes.cloudLayer].join(" ")} style={{backgroundImage: `url(${homePageHeroCloudLayer})`, ...cloudLayerSpring}} />
        </div>
        <animated.div className={isConsideredMedium ? classes.houseLayerMobile : classes.houseLayer} style={{backgroundImage: `url(${homePageHeroHouseLayer})`, ...(isConsideredMedium ? houseLayerSpringMobile : houseLayerSpringDesktop)}} />
        {!isConsideredMedium &&
          <>
            <div className={classes.typographyContainer}>
              <Typography className={classes.typographySpacer} style={{fontWeight: 'bold', fontSize: '3rem'}} variant="h2" component="h1">
                24/7 Real Estate Closings
              </Typography>
              <Typography variant="h5" style={{fontWeight: 500}} component="h2">
                Smooth and secure transactions automated onchain
              </Typography>
              <div className={classes.dropContractButtonContainer}>
                <DropContractButton />
              </div>
              {/* <div className={classes.watchVideoCta}>
                <Typography onClick={() => setVideoOpen(true)} className={classes.cursorPointer} style={{fontWeight: 'bold'}} variant="h6">
                  Watch Video
                </Typography>
                <PlayCircleOutlineIcon onClick={() => setVideoOpen(true)} className={[classes.watchVideoCtaIcon, classes.cursorPointer].join(' ')} />
              </div> */}
            </div>
            <div className={classes.personaCardContainer}>
              <PersonaSelectionCardContainer showDropContractWhenSignedIn={true} />
            </div>
          </>
        }
        {isConsideredMedium &&
          <Grid className={classes.mobileTopPadding} container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.typographyContainerMobile}>
                <Typography className={classes.typographySpacer} style={{fontWeight: 'bold', fontSize: '3rem'}} variant="h2">
                  24/7 Real Estate Closings
                </Typography>
                <Typography style={{fontWeight: 500}} variant="h5">
                  We leverage AI and blockchain technology for smooth and secure transactions
                </Typography>
                <div className={classes.dropContractButtonContainerMobile}>
                  <DropContractButton />
                </div>
                {/* <div className={classes.watchVideoCtaMobile}>
                  <Typography onClick={() => setVideoOpen(true)} className={classes.cursorPointer} style={{fontWeight: 'bold'}} variant="h6">
                    Watch Video
                  </Typography>
                  <PlayCircleOutlineIcon onClick={() => setVideoOpen(true)} className={[classes.watchVideoCtaIcon, classes.cursorPointer].join(' ')} />
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.personaCardContainerMobile}>
                <PersonaSelectionCardContainer showDropContractWhenSignedIn={true} />
              </div>
            </Grid>
          </Grid>
        }
      </animated.div>
    )
};

export default HomePageHeroAnimated;