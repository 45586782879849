import { connect, ConnectedProps } from 'react-redux';

import {
  setSellerFlowProfileInfo,
  setSellerFlowProfileInfoFirstName,
  setSellerFlowProfileInfoLastName,
  setSellerFlowProfileInfoEmailAddress,
  setSellerFlowProfileInfoPhoneNumber,
  setSellerFlowProfileInfoPhoneNumberCountry,
  setSellerFlowCurrentStep,
  setSellerFlowProfileAvailability,
  setSignedIn,
} from '../../state/actions';

import SellerOnboardingStep2ProfileInfo from '../../components/SellerOnboardingSteps/SellerOnboardingStep2ProfileInfo';

import { ISellerFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    sellerFlow: ISellerFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    sellerFlow: state.sellerFlow,
})

const mapDispatchToProps = {
  setSellerFlowProfileInfo,
  setSellerFlowProfileInfoFirstName,
  setSellerFlowProfileInfoLastName,
  setSellerFlowProfileInfoEmailAddress,
  setSellerFlowProfileInfoPhoneNumber,
  setSellerFlowProfileInfoPhoneNumberCountry,
  setSellerFlowCurrentStep,
  setSellerFlowProfileAvailability,
  setSignedIn,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingStep2ProfileInfo)