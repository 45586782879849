import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import SellerOnboardingResetContainer from '../containers/SellerOnboardingResetContainer';
import BuyerOnboardingResetContainer from '../containers/BuyerOnboardingResetContainer';
import AgentOnboardingResetContainer from '../containers/AgentOnboardingResetContainer';

import OnboardingFlowWrapper from '../components/OnboardingFlowWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
    },
    card: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '250px',
    },
    exampleImage: {
        width: '30%',
        margin: theme.spacing(4),
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
    },
    personaCardContainerMobile: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  }),
);

interface IFlowResetPageProps {
  flowType: "seller" | "buyer" | "agent"
}

const GetStartedSurveyPage = (props: IFlowResetPageProps) => {
    const classes = useStyles();

    const {
      flowType,
    } = props;

    return (
      <OnboardingFlowWrapper>
        <Container maxWidth="lg" className={classes.container}>
          {flowType === 'seller' &&
            <SellerOnboardingResetContainer />
          }
          {flowType === 'buyer' &&
            <BuyerOnboardingResetContainer />
          }
          {flowType === 'agent' &&
            <AgentOnboardingResetContainer />
          }
        </Container>
      </OnboardingFlowWrapper>
    )
};

export default GetStartedSurveyPage;