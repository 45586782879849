import { connect, ConnectedProps } from 'react-redux';

import {
  resetSellerFlow,
  setSellerFlowCurrentStep,
} from '../../state/actions';

import SellerOnboardingThankYou from '../../components/SellerOnboardingSteps/SellerOnboardingThankYou';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  resetSellerFlow,
  setSellerFlowCurrentStep,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingThankYou)