import React, { useEffect, useLayoutEffect } from 'react';

import { Toaster } from 'sonner'

import { BrowserRouter } from 'react-router-dom';

import { useIntercom } from 'react-use-intercom';

import Smartlook from 'smartlook-client'

import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { PropsFromRedux } from '../containers/AppContainer';
// import BlockNumberIndicator from './BlockNumberIndicator';
import { useWindowSize } from '../hooks';
import { AppConfig } from '../constants';

import PageContainer from './PageContainer';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

if(AppConfig.SMARTLOOK_ID) {
  Smartlook.init(AppConfig.SMARTLOOK_ID);
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface IAnalyticsWrapperProps {
  consentToCookies: boolean;
  children: any;
}

const AnalyticsWrapper = (props: IAnalyticsWrapperProps) => {

  const gtmParams = {
    id: AppConfig.GOOGLE_GTAG_ID
  };

  const {
    children,
    consentToCookies,
  } = props;

  if(consentToCookies) {
    return (
      <GTMProvider state={gtmParams}>
        {children}
      </GTMProvider>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

const App = (props: PropsFromRedux) => {

  const {
    consentToCookies,
  } = props;

  const {
    boot,
    // shutdown,
    // hide,
    // show,
    // update
  } = useIntercom();

  const {
    setConsideredMobile,
    setConsideredMedium,
  } = props;

  useEffect(() => {
    boot();
  }, [boot])

  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    let sizeConsiderMobile = 1100;
    let sizeConsideredMedium = 1280;
    if (windowSize.width && (windowSize.width <= sizeConsiderMobile)) {
      setConsideredMobile(true);
    }else{
      setConsideredMobile(false);
    }
    if (windowSize.width && (windowSize.width <= sizeConsideredMedium)) {
      setConsideredMedium(true);
    }else{
      setConsideredMedium(false);
    }
  }, [windowSize.width, windowSize.height, setConsideredMobile, setConsideredMedium])

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          h1: {
            fontFamily: "Inter",
            lineHeight: 1,
          },
          h2: {
            fontFamily: "Inter",
            lineHeight: 1,
          },
          h3: {
            fontFamily: "Inter",
            lineHeight: 1,
            fontSize: '2.5rem',
          },
          h4: {
            lineHeight: 1,
          },
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
        },
        components: {
          MuiCardContent :{
            styleOverrides: {
              root:{
                borderRadius: 15,
                overflow: 'auto',
                "&:last-child": {
                  paddingBottom: '16px'
                },
              }
            }
          },
          MuiPaper: {
            styleOverrides: {
              root:{
                borderRadius: 15,
              }
            }
          },
          MuiButton: {
            styleOverrides: {
              root: {
                // textTransform: 'none',
                fontSize: '1rem',
                boxShadow: 'none',
                borderRadius: '20px',
                "&:hover": {
                  boxShadow: 'none',
                },
              },
              outlinedSizeMedium: {
                height: 36,
              },
              contained: {
                color: 'white',
              },
              outlined: {
                backgroundColor: 'white'
              }
            }
          },
          MuiFab: {
            styleOverrides: {
              root: {
                // textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
              },
            }
          }
        },
        palette: {
          mode: props.darkMode ? 'dark' : 'light',
          primary: {
            main: "#37a6fb",
          },
          secondary: {
            main: "#414141",
          },
          ...(props.darkMode && {
            background: {
              default: "#131313",
              paper: "#2b2b2b"
            }
          })
        },
      }),
    [props.darkMode],
  );

  return (
    <AnalyticsWrapper consentToCookies={consentToCookies}>
      <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline/>
              <Toaster richColors position="bottom-center" />
              <PageContainer/>
              {/* <BlockNumberIndicator/> */}
            </ThemeProvider>
          </StyledEngineProvider>
      </BrowserRouter>
    </AnalyticsWrapper>
  );
}

export default App;
