import React, { useState, useRef, useEffect } from 'react';

import { animated, useSpring, useSprings, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import SimpleCard from './SimpleCard';
import LinkWrapper from './LinkWrapper';

import ForbesLogo from '../assets/img/forbes-logo.webp';
import InmanLogo from '../assets/img/inman-logo.webp';
import TechCrunchLogo from '../assets/img/tech-crunch-logo.webp';
import RisMediaLogo from '../assets/img/ris-media-logo.webp';
import YahooFinanceLogo from '../assets/img/yahoo-finance-logo.webp';

import { PROPY_BLUE } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
    vouchCard: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      justifyContent: 'space-between',
    },
    vouchQuoteContainer: {
      
    },
    vouchQuote: {
      fontWeight: 300,
      lineHeight: 1.3,
    },
    vouchTitle: {
      lineHeight: 1.2,
      marginBottom: theme.spacing(2),
    },
    vouchOrgContainer: {
      height: '35px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'end',
      marginBottom: theme.spacing(2)
    },
    vouchOrgLogo: {
      maxWidth: '100px',
      maxHeight: '100%',
      marginRight: theme.spacing(2),
    },
    vouchOrgTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    block: {
      position: 'relative',
    },
    absolute: {
      position: 'absolute',
      width:' calc(100% - 62px)',
    },
    navigationContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navigationCircle: {
      width: 15,
      cursor: 'pointer',
      color: '#CCCCCC',
    },
    navigationCircleSpacer: {
      marginRight: theme.spacing(1),
    },
    selectedNavigationCircle: {
      color: PROPY_BLUE,
    },
    navigationArrow: {
      cursor: 'pointer',
      color: '#a9a9a9',
      width: 18,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigationPageCountContainer: {
      marginLeft: theme.spacing(1),
      color: '#979797',
      display: 'flex',
    }
  }),
);

interface IVouch {
  title?: string;
  body: string;
  logo: string;
  link?: string;
}

const vouches : IVouch[] = [
  {
    title: "Three Ways Blockchain Technology Will Revolutionize Real Estate in 2019",
    body: "Propy is one of the most well-known incumbents in the space. It has built technology for buyers, sellers, brokers, title agents, and notaries to come together through a suite of smart contracts to facilitate transactions.",
    logo: ForbesLogo,
    link: "https://www.forbes.com/sites/forbesrealestatecouncil/2018/11/15/three-ways-blockchain-technology-will-revolutionize-real-estate-in-2019/",
  },
  {
    title: "Most Innovative Real Estate Company",
    body: "Propy wants to streamline real estate transfers using technology. It successfully launched a blockchain pilot in Vermont to establish a secure digital process for delivering electronic deeds, placing it at the forefront of real estate companies leveraging blockchain.",
    logo: InmanLogo,
    link: "https://www.inman.com/2022/12/15/so-meta-propy-offers-certification-in-virtual-world-real-estate/",
  },
  {
    title: "A blockchain-verified platform for selling houses",
    body: "...almost one-fifth of millennials have now thought about buying a home because of the lock-downs induced by the COVID-19 pandemic, meaning that many will be looking for an easy way to transact, especially if it has the ease of use Propy has.",
    logo: TechCrunchLogo,
    link: "https://techcrunch.com/2020/10/02/propy-a-blockchain-verified-platform-for-selling-houses-raises-funding-from-tim-draper/",
  },
  {
    title: "National Association of Realtors®’ VC Arm Invests in Propy",
    body: "Propy also integrated the favorite tools of agents such as DocuSign and addressed the main cybersecurity problem of the industry – wire fraud.",
    logo: RisMediaLogo,
    link: "https://www.rismedia.com/2019/06/10/national-association-realtors-vc-arm-invests-propy-reach-program/",
  },
  {
    title: "TechCrunch Founder Sells $1.6 Million House",
    body: "Propy is a real estate transaction platform that empowers buyers, sellers, their agents, and escrow agents to close a traditional real estate deal entirely online. The purchase offer, payment, and deeds are uploaded to an immutable blockchain.",
    logo: YahooFinanceLogo,
    link: "https://finance.yahoo.com/news/techcrunch-founder-sells-1-6-120038680.html",
  },
]

interface IMultiVouchCard {
  initialDelay?: number
}

const MultiVouchCard = (props: IMultiVouchCard) => {
    const classes = useStyles();

    const autoPageDelay = 8500;

    const [vouchIndex, setVouchIndex] = useState(0);

    const {
      initialDelay = 0,
    } = props;

    const timeoutRef = useRef(null);

    const resetTimeout = () => {
      if (timeoutRef.current) {
        //@ts-ignore
        clearTimeout(timeoutRef.current);
      }
    }

    useEffect(() => {
      resetTimeout();
      //@ts-ignore
      timeoutRef.current = setTimeout(
        () =>
          setVouchIndex((prevIndex) =>
            prevIndex === vouches.length - 1 ? 0 : prevIndex + 1
          ),
          autoPageDelay
      );
  
      return () => {
        resetTimeout();
      };
    }, [vouchIndex]);

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const cardSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay + 750
    })

    const [vouchSprings] = useSprings(
      vouches.length,
      (springIndex: number) => ({
        from: {
          display: 'none',
          opacity: 0,
          transform: 'translateX(100%)',
        },
        to: {
          display: 'block',
          opacity: vouchIndex === springIndex ? 1 : 0,
          transform: vouchIndex === springIndex ? 'translateX(0%)' : `translateX(${vouchIndex < springIndex ? '100%' : '-100%'})`,
        },
      }),
      [vouches, vouchIndex]
    )

    return (
      <animated.div style={cardSpring} className={classes.root} ref={ref}>
        <SimpleCard
          className={['box-shadow-default', classes.vouchCard].join(' ')}
        >
          {
            vouches.map((vouch, index) =>
              <animated.div key={`multivouch-${index}`} style={vouchSprings[index]} className={vouchIndex === index ? classes.block : classes.absolute}>
                <LinkWrapper actionNameGA='vouch_source_from_multi_vouch_card' link={vouch.link} external={true}>
                  <div className={[classes.vouchOrgContainer, "flex-center-all"].join(" ")}>
                    <img className={classes.vouchOrgLogo} src={vouch.logo} alt="World Economic Forum Logo" />
                  </div>
                  <Typography className={classes.vouchTitle} variant="h6">
                    {vouch.title}
                  </Typography>
                  <div className={classes.vouchQuoteContainer}>
                    <Typography className={classes.vouchQuote} variant="body1">
                      <i>"{vouch.body}"</i>
                    </Typography>
                  </div>
                </LinkWrapper>
              </animated.div>
            )
          }
          <div className={classes.navigationContainer}>
            {
              vouches.map((vouch, index) =>
                <CircleIcon 
                  key={`multivouch-navigation-${index}`}
                  onClick={() => setVouchIndex(index)}
                  className={[
                    classes.navigationCircle, 
                    (index < vouches.length - 1) ? classes.navigationCircleSpacer : '',
                    (index === vouchIndex) ? classes.selectedNavigationCircle : ''
                  ].join(' ')} 
                />
              )
            }
            <div className={classes.navigationPageCountContainer}>
              <ArrowBack onClick={() => setVouchIndex(vouchIndex === 0 ? (vouches.length - 1) : vouchIndex - 1)} className={classes.navigationArrow} />
              <Typography variant="button">
                {vouchIndex + 1}/5
              </Typography>
              <ArrowForward onClick={() => setVouchIndex(vouchIndex === (vouches.length - 1) ? 0 : vouchIndex + 1)} className={classes.navigationArrow}/>
            </div>
          </div>
        </SimpleCard>
      </animated.div>
    )

}

export default MultiVouchCard;