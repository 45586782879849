import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';

import RefreshIcon from '@mui/icons-material/Refresh';

import { Link } from "react-router-dom";

import { animated, useSpring, useInView } from '@react-spring/web';

import ExternalLink from '../ExternalLink';
import FloatingActionButton from '../FloatingActionButton';
import HorizontalImageCard from '../HorizontalImageCard';
import HorizontalImageLargeCardContainer from '../../containers/HorizontalImageLargeCardContainer';

import TitleEscrowImage from '../../assets/img/title-escrow.webp';
import TransactionPlatformImage from '../../assets/img/transaction-platform-nft.webp';
import SubscribeCalloutFlowImage from '../../assets/img/subscribe-callout-flow.webp';

import {
  BASE_URL,
  PROJECT_ROOT,
} from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
    },
    formSubtitle: {
      fontSize: '18px',
    },
    formGetCertifiedTitle: {
      fontSize: '26px',
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    createListingButton: {
      marginTop: theme.spacing(2),
    },
    subscribeCardContainer: {
      marginBottom: theme.spacing(2),
    },
    quickLinkCardContainer: {
      marginBottom: theme.spacing(4),
    },
    postTitleSpacing: {
      marginTop: theme.spacing(4),
    },
    helperTextContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      fontSize: '22px',
    },
  }),
);

interface IAgentOnboadingThankYou {
  showListNow?: boolean;
  showOnboardingConfirmed?: boolean;
  showIneligible?: boolean;
  showJoin?: boolean;
}

export default function AgentOnboardingThankYou(props: IAgentOnboadingThankYou) {

  let {
    showListNow = false,
    showOnboardingConfirmed = false,
    showIneligible = false,
    showJoin = false,
  } = props;

  const classes = useStyles();

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      {showOnboardingConfirmed &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Onboarding confirmed
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            We’re excited to welcome you to the Propy community! Feel free to read more about Propy below ahead of our chat.
          </Typography>
        </>
      }
      {showIneligible &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Sorry, you’re not eligible yet
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            We’re unable to provide a free trial to you today, but you may subscribe now.
          </Typography>
        </>
      }
      {showListNow &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Thank you!
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            You may create listings and receive offers right away.
          </Typography>
          <ExternalLink href={`${BASE_URL}/list-property`}>
            <FloatingActionButton
              className={classes.createListingButton}
              text="Create Listing"
            />
          </ExternalLink>
        </>
      }
      {showJoin &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
            Join the Propy Community
          </Typography>
          <Typography className={classes.formSubtitle} variant="body1">
            Transform how you do real estate business with Team Propy 
          </Typography>
        </>
      }
      <div className={classes.postTitleSpacing}>
        {!showOnboardingConfirmed &&
          <>
            <Typography className={classes.formGetCertifiedTitle} gutterBottom variant="h5" component="div">
              Subscribe today
            </Typography>
            <div className={classes.subscribeCardContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <HorizontalImageLargeCardContainer
                    image={SubscribeCalloutFlowImage}
                    title="Propy Community Membership & Platform Access"
                    description="Join a global real estate agent community and get early access to innovative, research-backed tools by Propy"
                    ctaButtonLink="https://propy.com/browse/subscriptions/"
                    ctaButtonLinkExternal={true}
                    ctaButtonText="Join now"
                    ctaButtonDescription="$20 per month"
                    external={true}
                    actionNameGA='subscriptions_1_from_agent_onboarding_thank_you_v2'
                  />
                </Grid>
              </Grid>
            </div>
          </>
        }
        <Typography className={classes.formGetCertifiedTitle} gutterBottom variant="h5" component="div">
          More about Propy
        </Typography>
        <div className={classes.quickLinkCardContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <HorizontalImageCard
                image={TitleEscrowImage}
                title="Blockchain Title & Escrow"
                link="https://propy.com/browse/propytitle/"
                external={true}
                actionNameGA="propytitle_from_agent_onboarding_thank_you_v2"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <HorizontalImageCard
                image={TransactionPlatformImage}
                title="Transaction Platform & NFTs"
                link="https://propy.com/browse/subscriptions/"
                external={true}
                actionNameGA="subscriptions_2_from_agent_onboarding_thank_you_v2"
              />
            </Grid>
          </Grid>
        </div>
        <Link replace to={`${PROJECT_ROOT}/get-started/agent/reset`} style={{color: 'inherit'}}>
          <Typography className={[classes.helperTextContainer, 'flex-vertical-center', 'interactive'].join(' ')} gutterBottom variant="h6" component="div">
            <RefreshIcon style={{marginRight: 8}} /> Start over
          </Typography>
        </Link>
      </div>
    </animated.div>
  );
}