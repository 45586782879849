import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import { PropsFromRedux } from '../containers/TitleAndEscrowBannerContainer';

import FloatingActionButton from './FloatingActionButton';
import LinkWrapper from './LinkWrapper';

import BackgroundImage from '../assets/img/phone-banner-background.webp';
import ForegroundImage from '../assets/img/phone-banner-foreground.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 270,
      position: 'relative',
      boxShadow: '0px 0px 20px 0px #00000026',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundPosition: 'center right',
      overflow: 'hidden',
    },
    rootMobile: {
      width: '100%',
      position: 'relative',
      boxShadow: '0px 0px 20px 0px #00000026',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundPosition: 'left',
      backgroundSize: 'cover',
      overflow: 'hidden',
    },
    typographyContainer: {
      marginRight: theme.spacing(6),
      marginLeft: theme.spacing(6),
      color: 'white'
    },
    typographyContainerMobile: {
      maxWidth: 700,
      padding: theme.spacing(4),
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: 'white',
    },
    foregroundImageContainer: {
      marginLeft: theme.spacing(8),
      height: '100%',
      width: 400,
    },
    typographySpacer: {
      marginBottom: theme.spacing(2)
    },
    backgroundImage: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 8,
      boxShadow: '0px 0px 20px 0px #00000026',
      zIndex: -1,
    },
    foregroundImage: {
      paddingTop: 45,
      maxWidth: '100%',
    },
    foregroundImageMobile: {
      maxWidth: 300,
    },
    subtitle: {
      fontWeight: 300,
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }),
);

interface ITitleAndEscrowBanner {
  initialDelay?: number
}

const TitleAndEscrowBanner = (props: ITitleAndEscrowBanner & PropsFromRedux) => {
    const classes = useStyles();

    const {
      isConsideredMedium,
      initialDelay = 0,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const rootSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay
    })

    const backgroundSpring = useSpring({
      from: {
        opacity: 0,
      },
      to: {
        opacity: isInView ? 1 : 0,
      },
      delay: initialDelay
    })

    const foregroundSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(50px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(50px)",
      },
      delay: initialDelay + 250
    })

    const typographySpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateX(50px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-50px)",
      },
      delay: initialDelay
    })

    return (
      <animated.div ref={ref} className={isConsideredMedium ? classes.rootMobile : classes.root} style={rootSpring}>
        <animated.div className={classes.backgroundImage} style={backgroundSpring}/>
        {!isConsideredMedium &&
          <>
            <div className={classes.foregroundImageContainer}>
              <animated.img src={ForegroundImage} className={classes.foregroundImage} style={foregroundSpring} alt="app notification preview"/>
            </div>
            <animated.div className={classes.typographyContainer} style={typographySpring}>
              <Typography className={[classes.typographySpacer, "text-shadow"].join(" ")} style={{fontWeight: 'bold'}} variant="h3">
                Title & Escrow by Propy
              </Typography>
              <Typography className={[classes.typographySpacer, classes.subtitle, "text-shadow"].join(" ")} variant="h5" component="h4">
                Close your next real estate transaction with business-class <strong>Title & Escrow</strong> services by the Propy team
              </Typography>
              <LinkWrapper actionNameGA='propy_title_from_title_and_escrow_banner' link="https://propy.com/browse/propytitle/" external={true}>
                <FloatingActionButton
                  text="Get Started"
                />
              </LinkWrapper>
            </animated.div>
          </>
        }
        {isConsideredMedium &&
          <div className={classes.mobileContainer}>
            <animated.div className={classes.typographyContainerMobile} style={typographySpring}>
              <Typography className={[classes.typographySpacer, "text-shadow"].join(" ")} style={{fontWeight: 'bold'}} variant="h3">
                Title & Escrow by Propy
              </Typography>
              <Typography className={[classes.typographySpacer, classes.subtitle, "text-shadow"].join(" ")} variant="h5">
                Close your next real estate transaction with business-class <strong>Title & Escrow</strong> services by the Propy team
              </Typography>
              <LinkWrapper actionNameGA='propy_title_from_title_and_escrow_banner' link="https://propy.com/browse/propytitle/" external={true}>
                <FloatingActionButton
                  text="Get Started"
                />
              </LinkWrapper>
            </animated.div>
            <animated.img src={ForegroundImage} className={classes.foregroundImageMobile} style={foregroundSpring} alt="app notification preview"/>
          </div>
        }
      </animated.div>
    )
};

export default TitleAndEscrowBanner;