import React, { HTMLProps, useCallback } from 'react'
import styled from 'styled-components';

import {
  usePageTracking,
} from '../hooks';

const StyledLink = styled.a<{hoverUnderline: boolean}>`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;

  color: inherit;

  :hover {
    text-decoration: ${props => (props.hoverUnderline ? 'underline' : 'none')};
  }

  :focus {
    outline: none;
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`

const ExternalLink = ({
    target = '_blank',
    href,
    rel = 'noopener noreferrer',
    fullWidth = false,
    hoverUnderline = false,
    eventCategoryGA = "external_link_click",
    actionNameGA = false,
    ...rest
  }: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & { href: string, fullWidth?: boolean, hoverUnderline?: boolean, eventCategoryGA?: string, actionNameGA?: false | string }) => {

    const { sendCustomEvent } = usePageTracking();

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        if(eventCategoryGA && actionNameGA) {
          sendCustomEvent(eventCategoryGA, actionNameGA, href);
        }
        // don't prevent default, don't redirect if it's a new tab
        if (target === '_blank' || target === '_self' || event.ctrlKey || event.metaKey) {
            // Nothing
        } else {
          event.preventDefault()
        }
      },
      [target, eventCategoryGA, actionNameGA, href, sendCustomEvent]
    )
    return <StyledLink hoverUnderline={hoverUnderline} style={{...(fullWidth && { width: '100%' })}} target={target} rel={rel} href={href} onClick={handleClick} {...rest} />
}

export default ExternalLink;