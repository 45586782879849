interface IConsentToCookies {
  type: string;
  consent: boolean;
}
const consentToCookies = (state = false, action: IConsentToCookies) => {
  switch (action.type) {
      case 'GIVE_CONSENT_TO_COOKIES':
          return action.consent
      default:
          return state
  }
}

export default consentToCookies;