import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PaymentsIcon from '@mui/icons-material/Payments';
// import SellerIcon from '@mui/icons-material/Sell';
import TitleAndEscrowIcon from '@mui/icons-material/ReceiptLong';
// import CryptoCertificationIcon from '@mui/icons-material/VerifiedUser';
import GetStartedIcon from '@mui/icons-material/Flag';

import HighlightedFeatureCard from './HighlightedFeatureCard';

import { PropsFromRedux } from '../containers/HighlightedFeaturesContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    }
  }),
);

interface IHighlightedFeatures {
  initialDelay?: number
  hideHeading?: boolean
  useSingular?: boolean
}

const HighlightedFeatures = (props: IHighlightedFeatures & PropsFromRedux) => {
    const classes = useStyles();

    const {
      // isConsideredMobile,
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: initialDelay
    })

    return (
      <div className={classes.root} ref={ref}>
        {!hideHeading &&
          <animated.div className={classes.headingText} style={titleSpring}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              The Future of Homebuying is Now
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              Streamline your transaction from payment to closing
            </Typography>
          </animated.div>
        }
        <Grid style={{justifyContent: 'center'}} container columns={12} spacing={2}>
            {/* <Grid item xs={20} sm={10} md={6} lg={4}>
              <HighlightedFeatureCard
                Icon={SellerIcon}
                title={`Offer Management`}
                description="Place and manage real estate offers digitally."
                baseDelay={initialDelay}
                animationDelayMultiplier={1}
                isExternalLink={true}
                callToActionLink={`https://propy.com/browse/real-estate-offer-management/`}
                callToActionText="Learn More"
                noMarginBottom={true}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <HighlightedFeatureCard
                Icon={PaymentsIcon}
                title={`Transaction Platform`}
                description="Exchange properties 24/7 around the globe."
                baseDelay={initialDelay}
                animationDelayMultiplier={2}
                callToActionLink={`https://propy.com/browse/transaction-platform/`}
                isExternalLink={true}
                callToActionText="Learn More"
                noMarginBottom={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <HighlightedFeatureCard
                Icon={TitleAndEscrowIcon}
                title={`Title & Escrow`}
                description="Quickly and securely close with confidence."
                isExternalLink={true}
                baseDelay={initialDelay}
                animationDelayMultiplier={3}
                callToActionLink={`https://propy.com/browse/propytitle/`}
                callToActionText="Learn More"
                noMarginBottom={true}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <HighlightedFeatureCard
                Icon={CryptoCertificationIcon}
                title={`Crypto Certifications`}
                description="Prove crypto fluency or find certified agents."
                isExternalLink={true}
                baseDelay={initialDelay}
                animationDelayMultiplier={4}
                callToActionLink={'https://propy.com/browse/crypto-for-real-estate/'}
                callToActionText="Learn More"
                noMarginBottom={true}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <HighlightedFeatureCard
                Icon={GetStartedIcon}
                title={`Get Started Today`}
                description="Complete a quick form and let’s get started."
                isExternalLink={false}
                baseDelay={initialDelay}
                animationDelayMultiplier={5}
                callToActionLink={'/get-started'}
                callToActionText="Begin Now"
                boosted={true}
                noMarginBottom={true}
              />
            </Grid>
        </Grid>
      </div>
    )
};

export default HighlightedFeatures;