import React from 'react';

import { animated, useSpring } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import Typography from '@mui/material/Typography';

import LinkWrapper from './LinkWrapper';

import HoverShiftContainer from '../containers/HoverShiftContainer';

import {
  priceFormat,
} from '../utils';

import GoldMedal from '../assets/img/propy-gold-medal.png';
import SilverMedal from '../assets/img/propy-silver-medal.png';
import BronzeMedal from '../assets/img/propy-bronze-medal.png';

const positionToMedal : {[key: number]: string} = {
  1: GoldMedal,
  2: SilverMedal,
  3: BronzeMedal,
}

interface IProps {
  scaleFactor: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    card: {
      width: '100%',
    },
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    profilePic: {
      width: (props: IProps) => 100 * props.scaleFactor,
      height: (props: IProps) => 100 * props.scaleFactor,
      borderRadius: '50%',
      marginRight: theme.spacing(2),
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      fontWeight: 'bold',
    },
    positionCircleContainer: {
      backgroundColor: "rgba(0, 123, 218, 1)",
      borderRadius: '50%',
      left: 12,
      top: 12,
      width: (props: IProps) => 40 * props.scaleFactor,
      height: (props: IProps) => 40 * props.scaleFactor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '3px solid white',
      position: 'absolute',
    },
    positionCircleInner: {
      color: 'white',
    },
    amountPillContainer: {
      position: 'absolute',
      left: 'calc(100% - 16px)',
      transform: 'translateX(-100%)',
      top: 16,
    },
    amountPill: {
      backgroundColor: "rgba(0, 123, 218, 1)",
      borderRadius: '24px',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    }
  }),
);

interface IAgentLeaderboardEntry {
  position: number
  name: string
  description: string
  amount: number | string
  profileLink: string | false
  imageLink?: string
  animationDelayMultiplier: number
  baseDelay: number
  activateAnimation: boolean
  positionCircleColorOverride?: string
  pillColorOverride?: string
  borderColor?: string
  scaleFactor?: number
}

const AgentLeaderboardEntry = (props: IAgentLeaderboardEntry) => {

    const {
      name,
      description,
      amount,
      profileLink,
      imageLink,
      position,
      animationDelayMultiplier = 1,
      baseDelay,
      activateAnimation,
      positionCircleColorOverride,
      pillColorOverride,
      borderColor,
      scaleFactor = 0.8,
    } = props;

    const classes = useStyles({scaleFactor});

    // const [ref, isInView] = useInView({
    //   amount: 'any',
    //   once: true,
    // })

    const listingCardSpring = useSpring({
      from: {
        opacity: 0,
        marginTop: '-35px',
      },
      to: {
        opacity: activateAnimation ? 1 : 0,
        marginTop: activateAnimation ? '0' : '-35px',
      },
      delay: baseDelay + (200 * animationDelayMultiplier),
    })

    return (
      <animated.div style={listingCardSpring} className={classes.root}>
        <HoverShiftContainer>
          <LinkWrapper actionNameGA={`agent_leaderboard_profile_from_agent_leaderboard_entry`} link={profileLink} external={true}>
            <Card elevation={0} className={[classes.card, "box-shadow-default"].join(" ")} style={borderColor ? { border: `3px solid ${borderColor}` } : { border: `3px solid transparent` }}>
              <CardActionArea>
                <CardContent>
                  <div className={classes.rowContainer}>
                    <div>
                      {positionToMedal[position] ?
                        <div className={classes.positionCircleContainer} style={positionCircleColorOverride ? {backgroundColor: positionCircleColorOverride} : {}}>
                          <div className={classes.positionCircleInner} style={{height: '100%'}}>
                            <img src={positionToMedal[position]} style={{height: '100%'}} alt={`position ${position} medal`} />
                          </div>
                        </div>
                      :
                        <div className={classes.positionCircleContainer} style={positionCircleColorOverride ? {backgroundColor: positionCircleColorOverride} : {}}>
                          <div className={classes.positionCircleInner}>
                            <Typography style={{ fontWeight: 'bold', fontSize: `${1.3 * scaleFactor}rem` }} variant="h6" component="div">
                              {position}
                            </Typography>
                          </div>
                        </div>
                      }
                      <div style={{background: `url(${imageLink})`, backgroundSize: 'cover'}} className={classes.profilePic} />
                    </div>
                    <div>
                      <Typography className={classes.cardTitleClamp} style={{ fontSize: `${1.6 * scaleFactor}rem` }} variant="h5" component="div">
                        {name}
                      </Typography>
                      <Typography className={classes.cardTitleClamp} variant="h6" style={{fontWeight: 400, fontSize: `${1.5 * scaleFactor}rem`}} component="div">
                        {description}
                      </Typography>
                      <div className={[classes.amountPill, "leaderboard-pro-point-pill-mobile"].join(" ")} style={pillColorOverride ? {backgroundColor: pillColorOverride} : {}}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '0.775rem' }} variant="subtitle2" component="div">
                          {priceFormat(amount, 0, 'PRO')}
                        </Typography>
                      </div>
                    </div>
                    <div className={[classes.amountPillContainer, "leaderboard-pro-point-pill-desktop"].join(" ")}>
                      <div className={classes.amountPill} style={pillColorOverride ? {backgroundColor: pillColorOverride} : {}}>
                        <Typography style={{ fontWeight: 'bold' }} variant="subtitle2" component="div">
                          {priceFormat(amount, 0, 'PRO')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </LinkWrapper>
        </HoverShiftContainer>
      </animated.div>
    )
};

export default AgentLeaderboardEntry;