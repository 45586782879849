import React from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import OnboardingBackground from '../assets/img/onboarding-background.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      minHeight: 'calc(100vh - 360px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    onboardingBackground: {
      borderRadius: 16,
      backgroundImage: `url(${OnboardingBackground})`,
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundSize: 'cover',
      opacity: 0.3,
    },
    innerContentWrapper: {
      position: 'relative',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      maxWidth: '100%',
    }
  }),
);

interface IOnboardingFlowContainer {
  children: React.ReactNode
}

const OnboardingFlowWrapper = (props: IOnboardingFlowContainer) => {

    const {
      children,
    } = props;

    const classes = useStyles();

    return (
      <div className={classes.root}>
          <div className={classes.onboardingBackground} />
          <div className={classes.innerContentWrapper}>
            {children}
          </div>
      </div>
    )
}

export default OnboardingFlowWrapper;