import { connect, ConnectedProps } from 'react-redux';

import {
  setAgentFlowAgentProfileInfo,
  setAgentFlowProfileInfoFirstName,
  setAgentFlowProfileInfoLastName,
  setAgentFlowProfileInfoEmailAddress,
  setAgentFlowProfileInfoPhoneNumber,
  setAgentFlowProfileInfoPhoneNumberCountry,
  setAgentFlowCurrentStep,
  setAgentFlowAgentProfileAvailability,
  setSignedIn,
} from '../../state/actions';

import AgentOnboardingStep3ProfileInfo from '../../components/AgentOnboardingStepsV2/AgentOnboardingStep3ProfileInfo';

import { IAgentFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    agentFlow: IAgentFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    agentFlow: state.agentFlow,
})

const mapDispatchToProps = {
  setAgentFlowAgentProfileInfo,
  setAgentFlowProfileInfoFirstName,
  setAgentFlowProfileInfoLastName,
  setAgentFlowProfileInfoEmailAddress,
  setAgentFlowProfileInfoPhoneNumber,
  setAgentFlowProfileInfoPhoneNumberCountry,
  setAgentFlowCurrentStep,
  setAgentFlowAgentProfileAvailability,
  setSignedIn,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentOnboardingStep3ProfileInfo)