import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import SimpleCard from './SimpleCard';
import LinkWrapper from './LinkWrapper';

import FeaturedList1 from '../assets/img/featured_list_1.webp';
import FeaturedList2 from '../assets/img/featured_list_2.webp';
import FeaturedList3 from '../assets/img/featured_list_3.webp';
import FeaturedList4 from '../assets/img/featured_list_4.webp';
import FeaturedList5 from '../assets/img/featured_list_5.webp';
import FeaturedList6 from '../assets/img/featured_list_6.webp';

import { PropsFromRedux } from '../containers/HighlightedFeaturesContainer';
import HoverShiftContainer from '../containers/HoverShiftContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    previewCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2)
    },
    previewImageContainer: {
      width: 150,
      height: 150,
      marginRight: theme.spacing(2),
    },
    previewImage: {
      width: 150,
      height: 150,
    },
    previewTextContainer: {

    },
    descriptionContainer: {
      marginTop: theme.spacing(2),
    },
    descriptionText: {
      fontWeight: 300,
      lineHeight: 1.3,
      marginBottom: theme.spacing(2),
    },
    ctaText: {
      fontWeight: 'bold',
    }
  }),
);

interface IHighlightedBlogPosts {
  initialDelay?: number
  hideHeading?: boolean
  useSingular?: boolean
}

const featuredContentList = [
  {
    title: "MetaAgents",
    description: "The first NFT avatars specifically for real estate agents, with holder benefits",
    image: FeaturedList1,
    link: "https://propy.com/browse/meta-agent-nft-avatars/",
    external: true,
  },
  {
    title: "NFT Marketplace",
    description: "List and sell real-world properties online as Non-Fungible Tokens (NFTs)",
    image: FeaturedList2,
    link: "https://propy.com/browse/real-estate-nft/",
    external: true,
  },
  {
    title: "Blog",
    description: "Stay up to speed with Propy & the emergence of next-gen of Real-Estate technology",
    image: FeaturedList3,
    link: "https://propy.com/browse/blog/",
    external: true,
  },
  {
    title: "Resource Center",
    description: "From step-by-step guides to e-books and educational videos for the modern real estate professional",
    image: FeaturedList4,
    link: "https://propy.com/browse/resources/",
    external: true,
  },
  {
    title: "Crypto Agent Certifications",
    description: "Learn and validate cryptocurrency and blockchain understanding",
    image: FeaturedList5,
    link: "https://propy.com/browse/crypto-for-real-estate/",
    external: true,
  },
  {
    title: "Webinars by Propy",
    description: "Learn about blockchain technology and real estate with experts in the industry",
    image: FeaturedList6,
    link: "https://propy.com/browse/webinars",
    external: true,
  },
]

const HighlightedBlogPosts = (props: IHighlightedBlogPosts & PropsFromRedux) => {
    const classes = useStyles();

    const {
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-25px)',
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay
    })

    const postSpring = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-25px)',
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay + 250
    })

    return (
      <div className={classes.root} ref={ref}>
        {!hideHeading &&
          <animated.div className={classes.headingText} style={titleSpring}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              Featured by Propy
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              Explore various product offerings to super power your real estate business
            </Typography>
          </animated.div>
        }
        <Grid style={{justifyContent: 'center'}} container spacing={2}>
          {featuredContentList.map((item, index) => 
            <Grid key={`highlighted-callout-${index}`} item xs={12} sm={12} md={6} lg={6}>
              <animated.div style={postSpring}>
                <HoverShiftContainer>
                  <LinkWrapper link={item['link']} external={item['external']}>
                    <CardActionArea>
                      <SimpleCard
                        className={['box-shadow-default', classes.previewCard].join(" ")}
                        defaultOrientation={"row"}
                      >
                        <div className={classes.previewImageContainer}>
                          <img className={classes.previewImage} src={item['image']} alt={`${item['title']} Media Preview`} />
                        </div>
                        <div className={classes.previewTextContainer}>
                            <Typography style={{fontWeight: 'bold'}} variant="h5" component="h4">
                              {item['title']}
                            </Typography>
                            <div className={classes.descriptionContainer}>
                              <Typography className={classes.descriptionText} variant="body1">
                                {item['description']}
                              </Typography>
                            </div>
                            <Typography className={classes.ctaText} variant="body1">
                              Learn More
                            </Typography>
                        </div>
                      </SimpleCard>
                    </CardActionArea>
                  </LinkWrapper>
                </HoverShiftContainer>
              </animated.div>
            </Grid>
          )}
        </Grid>
      </div>
    )
};

export default HighlightedBlogPosts;