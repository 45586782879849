import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import LinkWrapper from './LinkWrapper';
import FloatingActionButton from './FloatingActionButton';

import { PropsFromRedux } from '../containers/HorizontalImageLargeCardContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    card: {
      width: '100%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    cardDescriptionClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      marginTop: theme.spacing(1),
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      fontWeight: 'bold',
    },
    badgeImage: {
      width: 115,
      position: 'absolute',
      zIndex: 10,
      top: '-20px',
      left: '30px',
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    image: {
      width: 'auto',
    },
    ctaButtonContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    ctaButtonDescription: {
      marginLeft: theme.spacing(2),
    },
    mediaContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'black',
    }
  }),
);

interface IHorizontalImageLargeCard {
  image: string
  title: string
  description?: string
  wrapperLink?: string
  ctaButtonLink?: string
  ctaButtonLinkExternal?: boolean
  ctaButtonText?: string
  ctaButtonDescription?: string
  external?: boolean
  actionNameGA?: string
}

const HorizontalImageLargeCard = (props: IHorizontalImageLargeCard & PropsFromRedux) => {
    const classes = useStyles();

    const {
      isConsideredMobile,
      image,
      title,
      description,
      wrapperLink,
      ctaButtonLink,
      ctaButtonLinkExternal,
      ctaButtonText,
      ctaButtonDescription,
      external = false,
      actionNameGA,
    } = props;

    return (
      <div className={classes.root}>
        <LinkWrapper actionNameGA={actionNameGA} link={wrapperLink} external={external} className={classes.card}>
          <Card elevation={3} className={[classes.card, isConsideredMobile ? classes.vertical : classes.horizontal].join(" ")}>
            <div className={classes.mediaContainer}>
              <CardMedia
                component="img"
                height="220px"
                image={image}
                alt={`${title} media`}
                className={classes.image}
              />
            </div>
            <div>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.cardTitleClamp} variant="h5" component="div">
                  {title}
                </Typography>
                <Typography className={classes.cardDescriptionClamp} variant="body1" component="div">
                  {description}
                </Typography>
                {ctaButtonText &&
                  <div className={classes.ctaButtonContainer}>
                    <LinkWrapper
                      link={ctaButtonLink}
                      external={ctaButtonLinkExternal}
                    >
                      <FloatingActionButton
                        text={ctaButtonText}
                      />
                    </LinkWrapper>
                    {ctaButtonDescription &&
                      <Typography className={classes.ctaButtonDescription} color="text.secondary" variant="body1" component="div">
                        {ctaButtonDescription}
                      </Typography>
                    }
                  </div>
                }
              </CardContent>
            </div>
          </Card>
        </LinkWrapper>
      </div>
    )
};

export default HorizontalImageLargeCard;