import { connect, ConnectedProps } from 'react-redux';

import {
  setAgentFlowTrialOrSkip,
  setAgentFlowCurrentStep,
} from '../../state/actions';

import AgentOnboardingStep1 from '../../components/AgentOnboardingStepsV2/AgentOnboardingStep1GetStarted';

import { IAgentFlowData } from '../../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  agentFlow: IAgentFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  agentFlow: state.agentFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setAgentFlowTrialOrSkip,
  setAgentFlowCurrentStep,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentOnboardingStep1)