import React, { useState } from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import { animated, useSpring, useInView } from '@react-spring/web';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import FloatingActionButton from '../FloatingActionButton';

import { PropsFromRedux } from '../../containers/BuyerOnboardingSteps/BuyerOnboardingStep2LookingPreferencesContainer';

import {
  PROPY_BLUE_DARK,
  PROPY_BLUE,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
  PURCHASE_TYPE,
} from '../../constants';

import {
  IBuyerFlowData
} from '../../interfaces';

import { FlowService } from '../../services/api';

import ArizonaIcon from '../../assets/img/arizona.webp';
import ColoradoIcon from '../../assets/img/colorado.webp';
import FloridaIcon from '../../assets/img/florida.webp';
import GlobeIcon from '../../assets/img/globe.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    card: {
      width: '100%',
      height: 200,
      display: 'flex',
      justifyContent: 'center'
    },
    cardSelected: {
      backgroundColor: PROPY_BLUE,
      color: 'white',
    },
    imageUnselected: {
      opacity: 0.5,
    },
    imageSelected: {
      filter: 'brightness(0) invert(1)',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      marginTop: theme.spacing(3),
    },
    mapIcon: {
      height: 70,
    },
    optionButton: {
      fontSize: '20px',
      padding: theme.spacing(4),
      borderRadius: 40,
      width: '100%'
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    },
    sectionSpacer: {
      marginTop: theme.spacing(4),
    },
    sectionSpacerExtra: {
      marginTop: theme.spacing(6),
    },
    inputLabelSpacer: {
      fontSize: '0.9rem',
      marginBottom: theme.spacing(2),
    },
    submitButtonContainer: {
      marginTop: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
    customErrorHelper: {
      marginTop: theme.spacing(1),
    },
    customErrorHelperExtraSpace: {
      marginTop: theme.spacing(2),
    }
  }),
);

const buyerLookingLocationOptions = [
  {
    value: "Florida",
    label: "Florida",
    icon: FloridaIcon,
  },
  {
    value: "Colorado",
    label: "Colorado",
    icon: ColoradoIcon,
  },
  {
    value: "Arizona",
    label: "Arizona",
    icon: ArizonaIcon,
  },
  {
    value: "other-states-non-us",
    label: "Other or Non-US",
    icon: GlobeIcon,
  },
]

interface IPaymentOption {
  label: string;
  value: IBuyerFlowData["purchaseType"];
}

const buyerLookingPaymentOptions : IPaymentOption[] = [
  {
    value: "cash",
    label: "Cash",
  },
  {
    value: "mortgage",
    label: "Mortgage",
  },
  {
    value: "crypto",
    label: "Crypto",
  }
]

interface IPriceRangeOption {
  label: string;
  value: IBuyerFlowData["priceRange"];
}

const buyerLookingPriceRangeOptions : IPriceRangeOption[] = [
  {
    value: "<$200k",
    label: "<$200k",
  },
  {
    value: "$200k - $500k",
    label: "$200k - $500k",
  },
  {
    value: "$500k - $999k",
    label: "$500k - $999k",
  },
  {
    value: "$1m+",
    label: "$1m+",
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function BuyerOnboardingStep2PickLocation(props: PropsFromRedux & IStepProps) {

  const {
    setBuyerFlowDesiredLocation,
    setBuyerFlowPurchaseType,
    setBuyerFlowPriceRange,
    setBuyerFlowCurrentStep,
    buyerFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const classes = useStyles();

  // const [desiredLocation, setLocation] = React.useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLocationSelectionMissing, setIsLocationSelectionMissing] = useState(false);
  const [isPurchaseTypeSelectionMissing, setIsPurchaseTypeMissing] = useState(false);
  const [isPriceRangeSelectionMissing, setIsPriceRangeSelectionMissing] = useState(false);

  const handleLocationChange = (event: SelectChangeEvent) => {
    setBuyerFlowDesiredLocation(event.target.value);
    setIsLocationSelectionMissing(false);
  };

  const handlePurchaseTypeChange = (purchaseType: IPaymentOption["value"]) => {
    //@ts-ignore
    setBuyerFlowPurchaseType(purchaseType);
    setIsPurchaseTypeMissing(false);
  };

  const handlePriceRangeChange = (event: SelectChangeEvent) => {
    //@ts-ignore
    setBuyerFlowPriceRange(event.target.value);
    setIsPriceRangeSelectionMissing(false);
  };

  const nextStep = async () => {
    let isIncomplete = false;
    if(!buyerFlow.desiredLocation) {
      isIncomplete = true;
      setIsLocationSelectionMissing(true);
    }
    if(!buyerFlow.purchaseType) {
      isIncomplete = true;
      setIsPurchaseTypeMissing(true);
    }
    if(!buyerFlow.priceRange) {
      isIncomplete = true;
      setIsPriceRangeSelectionMissing(true);
    }
    if(!isIncomplete) {
      // submit data to backend
      if(!recaptchaRef?.current) {
        toast.error("Recaptcha expired, please refresh the page.");
      }
      if(buyerFlow.sessionId && recaptchaRef.current) {
        setStepSubmitting(true);
        setIsSubmitting(true);
        try {
          const token = await getToken();
          await FlowService.patchSession(buyerFlow.sessionId, {
            Type: FLOW_TYPE_TO_LEAD_TYPE["buyer"],
            ...(buyerFlow.serviceRequirement && { Actions: [SERVICE_REQUIREMENT[buyerFlow.serviceRequirement]] }),
            ...(buyerFlow.desiredLocation && { Location: { placeDesc: buyerFlow.desiredLocation } }),
            ...(buyerFlow.purchaseType && { Purchase: PURCHASE_TYPE[buyerFlow.purchaseType] }),
            ...(buyerFlow.firstName && { FirstName: buyerFlow.firstName }),
            ...(buyerFlow.lastName && { LastName: buyerFlow.lastName }),
            ...(buyerFlow.emailAddress && { Email: buyerFlow.emailAddress }),
            ...(buyerFlow.phoneNumber && { PhoneNumber: buyerFlow.phoneNumber }),
            ...(buyerFlow.phoneNumberCountry && { PhoneCountryCode: buyerFlow.phoneNumberCountry }),
            ...(buyerFlow.priceRange && { PriceRange: buyerFlow.priceRange }),
            ...(buyerFlow.hasAgent && { HasAgent: buyerFlow.hasAgent === "has-agent" ? true : false }),
            ...(buyerFlow.agentEmailAddress && { AgentEmail: buyerFlow.agentEmailAddress }),
            ...(buyerFlow.agentPhoneNumber && { AgentPhoneNumber: buyerFlow.agentPhoneNumber }),
            ...(buyerFlow.agentPhoneNumberCountry && { AgentPhoneCountryCode: buyerFlow.agentPhoneNumberCountry }),
            ...(buyerFlow.agentFirstName && { AgentFirstName: buyerFlow.agentFirstName }),
            ...(buyerFlow.agentLastName && { AgentLastName: buyerFlow.agentLastName }),
            ...(buyerFlow.agentMessage && { MessageToAgent: buyerFlow.agentMessage }),
          }, token);
          setStepSubmitting(false);
          setIsSubmitting(false);
          toast.success('Saved progress!');
          setBuyerFlowCurrentStep(buyerFlow.serviceRequirement === "looking" ? "profile-looking" : "profile-title-and-escrow");
        } catch (e) {
          console.log({e})
          setStepSubmitting(false);
          setIsSubmitting(false);
          toast.error("Unable to save progress, please try again or contract support");
        }
      }
    } else {
      setStepSubmitting(false);
      setIsSubmitting(false);
      toast.error("Please fill out missing options");
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Tell us about your dream property
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="buyer-onboarding-step-2-location-dropdown-label">Location</InputLabel>
        <Select
          labelId="buyer-onboarding-step-2-location-dropdown-label"
          id="buyer-onboarding-step-2-location-dropdown"
          value={typeof buyerFlow.desiredLocation === 'string' ? buyerFlow.desiredLocation : ''}
          label="Location"
          onChange={handleLocationChange}
          error={isLocationSelectionMissing}
        >
          {buyerLookingLocationOptions.map((item, index) => 
            <MenuItem id={`buyer-onboarding-step-2-location-dropdown-label-${item.value}`} key={`location-selection-entry-${index}`} value={item.value}>{item.label}</MenuItem>
          )}
        </Select>
        {isLocationSelectionMissing &&
          <div className={classes.customErrorHelper}>
            <FormHelperText id="buyer-onboarding-step-2-location-dropdown-error" error={true}>Please select a location</FormHelperText>
          </div>
        }
      </FormControl>
      <div className={classes.sectionSpacer}>
        <InputLabel className={classes.inputLabelSpacer} id="buyer-onboarding-step-2-payment-type-label">Purchase type</InputLabel>
        <Grid id="buyer-onboarding-step-2-payment-type-option-container" container spacing={3}>
          {buyerLookingPaymentOptions.map((item, index) => (
            <Grid key={`budget-quick-selection-entry-map-${index}`} item xs={12} sm={12} md={4} lg={4}>
              <FloatingActionButton 
                key={`purchase-type-option-${index}`}
                onClick={() => handlePurchaseTypeChange(item.value)}
                className={
                  [
                    classes.optionButton,
                    (buyerFlow.purchaseType === item.value) ? classes.optionButtonSelected : classes.optionButtonUnselected
                  ].join(" ")
                }
                textColor={(buyerFlow.purchaseType === item.value) ? 'white' : PROPY_BLUE}
                size="large"
                buttonColor={(buyerFlow.purchaseType !== item.value) ? "default" : "primary"}
                text={item.label}
              />
            </Grid>
          ))}
        </Grid>
        {isPurchaseTypeSelectionMissing &&
          <div className={classes.customErrorHelperExtraSpace}>
            <FormHelperText id="buyer-onboarding-step-2-payment-type-option-container-error" error={true}>Please select a purchase type</FormHelperText>
          </div>
        }
      </div>
      <div className={classes.sectionSpacerExtra}>
        <FormControl fullWidth>
          <InputLabel id="buyer-onboarding-step-2-price-range-dropdown-label">Price range</InputLabel>
          <Select
            labelId="buyer-onboarding-step-2-price-range-dropdown-label"
            id="buyer-onboarding-step-2-price-range-dropdown"
            value={typeof buyerFlow.priceRange === 'string' ? buyerFlow.priceRange : ''}
            label="Price Range"
            onChange={handlePriceRangeChange}
            error={isPriceRangeSelectionMissing}
          >
            {buyerLookingPriceRangeOptions.map((item, index) => 
              <MenuItem id={`buyer-onboarding-step-2-price-range-dropdown-entry-${index}`} key={`price-range-selection-entry-${index}`} value={item.value ? item.value : ""}>{item.label}</MenuItem>
            )}
          </Select>
          {isPriceRangeSelectionMissing &&
            <div className={classes.customErrorHelper}>
              <FormHelperText id="buyer-onboarding-step-2-price-range-dropdown-error" error={true}>Please select a price range</FormHelperText>
            </div>
          }
        </FormControl>
      </div>
      <div className={classes.submitButtonContainer}>
        <FloatingActionButton
          id="buyer-onboarding-step-2-looking-preferences-submit-button"
          className={classes.submitButton}
          disabled={isSubmitting}
          onClick={nextStep}
          text="Next"
        />
      </div>
    </animated.div>
  );
}