import { connect, ConnectedProps } from 'react-redux';

import AgentLeaderboard from '../components/AgentLeaderboard';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    isConsideredMedium: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    isConsideredMedium: state.isConsideredMedium,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentLeaderboard)