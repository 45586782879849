import { ISellerFlowData } from '../../interfaces';

interface IPropertyLocation {
  description: string
  placeId: string
}

interface IAgentInfo {
  agentEmailAddress: false | string;
  agentPhoneNumber: false | string;
  agentPhoneNumberCountry: false | string;
  agentMessage: false | string;
  agentFirstName: false | string;
  agentLastName: false | string;
}

interface IBuyerFlowDataAction {
  type: string;
  propertyLocation: IPropertyLocation
  agentInfo: IAgentInfo
}

const defaultState : ISellerFlowData = {
  currentStep: "",
  profileAvailability: false,
  sessionId: false,
  serviceRequirements: [],
  desiredLocation: false,
  hasAgent: false,
  firstName: false,
  lastName: false,
  emailAddress: false,
  phoneNumber: false,
  phoneNumberCountry: false,
  agentEmailAddress: false,
  agentPhoneNumber: false,
  agentPhoneNumberCountry: false,
  agentMessage: false,
  agentFirstName: false,
  agentLastName: false,
  propertyLocationPlaceId: false,
  propertyLocationDescription: false,
  propertyMLS: false,
}

const sellerFlow = (
  state = defaultState,
  action: IBuyerFlowDataAction & ISellerFlowData
) => {
  switch (action.type) {
    case 'SET_SELLER_FLOW_SESSION_ID': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.sessionId = action.sessionId;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_CURRENT_STEP': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.currentStep = action.currentStep;
      console.log({stateCopy})
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_SERVICE_REQUIREMENT': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      console.log({'action.serviceRequirements': action.serviceRequirements})
      stateCopy.serviceRequirements = action.serviceRequirements;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_DESIRED_LOCATION': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.desiredLocation = action.desiredLocation;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROPERTY_LOCATION': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      let {placeId, description} = action.propertyLocation;
      stateCopy.propertyLocationPlaceId = placeId;
      stateCopy.propertyLocationDescription = description;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO': {
      let {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        phoneNumberCountry,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      stateCopy.lastName = lastName;
      stateCopy.emailAddress = emailAddress;
      stateCopy.phoneNumber = phoneNumber;
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO_FIRST_NAME': {
      let {
        firstName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO_LAST_NAME': {
      let {
        lastName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.lastName = lastName;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO_EMAIL_ADDRESS': {
      let {
        emailAddress,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.emailAddress = emailAddress;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO_PHONE_NUMBER': {
      let {
        phoneNumber,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumber = phoneNumber;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY': {
      let {
        phoneNumberCountry,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROFILE_AVAILABILITY': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.profileAvailability = action.profileAvailability;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_HAS_AGENT': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.hasAgent = action.hasAgent;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO': {
      let {
        agentEmailAddress,
        agentPhoneNumber,
        agentPhoneNumberCountry,
        agentFirstName,
        agentLastName,
        agentMessage,
      } = action.agentInfo;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentEmailAddress = agentEmailAddress;
      stateCopy.agentPhoneNumber = agentPhoneNumber;
      stateCopy.agentPhoneNumberCountry = agentPhoneNumberCountry;
      stateCopy.agentMessage = agentMessage;
      stateCopy.agentFirstName = agentFirstName;
      stateCopy.agentLastName = agentLastName;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_EMAIL_ADDRESS': {
      let {
        agentEmailAddress,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentEmailAddress = agentEmailAddress;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_PHONE_NUMBER': {
      let {
        agentPhoneNumber,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentPhoneNumber = agentPhoneNumber;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_PHONE_NUMBER_COUNTRY': {
      let {
        agentPhoneNumberCountry,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentPhoneNumberCountry = agentPhoneNumberCountry;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_FIRST_NAME': {
      let {
        agentFirstName,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentFirstName = agentFirstName;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_LAST_NAME': {
      let {
        agentLastName,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentLastName = agentLastName;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_AGENT_INFO_MESSAGE': {
      let {
        agentMessage,
      } = action;
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentMessage = agentMessage;
      return stateCopy;
    }
    case 'SET_SELLER_FLOW_PROPERTY_MLS': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.propertyMLS = action.propertyMLS;
      return stateCopy;
    }
    case 'RESET_SELLER_FLOW': {
      let stateCopy : ISellerFlowData = JSON.parse(JSON.stringify(defaultState));
      return stateCopy;
    }
    default:
      return state
  }
}

export default sellerFlow;