import {combineReducers} from 'redux';
import agentFlow from './agentFlow';
import buyerFlow from './buyerFlow';
import sellerFlow from './sellerFlow';
import showLeftMenu from './showLeftMenu';
import activeAccount from './activeAccount';
import darkMode from './darkMode'
import isConsideredMobile from './isConsideredMobile';
import isConsideredMedium from './isConsideredMedium';
import signedIn from './signedIn';
import consentToCookies from './consentToCookies';
import dismissedPopupPropyAI from './dismissedPopupPropyAI';
import dismissedPopupAIRealEstateSummit2023 from './dismissedPopupAIRealEstateSummit2023';

const rootReducer = combineReducers({
    agentFlow,
    buyerFlow,
    sellerFlow,
    showLeftMenu,
    activeAccount,
    darkMode,
    isConsideredMobile,
    isConsideredMedium,
    signedIn,
    consentToCookies,
    dismissedPopupPropyAI,
    dismissedPopupAIRealEstateSummit2023,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;