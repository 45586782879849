import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import { toast } from 'sonner'

import parse from 'autosuggest-highlight/parse';

import { animated, useSpring, useInView } from '@react-spring/web';

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { PropsFromRedux } from '../../containers/SellerOnboardingSteps/SellerOnboardingStep3CommunityNftLocationFinderContainer';

import {
  PROPY_BLUE_DARK,
  PROPY_BLUE,
  GOOGLE_MAPS_API_KEY,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
} from '../../constants';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import {
  ApiService,
  FlowService,
} from '../../services/api';

import {
  debounce,
} from '../../utils';

import {
  ServiceRequirementsSellerEnum,
} from "../../interfaces";

import FloatingActionButton from '../FloatingActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    formSubtitle: {
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      fontSize: '18px',
    },
    bodyText: {
      fontWeight: '400',
      marginTop: theme.spacing(3),
      fontSize: '18px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    card: {
      width: '100%',
      height: 170,
      display: 'flex',
      justifyContent: 'center'
    },
    cardSelected: {
      backgroundColor: PROPY_BLUE,
      color: 'white',
    },
    imageUnselected: {
      opacity: 0.3,
    },
    imageSelected: {
      filter: 'brightness(0) invert(1)',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      marginTop: theme.spacing(3),
    },
    mapIcon: {
      height: 70,
    },
    submitButtonContainer: {
      marginTop: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
    searchModeSwitch: {
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#007BDA',
    },
    customErrorHelper: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface IMLSOption {
  address: string
  mlsNumber: string
}

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

const mlsInputValue = (reduxValue: string | false, localStateValue: string | false) => {
  if(reduxValue) {
    return reduxValue;
  } else if (localStateValue) {
    return localStateValue;
  }
  return '';
}

export default function BuyerOnboardingStep2PickLocation(props: PropsFromRedux & IStepProps) {

  const {
    setSellerFlowCurrentStep,
    setStepSubmitting,
    setSellerFlowPropertyLocation,
    setSellerFlowPropertyMLS,
    sellerFlow,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
  });

  const classes = useStyles();

  const [searchMode, setSearchMode] = useState<'location' | 'mls'>(sellerFlow?.propertyMLS ? 'mls' : 'location');
  const [isSearchingMLS, setIsSearchingMLS] = useState(false);
  const [mlsNumber, setMlsNumber] = useState<false | string>(sellerFlow?.propertyMLS ? sellerFlow?.propertyMLS : false);
  const [mlsOptions, setMlsOptions] = useState<IMLSOption[]>([]);
  const [showSelectionError, setShowSelectionError] = useState(false);
  const [showMlsError, setShowMlsError] = useState(false);

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  useEffect(() => {
    let isMounted = true;
    setShowMlsError(false);
    const fetchMls = async () => {
      setMlsOptions([]);
      setIsSearchingMLS(true);
      let mlsSearchResponse = await ApiService.get(`https://dev.api.propy.com/api/v3/properties/mls`, `?mls=${mlsNumber}`);
      setIsSearchingMLS(false);
      if(isMounted) {
        if(mlsSearchResponse?.data?.property?.address && mlsNumber) {
          setShowMlsError(false);
          setMlsOptions([{
            mlsNumber: mlsNumber,
            address: mlsSearchResponse?.data?.property?.address
          }]);
        } else {
          setShowMlsError(true);
        }
      }
    }
    if(mlsNumber && mlsNumber?.length > 0 && searchMode === 'mls') {
      let debouncedFetchMls = debounce(fetchMls, 1000, false);
      debouncedFetchMls();
    }
    return () => {
      isMounted = false;
    };
  }, [mlsNumber, searchMode])

  const nextStep = async () => {
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(sellerFlow.sessionId && recaptchaRef.current) {
      if((sellerFlow?.propertyLocationDescription && sellerFlow?.propertyLocationPlaceId) || (sellerFlow?.propertyMLS)) {
        setStepSubmitting(true);
        const token = await getToken();
        await FlowService.patchSession(sellerFlow.sessionId, {
          Type: FLOW_TYPE_TO_LEAD_TYPE["seller"],
          ...(sellerFlow.serviceRequirements && { Actions: sellerFlow.serviceRequirements.map((entry: keyof typeof ServiceRequirementsSellerEnum) => SERVICE_REQUIREMENT[entry]) }),
          ...(sellerFlow.firstName && { FirstName: sellerFlow.firstName }),
          ...(sellerFlow.lastName && { LastName: sellerFlow.lastName }),
          ...(sellerFlow.emailAddress && { Email: sellerFlow.emailAddress }),
          ...(sellerFlow.phoneNumber && { PhoneNumber: sellerFlow.phoneNumber }),
          ...(sellerFlow.phoneNumberCountry && { PhoneCountryCode: sellerFlow.phoneNumberCountry }),
          ...(sellerFlow.hasAgent && { HasAgent: sellerFlow.hasAgent === "has-agent" ? true : false }),
          ...(sellerFlow.agentEmailAddress && { AgentEmail: sellerFlow.agentEmailAddress }),
          ...(sellerFlow.agentPhoneNumber && { AgentPhoneNumber: sellerFlow.agentPhoneNumber }),
          ...(sellerFlow.agentPhoneNumberCountry && { AgentPhoneCountryCode: sellerFlow.agentPhoneNumberCountry }),
          ...(sellerFlow.agentFirstName && { AgentFirstName: sellerFlow.agentFirstName }),
          ...(sellerFlow.agentLastName && { AgentLastName: sellerFlow.agentLastName }),
          ...(sellerFlow.agentMessage && { MessageToAgent: sellerFlow.agentMessage }),
          ...({ location: { placeId: sellerFlow?.propertyLocationPlaceId ? sellerFlow?.propertyLocationPlaceId : null, placeDesc: sellerFlow?.propertyLocationDescription ? sellerFlow?.propertyLocationDescription : null } }),
          ...({ MLSNumber: sellerFlow?.propertyMLS ? sellerFlow?.propertyMLS : null }),
        }, token);
        setStepSubmitting(false);
        toast.success('Saved progress!');
        setSellerFlowCurrentStep('agent-details');
      } else {
        setShowSelectionError(true);
      }
    }
  }

  return (
    <animated.div ref={ref} style={stepperSpring}>
      {/* Useful for debugging */}
      {/* <pre>
        {`sellerFlow?.propertyMLS: ${sellerFlow?.propertyMLS}`}<br/>
        {`mlsNumber: ${mlsNumber}`}<br/>
        {`sellerFlow?.propertyLocationDescription: ${sellerFlow?.propertyLocationDescription}`}<br/>
        {`sellerFlow?.propertyLocationPlaceId: ${sellerFlow?.propertyLocationPlaceId}`}<br/>
      </pre> */}
      {searchMode === 'location' &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
            Where is the property located?
          </Typography>
          <Autocomplete
            freeSolo
            id="autocomplete-location-searcher"
            disableClearable
            noOptionsText="No matched location"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            loading={isPlacePredictionsLoading}
            value={sellerFlow?.propertyLocationDescription ? sellerFlow?.propertyLocationDescription : ''}
            options={placePredictions.map((option) => option)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                color="primary"
                label="Location"
                placeholder='Enter a location'
                variant="outlined"
                onChange={(evt) => {
                  getPlacePredictions({ input: evt.target.value });
                  setMlsOptions([]);
                  setMlsNumber(false);
                  setSellerFlowPropertyMLS(false);
                  setSellerFlowPropertyLocation({placeId: false, description: false});
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isPlacePredictionsLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              console.log({option})
              const matches =
                option.structured_formatting.main_text_matched_substrings || [];
      
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [match.offset, match.offset + match.length]),
              );
      
              return (
                <li {...props}>
                  <Grid onClick={() => {
                    setShowSelectionError(false);
                    setSellerFlowPropertyLocation({placeId: option.place_id, description: option.description});
                    setSellerFlowPropertyMLS(false);
                  }} container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        </>
      }
      {searchMode === 'mls' &&
        <>
          <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
            What is the property's MLS number?
          </Typography>
          <Autocomplete
            freeSolo
            id="autocomplete-mls-searcher"
            disableClearable
            noOptionsText="No matched MLS number"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.mlsNumber
            }
            filterOptions={(x) => x}
            loading={isPlacePredictionsLoading}
            options={mlsOptions}
            value={mlsInputValue(sellerFlow?.propertyMLS, mlsNumber)}
            open={!sellerFlow?.propertyMLS || (sellerFlow?.propertyMLS !== mlsNumber) ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                color="primary"
                label="MLS number"
                placeholder='Enter MLS number'
                variant="outlined"
                onChange={(evt) => {
                  setMlsNumber(evt.target.value);
                  setSellerFlowPropertyMLS(false);
                  setSellerFlowPropertyLocation({placeId: false, description: false});
                  setMlsOptions([]);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isSearchingMLS ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => {
              console.log({option})
              return (
                <li {...props}>
                  <Grid 
                    onClick={() => {
                      setShowSelectionError(false);
                      setSellerFlowPropertyLocation({placeId: false, description: option.address});
                      setSellerFlowPropertyMLS(option.mlsNumber);
                    }} 
                    container
                    alignItems="center"
                  >
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Box
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {option.mlsNumber}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {option.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        </>
      }
      {showSelectionError &&
        <div className={classes.customErrorHelper}>
          <FormHelperText error={true}>Please select either a location or MLS option from the list of options</FormHelperText>
        </div>
      }
      {!showSelectionError && showMlsError &&
        <div className={classes.customErrorHelper}>
          <FormHelperText error={true}>Unable to find an MLS record matching your provided input</FormHelperText>
        </div>
      }
      <Typography className={classes.bodyText} variant="h6" component="h6">
        No luck? Try {searchMode === 'mls' ? <span className={[classes.searchModeSwitch, 'hovered-propy-blue'].join(' ')} onClick={() => setSearchMode('location')}>searching by location</span> : <span className={[classes.searchModeSwitch, 'hovered-propy-blue'].join(' ')} onClick={() => setSearchMode('mls')}>searching by MLS#</span>}
      </Typography>
      <div className={classes.submitButtonContainer}>
        <FloatingActionButton
          className={classes.submitButton}
          onClick={() => nextStep()}
          text="Next"
        />
      </div>
    </animated.div>
  );
}