import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';

import { SvgIconComponent } from '@mui/icons-material';

import HoverShiftContainer from '../containers/HoverShiftContainer';

import { PROPY_BLUE } from '../constants';

import LinkWrapper from './LinkWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
    },
    rootNoMarginBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 0,
    },
    card: {
      width: '100%',
      maxWidth: 420,
    },
    cardNonBoosted: {
      border: '3px solid transparent',
    },
    cardBoosted: {
      border: '3px solid #36a0f0',
    },
    boostedTextTitle: {
      color: 'black',
    },
    boostedText: {
      // color: 'black',
    },
    cardTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      maxWidth: 150,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    cardDescriptionClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 4,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      textAlign: 'center',
    },
    callToAction: {
      marginTop: theme.spacing(2),
      width: '100%',
      fontWeight: 'bold',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  }),
);

interface IHighlightedFeatureCardProps {
  Icon: SvgIconComponent,
  title: string,
  description: string,
  baseDelay: number
  animationDelayMultiplier?: number
  callToActionText: string
  callToActionLink: string
  noMarginBottom?: boolean
  isExternalLink?: boolean
  boosted?: boolean
}

const HighlightedFeatureCard = (props: IHighlightedFeatureCardProps) => {
    const classes = useStyles();

    const {
      Icon,
      title,
      description,
      baseDelay,
      animationDelayMultiplier = 1,
      callToActionLink,
      callToActionText,
      noMarginBottom = false,
      isExternalLink = false,
      boosted = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const listingCardSpring = useSpring({
      from: {
        opacity: 0,
        marginTop: '-35px',
      },
      to: {
        opacity: isInView ? 1 : 0,
        marginTop: isInView ? '0' : '-35px',
      },
      delay: baseDelay + (200 * animationDelayMultiplier),
    })

    return (
      <animated.div style={listingCardSpring} className={noMarginBottom ? classes.rootNoMarginBottom : classes.root} ref={ref}>
          <HoverShiftContainer maxWidth={420}>
            <Card elevation={3} className={[classes.card, !boosted ? classes.cardNonBoosted : classes.cardBoosted].join(" ")}>
              <LinkWrapper link={callToActionLink} external={isExternalLink}>
                <CardActionArea>
                  <CardContent>
                    <div className={classes.iconContainer}>
                      <Icon style={{height: 50, width: 50, color: boosted ? PROPY_BLUE : PROPY_BLUE }}/>
                    </div>
                    <Typography className={[classes.cardTitle, boosted ? classes.boostedTextTitle : ""].join(" ")} gutterBottom variant="h5" component="div">
                      {title}
                    </Typography>
                    <Typography className={[classes.cardDescriptionClamp, boosted ? classes.boostedText : ""].join(" ")} variant="body1" color="text.secondary">
                      {description}
                    </Typography>
                    <div className={["flex-center-all", classes.callToAction].join(' ')}>
                      <Typography variant="button" color="text.secondary" className={boosted ? classes.boostedText : ""}>
                        {callToActionText}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </LinkWrapper>
            </Card>
          </HoverShiftContainer>
      </animated.div>
    )
};

export default HighlightedFeatureCard;