import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import GenericHeroContainer from '../containers/GenericHeroContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6)
    },
    card: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '250px',
    },
    exampleImage: {
        width: '30%',
        margin: theme.spacing(4),
    }
  }),
);

const HomePage = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="md">
          <div className={classes.root}>
            <GenericHeroContainer
              title={"Find an Agent"}
              subtitle={"Browse our catalogue of world-class, Propy-certified agents"}
              // image1={PropyHomeHeroPreview}
              // image2={PropyHomeHeroPreview2}
              // image3={PropyHomeHeroPreview3}
            />
          </div>
        </Container>
    )
};

export default HomePage;