import React from 'react';

import { Helmet, HelmetProvider } from "react-helmet-async";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import HomePageOgImage from '../assets/seo/home-page.webp';

import {
    PROJECT_ROOT
} from '../constants';

import HomePageHeroContainer from '../containers/HomePageHeroContainer';
import HighlightedVideoContainer from '../containers/HighlightedVideoContainer';
import HighlightedFeaturesContainer from '../containers/HighlightedFeaturesContainer';
import HighlightedPartnersContainer from '../containers/HighlightedPartnersContainer';
import PropyAndCoinbasePrimeBannerContainer from '../containers/PropyAndCoinbasePrimeBannerContainer';
import FeaturedListingsContainer from '../containers/FeaturedListingsContainer';
import FeaturedAreasContainer from '../containers/FeaturedAreasContainer';
import ResearchBackedInventionsContainer from '../containers/ResearchBackedInventionsContainer';
import HighlightedBlogPostsContainer from '../containers/HighlightedBlogPostsContainer';
import ProofOfFundsBannerContainer from '../containers/ProofOfFundsBannerContainer';
import TitleAndEscrowBannerContainer from '../containers/TitleAndEscrowBannerContainer';
import TestimonialsContainers from '../containers/TestimonialsContainer';
import NewsletterBannerContainer from '../containers/NewsletterBannerContainer';
import AgentLeaderboardContainer from '../containers/AgentLeaderboardContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        transform: 'translateY(-64px)',
    },
    card: {
        minWidth: 275,
        marginBottom: 15
    },
    title: {
        fontSize: 14,
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '250px',
    },
    exampleImage: {
        width: '30%',
        margin: theme.spacing(4),
    },
    sectionSpacer: {
        marginBottom: 100,
    },
    sectionSpacerSmall: {
        marginBottom: 100,
    },
    sectionSpacerExtraSmall: {
        marginBottom: theme.spacing(4),
    }
  }),
);

const HomePage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HelmetProvider>
                <Helmet>
                    <meta property="og:image" content={HomePageOgImage} />
                    <meta property="og:description" content="24/7 Real Estate Closings Powered by Tech, we leverage AI and blockchain technology for smooth and secure transactions." />
                    <meta property="og:title" content="Propy | Real Estate Transaction Automated" />
                    <meta property="og:url" content={`https://propy.com${PROJECT_ROOT}`} />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta name="description" content="24/7 Real Estate Closings Powered by Tech, we leverage AI and blockchain technology for smooth and secure transactions."/>
                </Helmet>
            </HelmetProvider>
            <div className={classes.sectionSpacerSmall}>
                <HomePageHeroContainer
                    initialDelay={500}
                />
            </div>
            <Container maxWidth="lg">
                <div className={classes.sectionSpacer}>
                    <PropyAndCoinbasePrimeBannerContainer
                        initialDelay={750}
                    />
                </div>
                <div className={classes.sectionSpacer}>
                    <HighlightedVideoContainer
                        initialDelay={500}
                        title='Efficiencies through AI'
                        subtitle='Find out how Propy leverages AI to enhance your experience'
                        videoUrl='https://www.youtube-nocookie.com/embed/X6KtkRmVa0c?si=2kgdF3JgeM59pUjG&rel=0'
                    />
                </div>
                <div className={classes.sectionSpacer}>
                    <HighlightedVideoContainer
                        initialDelay={500}
                        title='Efficiencies through Smart Contracts'
                        subtitle='Find out how Propy leverages Smart Contracts to enhance your experience'
                        videoUrl='https://www.youtube-nocookie.com/embed/10x9XCK1QlM?si=Gf7zayFRWt6eauda&rel=0'
                    />
                </div>
                <div className={classes.sectionSpacerExtraSmall}>
                    <HighlightedFeaturesContainer
                        initialDelay={500}
                    />
                </div>
                <div className={classes.sectionSpacer}>
                    <HighlightedPartnersContainer
                        initialDelay={500}
                    />
                </div>
                <div className={classes.sectionSpacer}>
                    <AgentLeaderboardContainer
                        initialDelay={500}
                    />
                </div>
            </Container>
            <div className={classes.sectionSpacer}>
                <FeaturedListingsContainer />
            </div>
            <Container maxWidth="lg">
                <div className={classes.sectionSpacerSmall}>
                    <ProofOfFundsBannerContainer
                        initialDelay={250}
                    />
                </div>
            </Container>
            <div className={classes.sectionSpacer}>
                <ResearchBackedInventionsContainer
                    initialDelay={500}
                />
            </div>
            <Container maxWidth="lg">
                <div>
                    <div className={classes.sectionSpacer}>
                        <TestimonialsContainers />
                    </div>
                    <div className={classes.sectionSpacer}>
                        <TitleAndEscrowBannerContainer
                            initialDelay={1000}
                        />
                    </div>
                    {/* <div className={classes.sectionSpacerSmall}>
                        <HighlightedFeaturesContainer
                            initialDelay={750}
                        />
                    </div>
                    <div className={classes.sectionSpacer}>
                        <HighlightedPartnersContainer
                            initialDelay={500}
                        />
                    </div> */}
                </div>
            </Container>
            <Container maxWidth="lg">
                <div>
                    <div className={classes.sectionSpacer}>
                        <HighlightedBlogPostsContainer
                            initialDelay={500}
                        />
                    </div>
                </div>
            </Container>
            <Container maxWidth="lg">
                <div>
                    <div className={classes.sectionSpacer}>
                        <NewsletterBannerContainer
                            initialDelay={250}
                        />
                    </div>
                </div>
            </Container>
            <div className={classes.sectionSpacerExtraSmall}>
                <FeaturedAreasContainer/>
            </div>
        </div>
    )
};

export default HomePage;