import React, { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import {
  uuid
} from '../utils';

import {
  PROJECT_ROOT,
} from '../constants';

import { PropsFromRedux } from '../containers/BuyerOnboardingStepsContainer';

import BuyerOnboardingStep1ServiceRequirementContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep1ServiceRequirementContainer';
import BuyerOnboardingStep2LookingPreferencesContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep2LookingPreferencesContainer';
import BuyerOnboardingStep2PickLocationContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep2PickLocationContainer';
import BuyerOnboardingStep3ProfileInfoContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep3ProfileInfoContainer';
import BuyerOnboardingStep4HasAgentContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep4HasAgentContainer';
import BuyerOnboardingStep5ScheduleMeetingContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingStep5ScheduleMeetingContainer';
import BuyerOnboardingThankYouContainer from '../containers/BuyerOnboardingSteps/BuyerOnboardingThankYouContainer';

// import { PROJECT_ROOT } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    formContainer: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
    },
    navigationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
    navigationButton: {

    },
    leftCornerContainer: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
    progressContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      opacity: 0.75,
    },
    circleProgressContainer: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    leftCornerTypography: {
      opacity: 0.7
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    innerContentFirstAndFinal: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    mobilePaddingOverride: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    recaptchaContainer: {
      position: 'relative',
      width: '100%',
    },
  }),
);

const onboardingStepToStepIndex : {[key: string]: number} = {
  "": 0,
  "describe-desired-property": 1,
  "pick-location": 1,
  "profile-looking": 2,
  "profile-title-and-escrow": 2,
  "agent-query-looking": 3,
  "agent-query-title-and-escrow": 3,
  "schedule-onboarding-looking": 4,
  "schedule-onboarding-title-and-escrow": 4,
  "thank-you": 5,
  "schedule-onboarding-complete": 5,
}

const onboardingStepToPreviousStep : {[key: string]: string} = {
  "": "",
  "describe-desired-property": "",
  "pick-location": "",
  "profile-looking": "describe-desired-property",
  "profile-title-and-escrow": "pick-location",
  "agent-query-looking": "profile-looking",
  "agent-query-title-and-escrow": "profile-title-and-escrow",
  "schedule-onboarding-looking": "agent-query-looking",
  "schedule-onboarding-title-and-escrow": "agent-query-title-and-escrow",
  "thank-you": "",
  "schedule-onboarding-complete": ""
}

const stepToCompletionPercent : {[key: string]: number} = {
  "": 15,
  "describe-desired-property": 30,
  "pick-location": 30,
  "profile-looking": 60,
  "profile-title-and-escrow": 60,
  "agent-query-looking": 75,
  "agent-query-title-and-escrow": 75,
  "schedule-onboarding-looking": 90,
  "schedule-onboarding-title-and-escrow": 90,
  "thank-you": 100,
  "schedule-onboarding-complete": 100,
}

interface IBuyerOnboarding {
  onboardingStep?: string;
  recaptchaRef: any;
}

const BuyerOnboardingSteps = (props: IBuyerOnboarding & PropsFromRedux) => {
  let navigate = useNavigate();

  const [stepSubmitting, setStepSubmitting] = useState(false);

  const {
    onboardingStep = "",
    buyerFlow,
    setBuyerFlowSessionId,
    setBuyerFlowCurrentStep,
    recaptchaRef,
    isConsideredMobile,
  } = props;

  useEffect(() => {
    if(!buyerFlow.sessionId) {
      let newSessionId = uuid();
      setBuyerFlowSessionId(newSessionId);
    }
    if(onboardingStep !== "schedule-onboarding-complete") {
      if((buyerFlow.currentStep || buyerFlow.currentStep === '') && (buyerFlow.currentStep !== onboardingStep)) {
        navigate(`${PROJECT_ROOT}/get-started/buyer/${buyerFlow.currentStep}`, { replace: true })
      }
    }
  }, [buyerFlow, setBuyerFlowSessionId, onboardingStep, navigate])

  useEffect(() => {
    if((onboardingStepToStepIndex[buyerFlow.currentStep] === undefined) || (onboardingStepToStepIndex[onboardingStep] === undefined)) {
      setBuyerFlowCurrentStep("");
    } else if(onboardingStep === "schedule-onboarding-complete" && (buyerFlow.currentStep !== "schedule-onboarding-complete")) {
      setBuyerFlowCurrentStep("schedule-onboarding-complete");
    }
  }, [buyerFlow, onboardingStep, setBuyerFlowCurrentStep])

  const classes = useStyles();

  return (
    <div 
      className={[(onboardingStepToStepIndex[onboardingStep] === 5 || onboardingStepToStepIndex[onboardingStep] === 0) ? classes.innerContentFirstAndFinal : classes.innerContent, "box-shadow-default", isConsideredMobile ? classes.mobilePaddingOverride : ""].join(" ")}
      style={(["schedule-onboarding-looking", "schedule-onboarding-title-and-escrow", "profile-complete"].indexOf(onboardingStep) > -1)  ? {width: 800, maxWidth: '100%', paddingBottom: 0} : {width: 650, maxWidth: '100%'}}
    >
      <Box className={[classes.formContainer].join(" ")} sx={{ width: '100%' }}>
        <div className={classes.progressContainer}>
          <LinearProgress variant="determinate" color="primary" value={stepToCompletionPercent[onboardingStep]} />
        </div>
        <div className={classes.circleProgressContainer}>
          {stepSubmitting && <CircularProgress style={{height: 25, width: 25}} />}
        </div>
        <div className={classes.leftCornerContainer}>
          {(stepToCompletionPercent[onboardingStep] < 100) && (onboardingStepToStepIndex[onboardingStep] !== 0) &&
            <div className={"grey-chip-interactive"} onClick={() => setBuyerFlowCurrentStep(onboardingStepToPreviousStep[buyerFlow.currentStep])}>
              <Typography variant="subtitle2" component="div">
                Go back
              </Typography>
            </div>
          }
        </div>
        {onboardingStep === "" &&
          <BuyerOnboardingStep1ServiceRequirementContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "describe-desired-property" &&
          <BuyerOnboardingStep2LookingPreferencesContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "pick-location" &&
          <BuyerOnboardingStep2PickLocationContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "profile-looking" &&
          <BuyerOnboardingStep3ProfileInfoContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "profile-title-and-escrow" &&
          <BuyerOnboardingStep3ProfileInfoContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "agent-query-looking" &&
          <BuyerOnboardingStep4HasAgentContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "agent-query-title-and-escrow" &&
          <BuyerOnboardingStep4HasAgentContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "schedule-onboarding-looking" &&
          <BuyerOnboardingStep5ScheduleMeetingContainer />
        }
        {onboardingStep === "schedule-onboarding-title-and-escrow" &&
          <BuyerOnboardingStep5ScheduleMeetingContainer />
        }
        {onboardingStep === "schedule-onboarding-complete" &&
          <BuyerOnboardingThankYouContainer
            showBrowseNow={true}
          />
        }
        {onboardingStep === "thank-you" &&
          <BuyerOnboardingThankYouContainer
            showBrowseNow={true}
          />
        }
      </Box>
    </div>
  )
};

export default BuyerOnboardingSteps;