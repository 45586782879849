import { connect, ConnectedProps } from 'react-redux';

import {
    resetSellerFlow,
    setSellerFlowCurrentStep,
} from '../state/actions';

import SellerOnboardingReset from '../components/SellerOnboardingReset';

import { ISellerFlowData } from '../interfaces';
interface RootState {
    sellerFlow: ISellerFlowData;
    darkMode: boolean;
    isConsideredMobile: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
    sellerFlow: state.sellerFlow,
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
    resetSellerFlow,
    setSellerFlowCurrentStep,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingReset)