import React from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/SellerOnboardingSteps/SellerOnboardingStep3TitleAndEscrowEligibilityContainer';

import {
  PROPY_BLUE_DARK,
  PROPY_BLUE,
} from '../../constants';

import FloatingActionButton from '../FloatingActionButton';

import ArizonaIcon from '../../assets/img/arizona.webp';
import ColoradoIcon from '../../assets/img/colorado.webp';
import FloridaIcon from '../../assets/img/florida.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      fontSize: '26px',
    },
    formSubtitle: {
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      fontSize: '18px',
    },
    bodyText: {
      fontWeight: '400',
      marginTop: theme.spacing(3),
      fontSize: '18px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    card: {
      width: '100%',
      height: 170,
      display: 'flex',
      justifyContent: 'center'
    },
    cardSelected: {
      backgroundColor: PROPY_BLUE,
      color: 'white',
    },
    imageUnselected: {
      opacity: 0.3,
    },
    imageSelected: {
      filter: 'brightness(0) invert(1)',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      marginTop: theme.spacing(3),
    },
    mapIcon: {
      height: 70,
    },
    submitButtonContainer: {
      marginTop: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
  }),
);

const locationOptions = [
  {
    value: "Florida",
    label: "Florida",
    icon: FloridaIcon,
  },
  {
    value: "Colorado",
    label: "Colorado",
    icon: ColoradoIcon,
  },
  {
    value: "Arizona",
    label: "Arizona",
    icon: ArizonaIcon,
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
}

export default function SellerOnboardingStep3TitleAndEscrowEligibility(props: PropsFromRedux & IStepProps) {

  const {
    setSellerFlowCurrentStep,
    sellerFlow,
  } = props;

  const classes = useStyles();

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Title & Escrow Services
      </Typography>
      <Typography className={classes.formSubtitle} variant="h6" component="h6">
        Propy currently supports Title & Escrow in the following locations:
      </Typography>
      <Grid container spacing={3}>
        {locationOptions.map((item, index) => (
          <Grid key={`location-quick-selection-entry-map-${index}`} item xs={12} sm={6} md={4} lg={4}>
            <Card elevation={0} className={[classes.card, sellerFlow.desiredLocation === item.value ? classes.cardSelected : '', 'grey-border-card'].join(" ")}>
              <div className={classes.card}>
                <CardContent className={classes.cardContent}>
                  {<img className={[classes.mapIcon, sellerFlow.desiredLocation === item.value ? classes.imageSelected : classes.imageUnselected].join(" ")} src={item.icon} alt={`map icon ${item.label}`} />}
                  <Typography className={classes.cardTitle} gutterBottom={false} variant="h5" component="div">
                    {item.label}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography className={classes.bodyText} variant="h6" component="h6">
        If your property is located above, you may be eligible to list your home
      </Typography>
      <div className={classes.submitButtonContainer}>
        <FloatingActionButton
          className={classes.submitButton}
          onClick={() => setSellerFlowCurrentStep("listing-options")}
          text="Next"
        />
      </div>
    </animated.div>
  );
}