import { connect, ConnectedProps } from 'react-redux';

import {
  giveConsentToCookies,
} from '../state/actions';

import CookieConsentBanner from '../components/CookieConsentBanner';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
})

const mapDispatchToProps = {
  giveConsentToCookies,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CookieConsentBanner)