import { useEffect, useState } from "react";

interface IRecaptchaObject {
  token: string | null
  tokenSpawnTimeUnix: number | null
}

const useRecaptchaToken = (recaptchaRef: any) => {

  let recaptchaObject : IRecaptchaObject = {
    token: null,
    tokenSpawnTimeUnix: null,
  }

  // recaptcha expires after 120 seconds but we use 100 just to make sure we always have a fresh one stored in memory
  const autoUpdatePeriod = 100;

  const [recaptchaObjectState, setRecaptchaObjectState] = useState(recaptchaObject);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchIndex, setFetchIndex] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date().getTime());

  useEffect(() => {
    let isMounted = true;
    const refreshToken = async () => {
      if(recaptchaRef && recaptchaRef.current && recaptchaRef?.current?.executeAsync) {
        setIsLoading(true);
        let currentTimeUnix = Math.floor(new Date().getTime() / 1000);
        let token = await recaptchaRef?.current?.executeAsync();
        let newRecaptchaObject : IRecaptchaObject = {
          token: token,
          tokenSpawnTimeUnix: currentTimeUnix,
        }
        setIsLoading(false);
        if(isMounted) {
          setRecaptchaObjectState(newRecaptchaObject);
        }
      }
    }
    refreshToken();
    return () => {
      isMounted = false;
    };
  }, [recaptchaRef, fetchIndex]);

  useEffect(() => {

    let isMounted = true;

    const timetrackerIntervalId = setInterval(async () => {
      setCurrentTimestamp(new Date().getTime());
      if(recaptchaObjectState.tokenSpawnTimeUnix) {
        let secondsSinceLastUpdate = Number(((currentTimestamp - (recaptchaObjectState.tokenSpawnTimeUnix * 1000)) / 1000).toFixed(0));
        if ((secondsSinceLastUpdate > autoUpdatePeriod) && !isLoading && isMounted) {
          setFetchIndex(fetchIndex + 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timetrackerIntervalId)
      isMounted = false;
    };
  });

  const getToken = async () => {
    if(recaptchaObjectState.tokenSpawnTimeUnix) {
      let secondsSinceLastUpdate = Number(((currentTimestamp - (recaptchaObjectState.tokenSpawnTimeUnix * 1000)) / 1000).toFixed(0));
      if(secondsSinceLastUpdate > autoUpdatePeriod) {
        let token = await recaptchaRef?.current?.executeAsync();
        return token;
      } else {
        return recaptchaObjectState.token;
      }
    } else {
      let token = await recaptchaRef?.current?.executeAsync();
      return token;
    }
  }

  return getToken;
  
};

export default useRecaptchaToken;