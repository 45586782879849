import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

import { toast } from 'sonner'

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';
import { MuiTelInput } from 'mui-tel-input';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/BuyerOnboardingSteps/BuyerOnboardingStep4HasAgentContainer';

import { 
  PROPY_BLUE,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
  PURCHASE_TYPE,
} from '../../constants';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import FloatingActionButton from '../FloatingActionButton';

import {
  isPriorityLocation,
  isBuyerBudgetEligibleForMeeting,
} from "../../utils";

import {
  FlowService,
  UserService,
} from '../../services/api';

import {
  IBuyerFlowData
} from "../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    formSubtitle: {
      fontSize: '18px',
      marginBottom: theme.spacing(3),
    },
    optionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    optionSpacer: {
      marginLeft: theme.spacing(2),
    },
    optionButton: {
      fontSize: '20px',
      padding: theme.spacing(4),
      borderRadius: 40,
      width: '100%'
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    },
    submitButtonContainer: {
      marginTop: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
    agentFormContainer: {
      marginTop: theme.spacing(4),
    },
  }),
);

interface IHasAgentOption {
  value: IBuyerFlowData["hasAgent"];
  label: string;
}

const serviceRequirementOptions : IHasAgentOption[] = [
  {
    value: "has-agent",
    label: "Yes",
  },
  {
    value: "no-agent",
    label: "No",
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function BuyerOnboardingStep4HasAgent(props: PropsFromRedux & IStepProps) {

  const {
    setBuyerFlowHasAgent,
    setBuyerFlowCurrentStep,
    setBuyerFlowAgentInfo,
    setBuyerFlowAgentInfoEmailAddress,
    setBuyerFlowAgentInfoPhoneNumber,
    setBuyerFlowAgentInfoPhoneNumberCountry,
    setBuyerFlowAgentInfoFirstName,
    setBuyerFlowAgentInfoLastName,
    // setBuyerFlowAgentInfoMessage,
    buyerFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const [lastCheckedNumber, setLastCheckedNumber] = useState('');
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [hasTriedSubmit, setHasTriedSubmit] = useState(false);

  const ValidationSchema = yup.object().shape({
    agentEmailAddress: yup.string().email('Invalid email'),
    agentFirstName: yup.string()
      .max(50, 'Too Long!'),
    agentLastName: yup.string()
      .max(50, 'Too Long!'),
    // agentMessage: yup.string(),
    hiddenField: yup.string().test('hiddenField', 'hiddenField', function (value) { setHasTriedSubmit(true); return true }),
    agentPhoneNumber: yup.string()
      .test("validPhone", 'Invalid phone number', 
        function (value) {
          if(value) {
            let parsed = parsePhoneNumberFromString(value);
            if(parsed?.nationalNumber && parsed?.countryCallingCode) {
              let currentNumber = `${parsed?.number}`;
              let currentCountryCallingCode = `${parsed?.countryCallingCode}`;
              let currentNationalNumber = `${parsed?.nationalNumber}`;
              if(lastCheckedNumber !== parsed?.number) {
                setIsValidatingPhone(true);
                return new Promise(async (resolve) => {
                  if(currentNationalNumber && currentCountryCallingCode) {
                    setIsValidPhone(false);
                    await UserService.getPhoneInfo(currentNationalNumber, currentCountryCallingCode)
                      .then(response => {
                        if(response?.data?.message === "Success") {
                          setIsValidPhone(true);
                          if(parsed?.number) {
                            setLastCheckedNumber(currentNumber);
                          }
                          resolve(true);
                        } else {
                          if(parsed?.number) {
                            setLastCheckedNumber(currentNumber);
                          }
                          resolve(false);
                        }
                      })
                      .catch(e => {
                        if(parsed?.number) {
                          setLastCheckedNumber(currentNumber);
                        }
                        resolve(false);
                      });
                    setIsValidatingPhone(false);
                  }
                });
              } else {
                return isValidPhone;
              }
            } else {
              setIsValidatingPhone(false);
              return false;
            }
          } else {
            setIsValidPhone(false);
            setIsValidatingPhone(false);
            // return false; use this when it's required, else return true when not required
            return true;
          }
        }
      )
      .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, {message: "Invalid phone number"}),
  });

  useEffect(() => {
    if(!buyerFlow.agentPhoneNumber || buyerFlow.agentPhoneNumber.length === 0) {
      setIsValidPhone(false);
      setLastCheckedNumber('');
    }
  }, [buyerFlow.agentPhoneNumber])

  const classes = useStyles();

  const handleHasAgentClick = (option: IBuyerFlowData["hasAgent"]) => {
    setBuyerFlowHasAgent(option);
    if(option === 'no-agent') {
      nextStep(option);
    }
  }

  const nextStep = async (hasAgent: IBuyerFlowData["hasAgent"]) => {
    // // submit data to backend
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(buyerFlow.sessionId && recaptchaRef.current) {
      setStepSubmitting(true);
      const token = await getToken();
      await FlowService.patchSession(buyerFlow.sessionId, {
        Type: FLOW_TYPE_TO_LEAD_TYPE["buyer"],
        ...(buyerFlow.serviceRequirement && { Actions: [SERVICE_REQUIREMENT[buyerFlow.serviceRequirement]] }),
        ...(buyerFlow.desiredLocation && { Location: { placeDesc: buyerFlow.desiredLocation } }),
        ...(buyerFlow.purchaseType && { Purchase: PURCHASE_TYPE[buyerFlow.purchaseType] }),
        ...(buyerFlow.firstName && { FirstName: buyerFlow.firstName }),
        ...(buyerFlow.lastName && { LastName: buyerFlow.lastName }),
        ...(buyerFlow.emailAddress && { Email: buyerFlow.emailAddress }),
        ...(buyerFlow.phoneNumber && { PhoneNumber: buyerFlow.phoneNumber }),
        ...(buyerFlow.priceRange && { PriceRange: buyerFlow.priceRange }),
        ...(hasAgent && { HasAgent: hasAgent === "has-agent" ? true : false }),
        ...(buyerFlow.agentEmailAddress && { AgentEmail: buyerFlow.agentEmailAddress }),
        ...(buyerFlow.agentPhoneNumber && { AgentPhoneNumber: buyerFlow.agentPhoneNumber }),
        ...(buyerFlow.agentFirstName && { AgentFirstName: buyerFlow.agentFirstName }),
        ...(buyerFlow.agentLastName && { AgentLastName: buyerFlow.agentLastName }),
        // ...(buyerFlow.agentMessage && { MessageToAgent: buyerFlow.agentMessage }),
      }, token);
      setStepSubmitting(false);
      if(
        (buyerFlow.serviceRequirement === "looking") &&
        isPriorityLocation(buyerFlow.desiredLocation ? buyerFlow.desiredLocation : "") &&
        isBuyerBudgetEligibleForMeeting(buyerFlow.priceRange ? buyerFlow.priceRange : false) &&
        (hasAgent === "no-agent")
      ) {
        // setBuyerFlowCurrentStep("schedule-onboarding-looking");
        setBuyerFlowCurrentStep("thank-you");
      } else if(
        (buyerFlow.serviceRequirement === "title-and-escrow") && 
        isPriorityLocation(buyerFlow.desiredLocation ? buyerFlow.desiredLocation : "")
      ) {
        // setBuyerFlowCurrentStep("schedule-onboarding-title-and-escrow");
        setBuyerFlowCurrentStep("thank-you");
      } else {
        setBuyerFlowCurrentStep("thank-you");
      }
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Do you have an agent?
      </Typography>
      <div className={classes.optionContainer}>
        {serviceRequirementOptions.map((entry, index) => 
          <FloatingActionButton 
            key={`deals-closed-option-${index}`}
            onClick={() => handleHasAgentClick(entry.value)}
            className={
              [
                classes.optionButton,
                index > 0 ? classes.optionSpacer : "",
                (buyerFlow.hasAgent === entry.value) ? classes.optionButtonSelected : classes.optionButtonUnselected
              ].join(" ")
            }
            textColor={(buyerFlow.hasAgent === entry.value) ? 'white' : PROPY_BLUE}
            size="large"
            buttonColor={(buyerFlow.hasAgent !== entry.value) ? "default" : "primary"}
            text={entry.label}
          />
        )}
      </div>
      {
        buyerFlow.hasAgent === 'has-agent' && (
          <div className={classes.agentFormContainer}>
            <Typography className={classes.formSubtitle} variant="body1">
              Feel free to share your agent’s details so we can provide free access to Propy to your agent.
            </Typography>
            <Formik
              validateOnChange={hasTriedSubmit}
              validateOnBlur={hasTriedSubmit}
              initialValues={{
                agentEmailAddress: typeof buyerFlow.agentEmailAddress === 'string' ? buyerFlow.agentEmailAddress : '',
                agentPhoneNumber: typeof buyerFlow.agentPhoneNumber === 'string' ? buyerFlow.agentPhoneNumber : '',
                agentPhoneNumberCountry: typeof buyerFlow.agentPhoneNumberCountry === 'string' ? buyerFlow.agentPhoneNumberCountry : '',
                agentFirstName: typeof buyerFlow.agentFirstName === 'string' ? buyerFlow.agentFirstName : '',
                agentLastName: typeof buyerFlow.agentLastName === 'string' ? buyerFlow.agentLastName : '',
                // agentMessage: typeof buyerFlow.agentMessage === 'string' ? buyerFlow.agentMessage : '',
                hiddenField: "hidden",
              }}
              validationSchema={ValidationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  if(!recaptchaRef?.current) {
                    toast.error("Recaptcha expired, please refresh the page.");
                  }
                  if(buyerFlow.sessionId && recaptchaRef.current) {
                    setSubmitting(true);
                    setBuyerFlowAgentInfo(values);
                    let successMessage = 'Saved progress!';
                    setStepSubmitting(true);
                    const token = await getToken();
                    await FlowService.patchSession(buyerFlow.sessionId, {
                      Type: FLOW_TYPE_TO_LEAD_TYPE["buyer"],
                      ...(buyerFlow.serviceRequirement && { Actions: [SERVICE_REQUIREMENT[buyerFlow.serviceRequirement]] }),
                      ...(buyerFlow.desiredLocation && { Location: { placeDesc: buyerFlow.desiredLocation } }),
                      ...(buyerFlow.purchaseType && { Purchase: PURCHASE_TYPE[buyerFlow.purchaseType] }),
                      ...(buyerFlow.firstName && { FirstName: buyerFlow.firstName }),
                      ...(buyerFlow.lastName && { LastName: buyerFlow.lastName }),
                      ...(buyerFlow.emailAddress && { Email: buyerFlow.emailAddress }),
                      ...(buyerFlow.phoneNumber && { PhoneNumber: buyerFlow.phoneNumber }),
                      ...(buyerFlow.phoneNumberCountry && { PhoneCountryCode: buyerFlow.phoneNumberCountry }),
                      ...(buyerFlow.priceRange && { PriceRange: buyerFlow.priceRange }),
                      ...(buyerFlow.hasAgent && { HasAgent: buyerFlow.hasAgent === "has-agent" ? true : false }),
                      ...(values.agentEmailAddress && { AgentEmail: values.agentEmailAddress }),
                      ...(values.agentPhoneNumber && { AgentPhoneNumber: values.agentPhoneNumber }),
                      ...(values.agentPhoneNumberCountry && { AgentPhoneCountryCode: values.agentPhoneNumberCountry }),
                      ...(values.agentFirstName && { AgentFirstName: values.agentFirstName }),
                      ...(values.agentLastName && { AgentLastName: values.agentLastName }),
                      // ...(buyerFlow.agentMessage && { MessageToAgent: buyerFlow.agentMessage }),
                    }, token);
                    setStepSubmitting(false);
                    toast.success(successMessage);
                    setSubmitting(false);
                    // if submit success
                    if(
                      (buyerFlow.serviceRequirement === "looking") &&
                      isPriorityLocation(buyerFlow.desiredLocation ? buyerFlow.desiredLocation : "") &&
                      isBuyerBudgetEligibleForMeeting(buyerFlow.priceRange ? buyerFlow.priceRange : false) &&
                      (buyerFlow.hasAgent === "no-agent")
                    ) {
                      // setBuyerFlowCurrentStep("schedule-onboarding-looking");
                      setBuyerFlowCurrentStep("thank-you");
                    } else if(
                      (buyerFlow.serviceRequirement === "title-and-escrow") && 
                      isPriorityLocation(buyerFlow.desiredLocation ? buyerFlow.desiredLocation : "")
                    ) {
                      // setBuyerFlowCurrentStep("schedule-onboarding-title-and-escrow");
                      setBuyerFlowCurrentStep("thank-you");
                    } else {
                      setBuyerFlowCurrentStep("thank-you");
                    }
                  }
                } catch(e) {
                  console.log({e})
                  setSubmitting(false);
                  setStepSubmitting(false);
                  toast.error("Unable to save progress, please try again or contract support");
                }
              }}
            >
              {({ submitForm, isSubmitting, handleChange, setFieldValue, errors }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="agentFirstName"
                        type="text"
                        label="Agent first name (optional)"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setBuyerFlowAgentInfoFirstName(event.target.value)
                          handleChange(event)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="agentLastName"
                        type="text"
                        label="Agent last name (optional)"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setBuyerFlowAgentInfoLastName(event.target.value)
                          handleChange(event)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="agentEmailAddress"
                        type="text"
                        label="Agent email address (optional)"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setBuyerFlowAgentInfoEmailAddress(event.target.value)
                          handleChange(event)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        component={MuiTelInput}
                        defaultCountry="US"
                        focusOnSelectCountry
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            isValidatingPhone ? 
                              <InputAdornment position="end">
                                <CircularProgress style={{width: 25, height: 25}}/>
                              </InputAdornment>
                            : 
                              <InputAdornment position="end">
                                {isValidPhone ? <CheckCircleOutlineIcon style={{fontSize: 28, color: '#00d000'}} /> : <></>}
                              </InputAdornment>
                          ),
                        }}
                        name="agentPhoneNumber"
                        type="tel"
                        label="Agent phone number (optional)"
                        disabled={isSubmitting}
                        helperText={
                          errors["agentPhoneNumber"] ? 
                            errors["agentPhoneNumber"] 
                          : isValidatingPhone ? "Validating phone number..." : ""}
                        error={errors["agentPhoneNumber"] ? true : false}
                        value={buyerFlow.agentPhoneNumber ? buyerFlow.agentPhoneNumber : ''}
                        onPaste={(event: ClipboardEvent) => {
                          if(event.clipboardData) {
                            let paste = (event.clipboardData).getData("text").replace(/ /g, '');
                            if(paste && paste.indexOf("+") === 0) {
                              setBuyerFlowAgentInfoPhoneNumber(paste)
                              setFieldValue('agentPhoneNumber', paste)
                              let parsedData = parsePhoneNumberFromString(paste);
                              if(parsedData?.country) {
                                let country = parsedData.country.toLowerCase();
                                setBuyerFlowAgentInfoPhoneNumberCountry(country);
                                setFieldValue('agentPhoneNumberCountry', country)
                              }
                            }
                          }
                        }}
                        onChange={(value: string, info: any) => {
                          let useFieldValue = !info.numberValue ? value : info.numberValue;
                          setBuyerFlowAgentInfoPhoneNumber(useFieldValue)
                          setFieldValue('agentPhoneNumber', useFieldValue)
                          let parsedData = parsePhoneNumberFromString(useFieldValue);
                          if(parsedData?.country) {
                            let country = parsedData.country.toLowerCase();
                            setBuyerFlowAgentInfoPhoneNumberCountry(country);
                            setFieldValue('agentPhoneNumberCountry', country)
                          }
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        component={TextField}
                        fullWidth
                        name="agentMessage"
                        type="text"
                        label="Message to agent"
                        multiline={true}
                        rows={5}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setBuyerFlowAgentInfoMessage(event.target.value)
                          handleChange(event)
                        }}
                      />
                    </Grid> */}
                    <div style={{display: 'none'}}>
                      <Field
                        component={TextField}
                        name="hiddenField"
                        type="text"
                        label="hidden"
                      />
                      <Field
                        component={TextField}
                        name="agentPhoneNumberCountry"
                        type="text"
                        label="hidden"
                      />
                    </div>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      {isSubmitting && <LinearProgress />}
                    </Grid> */}
                    <div className={classes.submitButtonContainer}>
                      <FloatingActionButton
                        className={classes.submitButton}
                        disabled={isSubmitting}
                        onClick={submitForm}
                        text="Next"
                      />
                    </div>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        )
      }
    </animated.div>
  );
}