import { connect, ConnectedProps } from 'react-redux';

import {
  setSellerFlowCurrentStep,
  setSellerFlowServiceRequirements,
} from '../../state/actions';

import SellerOnboardingStep1ServiceRequirement from '../../components/SellerOnboardingSteps/SellerOnboardingStep1ServiceRequirement';

import { ISellerFlowData } from '../../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  sellerFlow: ISellerFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  sellerFlow: state.sellerFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setSellerFlowCurrentStep,
  setSellerFlowServiceRequirements,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingStep1ServiceRequirement)