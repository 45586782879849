import { connect, ConnectedProps } from 'react-redux';

import {
    setAgentFlowSessionId,
    setAgentFlowCurrentStep,
} from '../state/actions';

import AgentOnboardingStepsV2 from '../components/AgentOnboardingStepsV2';

import { IAgentFlowData } from '../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    agentFlow: IAgentFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
    agentFlow: state.agentFlow,
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
    setAgentFlowSessionId,
    setAgentFlowCurrentStep,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AgentOnboardingStepsV2)