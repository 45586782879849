import { connect, ConnectedProps } from 'react-redux';

import PersonaSelectionCard from '../components/PersonaSelectionCard';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
  signedIn: boolean,
}
  
const mapStateToProps = (state: RootState) => ({
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
  isConsideredMedium: state.isConsideredMedium,
  signedIn: state.signedIn,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PersonaSelectionCard)