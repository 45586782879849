import React from 'react';

import {
  animated,
  useSprings,
  useInView,
} from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';

import { PropsFromRedux } from '../containers/FeaturedListingsContainer';

import FeaturedAreaCard from './FeaturedAreaCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      borderRadius: 8,
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    }
  }),
);

const FeaturedAreas = (props: PropsFromRedux) => {
    const classes = useStyles();

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const [springs] = useSprings(
      3,
      (springIndex: number) => ({
        from: {
          opacity: 0,
          transform: 'translateY(-35px)',
        },
        to: {
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(-35px)',
        },
        delay: 200 + (200 * springIndex),
      }),
      [isInView]
    )

    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <animated.div style={springs[0]}>
                <FeaturedAreaCard
                  link="https://propy.com/map?latitude=34.0522342&longitude=-118.2436849&zoom=11&northEastLatitude=34.39628830061231&northEastLongitude=-117.804231775&southWestLatitude=33.706778135634345&southWestLongitude=-118.683138025&propertyStatuses=1&page=1&locationName=Los%20Angeles&locationCountry=United%20States&locationState=CA&countryCode=US&locationAddress=Los%20Angeles,%20CA,%20USA&locationType=locality"
                  heading="Los Angeles, CA"
                  buttonText="Los Angeles"
                  imageLink="https://vagabond-public-storage.s3.eu-west-2.amazonaws.com/639ba35d03d18e8bd5976328.jpg"
                />
              </animated.div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <animated.div style={springs[1]}>
                <FeaturedAreaCard
                  link="https://propy.com/map?latitude=30.267153&longitude=-97.7430608&zoom=11&northEastLatitude=30.62587907132472&northEastLongitude=-97.303607675&southWestLatitude=29.907111416276035&southWestLongitude=-98.182513925&propertyStatuses=1&page=1&locationName=Austin&locationCountry=United%20States&locationState=TX&countryCode=US&locationAddress=Austin,%20TX,%20USA&locationType=locality"
                  heading="Austin, TX"
                  buttonText="Austin"
                  imageLink="https://vagabond-public-storage.s3.eu-west-2.amazonaws.com/63c3672f62b230fce5772173.jpg"
                />
              </animated.div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <animated.div style={springs[2]}>
                <FeaturedAreaCard
                  link="https://propy.com/map?latitude=25.7616798&longitude=-80.1917902&zoom=13&northEastLatitude=25.85533033863381&northEastLongitude=-80.08192691875&southWestLatitude=25.66795533106153&southWestLongitude=-80.30165348125&propertyStatuses=1&page=1&locationName=Miami&locationCountry=United%20States&locationState=FL&countryCode=US&locationAddress=Miami,%20FL,%20USA&locationType=locality"
                  heading="Miami, FL"
                  buttonText="Miami"
                  imageLink="https://vagabond-public-storage.s3.eu-west-2.amazonaws.com/63211b134b679ead16f634fe.jpg"
                />
              </animated.div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
};

export default FeaturedAreas;