import { matchRoutes, useLocation } from "react-router-dom"

import {
  PROJECT_ROOT
} from '../constants'

const routes = [
  { path: `${PROJECT_ROOT}/` },
]

const useCurrentPath = () => {
  const location = useLocation()
  const match = matchRoutes(routes, location)

  if(match?.[0]?.route?.path) {
    return match?.[0]?.route?.path;
  } else {
    return ''
  }
}

export default useCurrentPath;