import { connect, ConnectedProps } from 'react-redux';

import SellerOnboardingStep5ScheduleMeeting from '../../components/SellerOnboardingSteps/SellerOnboardingStep5ScheduleMeeting';

import { ISellerFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    sellerFlow: ISellerFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    sellerFlow: state.sellerFlow,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingStep5ScheduleMeeting)