import React, { useState, useEffect, useRef } from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

import { PropsFromRedux } from '../containers/GenericHeroContainer';

const primaryHeroImageHeight = 350;
const primaryHeroImageTopOffset = 32;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    heroText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
    },
    heroTitle: {
      marginBottom: theme.spacing(1),
    },
    heroSubtitle: {
      
    },
    heroImageContainerShared: {
      backgroundColor: 'white',
    },
    heroImageContainer: {
      height: primaryHeroImageHeight,
      width: '100%',
      maxWidth: '600px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 8,
      position: 'absolute',
      zIndex: 1,
      left: '50%',
      transform: 'translateX(-50%)',
      top: `${primaryHeroImageTopOffset}px`
    },
    heroImage2Container: {
      height: 200,
      width: '300px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 8,
      position: 'absolute',
      zIndex: 1,
      left: '108%',
      // top: '30px'
      transform: 'translateX(-100%)translateY(-50%)',
      top: '207px'
    },
    heroImage3Container: {
      height: 200,
      width: '300px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: 8,
      position: 'absolute',
      zIndex: 1,
      left: '-5%',
      transform: 'translateY(-50%)',
      top: '207px'
    },
    heroImage: {
      
    },
    heroMediaZone: {
      position: 'relative',
    },
    callToAction: {
      marginTop: theme.spacing(2)
    },
  }),
);

interface IGenericHeroProps {
  title?: string
  subtitle?: string
  image1?: string
  image2?: string
  image3?: string
  callToActionText?: string
  callToActionFn?: () => void
  callToActionLink?: string
}

const GenericHero = (props: IGenericHeroProps & PropsFromRedux) => {
    const classes = useStyles();

    const [heroHeight, setHeroHeight] = useState<number>(555);

    const handleElementResized = () => {
      if(observedDiv.current && observedDiv?.current?.offsetHeight !== heroHeight) {
        let extraHeight = image1 ? primaryHeroImageHeight + primaryHeroImageTopOffset : 0;
        setHeroHeight(observedDiv.current.offsetHeight + extraHeight);
      }
    }

    const observedDiv = useRef<HTMLDivElement>(null);

    const resizeObserver = new ResizeObserver(handleElementResized);

    useEffect(() => {
      if(observedDiv.current) {
        resizeObserver.observe(observedDiv.current);
      }
      return function cleanup() {
        resizeObserver.disconnect();
      }
    });

    const {
      isConsideredMobile,
      title,
      subtitle,
      image1,
      image2,
      image3,
      callToActionText,
      callToActionFn,
      callToActionLink,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const heroTextSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
    })

    const heroImageContainerSpring = useSpring({
      from: {
        opacity: 0,
        top: '132px',
        backgroundImage: `url(${image1})`,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? `${primaryHeroImageTopOffset}px` : '132px',
        backgroundImage: `url(${image1})`,
      },
      delay: 200
    })

    const heroImage2ContainerSpring = useSpring({
      from: {
        opacity: 0,
        left: '115%',
        backgroundImage: `url(${image2})`,
      },
      to: {
        opacity: isInView ? 1 : 0,
        left: isInView ? '95%' : '115%',
        backgroundImage: `url(${image2})`,
      },
      delay: 600
    })

    const heroImage3ContainerSpring = useSpring({
      from: {
        opacity: 0,
        left: '-15%',
        backgroundImage: `url(${image3})`,
      },
      to: {
        opacity: isInView ? 1 : 0,
        left: isInView ? '5%' : '-15%',
        backgroundImage: `url(${image3})`,
      },
      delay: 400
    })

    return (
      <div className={classes.root} style={{minHeight: heroHeight}} ref={ref}>
        <animated.div className={classes.heroText} style={heroTextSpring} ref={observedDiv}>
          <Typography className={classes.heroTitle} style={{fontWeight: 'bold'}} variant="h2">
            {title}
          </Typography>
          <Typography className={classes.heroSubtitle} variant="h5">
            {subtitle}
          </Typography>
          {callToActionFn && callToActionText &&
            <Button size="large" variant="contained" className={classes.callToAction} onClick={() => callToActionFn()}>
              {callToActionText}
            </Button>
          }
          {callToActionLink && callToActionText &&
            <Link to={callToActionLink} style={{textDecoration: 'none'}}>
              <Button size="large" variant="contained" className={classes.callToAction}>
                {callToActionText}
              </Button>
            </Link>
          }
        </animated.div>
        <div className={classes.heroMediaZone}>
          {image1 && <animated.div className={[classes.heroImageContainerShared, classes.heroImageContainer, 'box-shadow-6'].join(' ')} style={heroImageContainerSpring} />}
          {image2 && !isConsideredMobile && <animated.div className={[classes.heroImageContainerShared, classes.heroImage2Container, 'box-shadow-6'].join(' ')} style={heroImage2ContainerSpring} />}
          {image3 && !isConsideredMobile && <animated.div className={[classes.heroImageContainerShared, classes.heroImage3Container, 'box-shadow-6'].join(' ')} style={heroImage3ContainerSpring} />}
        </div>
      </div>
    )
};

export default GenericHero;