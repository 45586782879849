import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import GetStartedIcon from '@mui/icons-material/Flag';

import { Link } from 'react-router-dom';

import LogoDarkMode from '../assets/svg/propy-dark-mode.svg'

import { PropsFromRedux } from '../containers/FooterContainer';

import { PROJECT_ROOT } from '../constants';
import ExternalLink from './ExternalLink';
import LinkWrapper from './LinkWrapper';
// import FloatingActionButton from './FloatingActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#000',
      width: '100%',
      display: 'flex',
    },
    rootGrid: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    footerLink: {
      color: 'white',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    lightText: {
      fontWeight: 300,
      color: '#CCCCCC'
    },
    offWhiteText: {
      color: '#CCCCCC',
    },
    spacer: {
      marginTop: theme.spacing(1),
    },
    linkRow: {
      display: 'flex',
    },
    linkContainer: {
      display: 'flex',
    },
    linkColumn: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    linkColumnMobile: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    linkIcon: {
      marginLeft: theme.spacing(0.5),
      color: "#37a6fb",
    },
    iconLinkContainer: {
      display: 'flex',
    },
    horizontalSpacer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    footerPropyLogoContainer: {
      height: '100%',
    },
    footerPropyLogoContainerMobile: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      height: '100%',
      justifyContent: 'center',
    },
    footerPropyLogoInner: {
      color: 'white',
    },
    footerPropyLogoInnerMobile: {
      color: 'white',
      textAlign: 'center',
    },
    footerLinkListContainer: {
      height: '100%',
      paddingTop: 0,
      display: 'flex',
      alignItems: 'center',
    },
    footerLinkListContainerMobile: {
      height: '100%',
      paddingTop: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    newsletterButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    }
  }),
);

interface IFooterProps {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
}

interface ILogoZone {
  darkMode: boolean;
  isConsideredMobile: boolean;
  isConsideredMedium: boolean;
  classes: any;
}

const LogoZone = (props: ILogoZone) => {

  const {
    classes,
    isConsideredMedium,
  } = props;

  return (
    <div className={["flex-center-vertical", isConsideredMedium ? classes.footerPropyLogoContainerMobile : classes.footerPropyLogoContainer].join(" ")}>        
      <div style={{color: 'white'}} className={isConsideredMedium ? classes.footerPropyLogoInnerMobile : classes.footerPropyLogoInner}>
        <Link to={`${PROJECT_ROOT}/`} style={{textDecoration: 'none', height: 34}}>
          <img height={'34px'} style={{marginRight: '10px', cursor: 'pointer'}} src={LogoDarkMode} alt="Propy logo" />
        </Link>
        <Typography className={[classes.lightText, classes.spacer].join(" ")} variant="subtitle1">
          © 2023 Propy, Inc. All rights reserved
        </Typography>
        <div className={[classes.spacer, classes.linkRow].join(" ")}>
          <ExternalLink hoverUnderline className={[classes.footerLink].join(" ")} href="https://propy.com/browse/terms-and-conditions/">
            <Typography className={classes.footerLink} variant="body1">
              Terms & Conditions
            </Typography>
          </ExternalLink>
          <Typography className={classes.horizontalSpacer} variant="body1">
            |
          </Typography>
          <ExternalLink hoverUnderline className={[classes.footerLink].join(" ")} href="https://propy.com/browse/privacy-policy/">
            <Typography className={classes.footerLink} variant="body1">
              Privacy
            </Typography>
          </ExternalLink>
          <Typography className={classes.horizontalSpacer} variant="body1">
            |
          </Typography>
          <ExternalLink hoverUnderline className={[classes.footerLink].join(" ")} href="https://propy.com/browse/cookies-policy/">
            <Typography className={classes.footerLink} variant="body1">
              Cookies
            </Typography>
          </ExternalLink>
        </div>
      </div>
    </div>
  )
}

const Footer = (props: IFooterProps & PropsFromRedux) => {
    const classes = useStyles();

    const {
      darkMode,
      isConsideredMobile,
      isConsideredMedium,
    } = props;

    return (
      <div className={classes.root}>
        <Container maxWidth="lg" style={{height: '100%'}}>
          <Grid className={classes.rootGrid} container spacing={2} style={{height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            {!isConsideredMedium &&
              <Grid item xs={12} sm={12} md={12} lg={4} style={{height: '100%', paddingTop: 0}}>
                <LogoZone classes={classes} isConsideredMobile={isConsideredMobile} isConsideredMedium={isConsideredMedium} darkMode={darkMode} />
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={8} className={classes.footerLinkListContainer}>
              <Grid container columns={20} spacing={2}>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ExternalLink style={{width: '100%'}} href={`https://propy.com/browse/newsletter`}>
                    <FloatingActionButton
                      text="SUBSCRIBE TO NEWSLETTER"
                      className={classes.newsletterButton}
                    />
                  </ExternalLink>
                </Grid> */}
                <Grid item xs={20} sm={20} md={4} lg={4} className={isConsideredMedium ? classes.linkColumnMobile : classes.linkColumn}>
                  <Typography className={classes.offWhiteText} variant="body1">
                    ONBOARDING
                  </Typography>
                  <LinkWrapper hoverUnderline link={`/get-started`} className={[classes.footerLink, classes.iconLinkContainer, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Get Started
                    </Typography>
                    <GetStartedIcon className={classes.linkIcon}/>
                  </LinkWrapper>
                  <LinkWrapper hoverUnderline link={`/get-started/agent`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      I'm an Agent
                    </Typography>
                  </LinkWrapper>
                  <LinkWrapper hoverUnderline link={`/get-started/buyer`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      I'm a Buyer
                    </Typography>
                  </LinkWrapper>
                  <LinkWrapper hoverUnderline link={`/get-started/seller`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      I'm a Seller
                    </Typography>
                  </LinkWrapper>
                </Grid>
                <Grid item xs={20} sm={20} md={4} lg={4} className={isConsideredMedium ? classes.linkColumnMobile : classes.linkColumn}>
                  <Typography className={classes.offWhiteText} variant="body1">
                    OFFERINGS
                  </Typography>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/subscriptions/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Pricing
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/crypto-for-real-estate/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Courses
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/resources/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Resources
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/about/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      About Propy
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://coinmarketcap.com/currencies/propy/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Propy Token
                    </Typography>
                  </ExternalLink>
                </Grid>
                <Grid item xs={20} sm={20} md={4} lg={4} className={isConsideredMedium ? classes.linkColumnMobile : classes.linkColumn}>
                  <Typography className={classes.offWhiteText} variant="body1">
                    EDUCATION
                  </Typography>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/knowledge-base/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Guides
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/webinars/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Webinars
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/case-studies/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Case Studies
                    </Typography>
                  </ExternalLink>
                </Grid>
                <Grid item xs={20} sm={20} md={4} lg={4} className={isConsideredMedium ? classes.linkColumnMobile : classes.linkColumn}>
                  <Typography className={classes.offWhiteText} variant="body1">
                    PRESS
                  </Typography>
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/blog/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Blog
                    </Typography>
                  </ExternalLink>
                  {/* <ExternalLink href={`${PROJECT_ROOT}/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Events
                    </Typography>
                  </ExternalLink> */}
                  <ExternalLink hoverUnderline href={`https://propy.com/browse/careers/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Careers
                    </Typography>
                  </ExternalLink>
                </Grid>
                <Grid item xs={20} sm={20} md={4} lg={4} className={isConsideredMedium ? classes.linkColumnMobile : classes.linkColumn}>
                  <Typography className={classes.offWhiteText} variant="body1">
                    SOCIAL
                  </Typography>
                  <ExternalLink hoverUnderline href={`https://www.facebook.com/propyinc`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Facebook
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://www.instagram.com/propy.inc/`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Instagram
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://twitter.com/PropyInc`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Twitter
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://discord.gg/7pjUugx97A`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Discord
                    </Typography>
                  </ExternalLink>
                  <ExternalLink hoverUnderline href={`https://t.me/propy`} className={[classes.footerLink, classes.spacer].join(" ")}>
                    <Typography className={classes.footerLink} variant="body1">
                      Telegram
                    </Typography>
                  </ExternalLink>
                </Grid>
              </Grid>
            </Grid>
            {isConsideredMedium &&
              <Grid item xs={12} sm={12} md={12} lg={6} style={{height: '100%', paddingTop: 0}}>
                <LogoZone classes={classes} isConsideredMobile={isConsideredMobile} isConsideredMedium={isConsideredMedium} darkMode={darkMode} />
              </Grid>
            }
          </Grid>
        </Container>
      </div>
    )
};

export default Footer;