import React from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/BuyerOnboardingSteps/BuyerOnboardingStep2PickLocationContainer';

import {
  PROPY_BLUE_DARK,
  PROPY_BLUE,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
  PURCHASE_TYPE,
} from '../../constants';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import { FlowService } from '../../services/api';

import ArizonaIcon from '../../assets/img/arizona.webp';
import ColoradoIcon from '../../assets/img/colorado.webp';
import FloridaIcon from '../../assets/img/florida.webp';
import GlobeIcon from '../../assets/img/globe.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    quickSelectionZone: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      color: PROPY_BLUE_DARK,
      fontWeight: 'bold',
    },
    card: {
      width: '100%',
      height: 200,
      display: 'flex',
      justifyContent: 'center'
    },
    cardSelected: {
      backgroundColor: PROPY_BLUE,
      color: 'white',
    },
    imageUnselected: {
      opacity: 0.5,
    },
    imageSelected: {
      filter: 'brightness(0) invert(1)',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      marginTop: theme.spacing(3),
    },
    mapIcon: {
      height: 70,
    }
  }),
);

const locationOptions = [
  {
    value: "Florida",
    label: "Florida",
    icon: FloridaIcon,
  },
  {
    value: "Colorado",
    label: "Colorado",
    icon: ColoradoIcon,
  },
  {
    value: "Arizona",
    label: "Arizona",
    icon: ArizonaIcon,
  },
  {
    value: "other-states-non-us",
    label: "Other or Non-US",
    icon: GlobeIcon,
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function BuyerOnboardingStep2PickLocation(props: PropsFromRedux & IStepProps) {

  const {
    setBuyerFlowDesiredLocation,
    setBuyerFlowCurrentStep,
    buyerFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const classes = useStyles();

  const handleLocationChange = async (location: string) => {
    setBuyerFlowDesiredLocation(location);
    // submit data to backend
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(buyerFlow.sessionId && recaptchaRef.current) {
      setStepSubmitting(true);
      try {
        const token = await getToken();
        await FlowService.patchSession(buyerFlow.sessionId, {
          Type: FLOW_TYPE_TO_LEAD_TYPE["buyer"],
          ...(buyerFlow.serviceRequirement && { Actions: [SERVICE_REQUIREMENT[buyerFlow.serviceRequirement]] }),
          ...(buyerFlow.desiredLocation && { Location: { placeDesc: location } }),
          ...(buyerFlow.purchaseType && { Purchase: PURCHASE_TYPE[buyerFlow.purchaseType] }),
          ...(buyerFlow.firstName && { FirstName: buyerFlow.firstName }),
          ...(buyerFlow.lastName && { LastName: buyerFlow.lastName }),
          ...(buyerFlow.emailAddress && { Email: buyerFlow.emailAddress }),
          ...(buyerFlow.phoneNumber && { PhoneNumber: buyerFlow.phoneNumber }),
          ...(buyerFlow.phoneNumberCountry && { PhoneCountryCode: buyerFlow.phoneNumberCountry }),
          ...(buyerFlow.priceRange && { PriceRange: buyerFlow.priceRange }),
          ...(buyerFlow.hasAgent && { HasAgent: buyerFlow.hasAgent === "has-agent" ? true : false }),
          ...(buyerFlow.agentEmailAddress && { AgentEmail: buyerFlow.agentEmailAddress }),
          ...(buyerFlow.agentPhoneNumber && { AgentPhoneNumber: buyerFlow.agentPhoneNumber }),
          ...(buyerFlow.agentPhoneNumberCountry && { AgentPhoneCountryCode: buyerFlow.agentPhoneNumberCountry }),
          ...(buyerFlow.agentFirstName && { AgentFirstName: buyerFlow.agentFirstName }),
          ...(buyerFlow.agentLastName && { AgentLastName: buyerFlow.agentLastName }),
          ...(buyerFlow.agentMessage && { MessageToAgent: buyerFlow.agentMessage }),
        }, token);
        toast.success('Saved progress!');
      } catch (e) {
        console.log({e})
        setStepSubmitting(false);
        toast.error("Unable to save progress, please try again or contract support");
      }
      setStepSubmitting(false);
    }
    setBuyerFlowCurrentStep(buyerFlow.serviceRequirement === "looking" ? "profile-looking" : "profile-title-and-escrow");
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Where are you located?
      </Typography>
      <Grid container spacing={3}>
        {locationOptions.map((item, index) => (
          <Grid key={`location-quick-selection-entry-map-${index}`} item xs={12} sm={6} md={6} lg={6}>
            <Card elevation={3} className={[classes.card, buyerFlow.desiredLocation === item.value ? classes.cardSelected : ''].join(" ")}>
              <CardActionArea onClick={() => handleLocationChange(item.value)} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  {<img className={[classes.mapIcon, buyerFlow.desiredLocation === item.value ? classes.imageSelected : classes.imageUnselected].join(" ")} src={item.icon} alt={`map icon ${item.label}`} />}
                  <Typography className={classes.cardTitle} gutterBottom={false} variant="h5" component="div">
                    {item.label}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </animated.div>
  );
}