import { connect, ConnectedProps } from 'react-redux';

import GetStartedSurveyPage from '../pages/GetStartedSurveyPage';

interface RootState {
  consentToCookies: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
  consentToCookies: state.consentToCookies,
})
  
const connector = connect(mapStateToProps, {})
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(GetStartedSurveyPage)