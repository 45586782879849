import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import { AppConfig } from '../constants';

let isGAInitialized = false;

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isGAInitialized) {
      ReactGA.initialize(AppConfig.GOOGLE_ANALYTICS_ID);
      isGAInitialized = true;
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      document.documentElement.scrollTo(0, 0);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, [location, initialized]);

  const sendCustomEvent = (category: string, action: string, url: string) => {
    if (initialized) {
      ReactGA.event({
        category,
        action,
      }, {
        url
      });
    }
  } 

  return {
    sendCustomEvent
  }
};

export default usePageTracking;