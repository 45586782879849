import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';

import SimpleCard from './SimpleCard';

import WEF from '../assets/img/WEF.webp';

import { PROPY_BLUE } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vouchCard: {
      padding: theme.spacing(4),
      borderLeft: `10px solid ${PROPY_BLUE}`
    },
    vouchQuoteContainer: {
      marginTop: theme.spacing(2),
    },
    vouchQuote: {
      fontWeight: 300,
      lineHeight: 1.3,
      marginBottom: theme.spacing(2),
    },
    vouchOrgContainer: {
      height: '50px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    vouchOrgLogo: {
      height: '100%',
      marginRight: theme.spacing(2),
    },
    vouchOrgTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  }),
);

interface ISingleVouchCard {
  initialDelay?: number
}

const SingleVouchCard = (props: ISingleVouchCard) => {
    const classes = useStyles();

    const {
      initialDelay = 0,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const cardSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay + 500
    })

    return (
      <animated.div style={cardSpring} ref={ref}>
        <SimpleCard
          className={['box-shadow-default', classes.vouchCard].join(' ')}
        >
          <Typography style={{fontWeight: 'bold'}} variant="h5">
            Propy Awarded as Technology Pioneer by World Economic Forum
          </Typography>
          <div className={classes.vouchQuoteContainer}>
            <Typography className={classes.vouchQuote} variant="h6">
              <i>"Propy and its fellow pioneers are developing technologies that can help society solve some of its most pressing issues."</i>
            </Typography>
          </div>
          <div className={[classes.vouchOrgContainer, "flex-center-all"].join(" ")}>
            <img className={classes.vouchOrgLogo} src={WEF} alt="World Economic Forum Logo" />
            <div className={classes.vouchOrgTextContainer}>
              <Typography style={{lineHeight: 1.3, fontWeight: 'bold'}} variant="body1">
                Susan Nesbitt
              </Typography>
              <Typography style={{lineHeight: 1.3}} variant="body1">
                Head of the Global Innovators Community, WEF
              </Typography>
            </div>
          </div>
        </SimpleCard>
      </animated.div>
    )

}

export default SingleVouchCard;