import { IAgentFlowData } from '../../interfaces';

interface IAgentFlowDataAction {
  type: string;
}

const defaultState : IAgentFlowData = {
  currentStep: "",
  profileAvailability: false,
  sessionId: false,
  location: false,
  dealsClosedBracket: false,
  firstName: false,
  lastName: false,
  emailAddress: false,
  phoneNumber: false,
  phoneNumberCountry: false,
  trialOrSkip: false,
  version: false,
}

const agentFlow = (
  state = defaultState,
  action: IAgentFlowDataAction & IAgentFlowData
) => {
  switch (action.type) {
    case 'SET_AGENT_FLOW_VERSION': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.version = action.version;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_SESSION_ID': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.sessionId = action.sessionId;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_TRIAL_OR_SKIP': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.trialOrSkip = action.trialOrSkip;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_CURRENT_STEP': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.currentStep = action.currentStep;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_AGENT_LOCATION': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.location = action.location;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_AGENT_DEALS_CLOSED': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.dealsClosedBracket = action.dealsClosedBracket;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_AGENT_PROFILE_INFO': {
      let {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        phoneNumberCountry,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      stateCopy.lastName = lastName;
      stateCopy.emailAddress = emailAddress;
      stateCopy.phoneNumber = phoneNumber;
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_PROFILE_INFO_FIRST_NAME': {
      let {
        firstName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_PROFILE_INFO_LAST_NAME': {
      let {
        lastName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.lastName = lastName;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_PROFILE_INFO_EMAIL_ADDRESS': {
      let {
        emailAddress,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.emailAddress = emailAddress;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_PROFILE_INFO_PHONE_NUMBER': {
      let {
        phoneNumber,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumber = phoneNumber;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY': {
      let {
        phoneNumberCountry,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_AGENT_FLOW_AGENT_PROFILE_AVAILABILITY': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.profileAvailability = action.profileAvailability;
      return stateCopy;
    }
    case 'RESET_AGENT_FLOW': {
      let stateCopy : IAgentFlowData = JSON.parse(JSON.stringify(defaultState));
      return stateCopy;
    }
    default:
      return state
  }
}

export default agentFlow;