import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import FloatingActionButton from './FloatingActionButton';

import ExternalLink from './ExternalLink';

import HoverShiftContainer from '../containers/HoverShiftContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      width: '100%',
    },
    cardTitleClamp: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',  
      overflow: 'hidden',
      fontWeight: 'bold',
      textShadow: '2px 2px 8px #000000',
    },
    overlay: {
      top: 0,
      position: 'absolute',
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#00000021',
      width: '100%',
      height: '100%',
    },
    cardContentOverlay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    }
  }),
);

interface IFeaturedAreaCardProps {
  link: string
  heading: string
  imageLink: string
  buttonText: string
}

const FeaturedAreaCard = (props: IFeaturedAreaCardProps) => {
    const classes = useStyles();

    const {
      link,
      imageLink,
      heading,
      buttonText,
    } = props;

    return (
      <div>
        <HoverShiftContainer>
          <ExternalLink className={classes.root} href={link}>
            <Card elevation={0} className={[classes.card, "box-shadow-default"].join(" ")}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="272"
                  image={imageLink}
                  alt={`${heading} featured area media`}
                />
                <div className={classes.overlay}>
                  <CardContent className={classes.cardContentOverlay}>
                    <Typography className={classes.cardTitleClamp} gutterBottom variant="h5" component="div">
                      {heading}
                    </Typography>
                    <div>
                      <FloatingActionButton
                        text={`${buttonText} homes for sale`}
                        buttonColor="default"
                        textColor="black"
                        component="div"
                      />
                    </div>
                  </CardContent>
                </div>
              </CardActionArea>
            </Card>
          </ExternalLink>
        </HoverShiftContainer>
      </div>
    )
};

export default FeaturedAreaCard;