import { connect, ConnectedProps } from 'react-redux';

import {
    setBuyerFlowSessionId,
    setBuyerFlowCurrentStep,
    setBuyerFlowServiceRequirement,
} from '../state/actions';

import BuyerOnboardingSteps from '../components/BuyerOnboardingSteps';

import { IBuyerFlowData } from '../interfaces';
interface RootState {
    buyerFlow: IBuyerFlowData;
    darkMode: boolean;
    isConsideredMobile: boolean;
}
  
const mapStateToProps = (state: RootState) => ({
    buyerFlow: state.buyerFlow,
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
    setBuyerFlowSessionId,
    setBuyerFlowCurrentStep,
    setBuyerFlowServiceRequirement,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(BuyerOnboardingSteps)