import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Paper from '@mui/material/Paper';
import LockIcon from '@mui/icons-material/Lock';

// import PropyPartnerAcme from '../assets/img/propy-partner-acme.png';
// import PropyPartnerCompass from '../assets/img/propy-partner-compass.png';
// import PropyPartnerExp from '../assets/img/propy-partner-exp.png';
// import PropyPartnerKellerWilliams from '../assets/img/propy-partner-keller-williams.png';
// import PropyPartnerRA from '../assets/img/propy-partner-ra.png';
// import PropyPartnerRedfin from '../assets/img/propy-partner-redfin.png';
// import PropyPartnerRemax from '../assets/img/propy-partner-remax.png';

import { PropsFromRedux } from '../containers/HighlightedPartnersContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    taglineContainer: {
      backgroundColor: '#eee',
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      maxWidth: 640,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
    },
    taglineContainerMobile: {
      backgroundColor: '#eee',
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      maxWidth: 640,
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    taglineIcon: {
      marginLeft: theme.spacing(1),
      opacity: 0.5,
    },
    partnerImage: {
      margin: theme.spacing(2),
    }
  }),
);

interface IHighlightedFeatures {
  initialDelay?: number
}

const HighlightedPartners = (props: IHighlightedFeatures & PropsFromRedux) => {
    const classes = useStyles();

    const {
      isConsideredMobile,
      initialDelay = 0,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const containerSpring = useSpring({
      from: {
        opacity: 0,
        transform: 'translateY(-25px)',
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? 'translateY(0px)' : 'translateY(-25px)',
      },
      delay: initialDelay
    })

    return (
      <animated.div style={containerSpring} ref={ref}>
        {/* <div className={classes.root} ref={ref}>
          <img className={classes.partnerImage} style={{height: '34px'}} src={PropyPartnerKellerWilliams} alt="Propy Partner Keller Williams" />
          <img className={classes.partnerImage} style={{height: '104px'}} src={PropyPartnerAcme} alt="Propy Partner ACME" />
          <img className={classes.partnerImage} style={{height: '65px'}} src={PropyPartnerRA} alt="Propy Partner RA" />
          <img className={classes.partnerImage} style={{height: '29px'}} src={PropyPartnerCompass} alt="Propy Partner Compass" />
          <img className={classes.partnerImage} style={{height: '33px'}} src={PropyPartnerRedfin} alt="Propy Partner Redfin" />
          <img className={classes.partnerImage} style={{height: '23px'}} src={PropyPartnerRemax} alt="Propy Partner Remax" />
          <img className={classes.partnerImage} style={{height: '65px'}} src={PropyPartnerExp} alt="Propy Partner EXP" />
        </div> */}
        <div>
          <Paper style={{borderRadius: 30}} elevation={0} className={isConsideredMobile ? classes.taglineContainerMobile : classes.taglineContainer}>
            {isConsideredMobile && <LockIcon className={classes.taglineIcon} style={{marginBottom: 8}} />}
            Over $4 billion in transactions processed with Propy partners across the USA
            {/* {!isConsideredMobile && <LockIcon className={classes.taglineIcon} />} */}
          </Paper>
        </div>
      </animated.div>
    )
};

export default HighlightedPartners;