import React, { useEffect } from 'react';

// import {
//   randomValueInRange,
// } from '../utils';

import { PropsFromRedux } from '../containers/ABGateContainer';

import AgentOnboardingStepsContainerV1 from '../containers/AgentOnboardingStepsContainer';
import AgentOnboardingStepsContainerV2 from '../containers/AgentOnboardingStepsContainerV2';

interface IABGate {
  type: 'agent';
  onboardingStep?: string;
  recaptchaRef: any;
}

const ABGate = (props: IABGate & PropsFromRedux) => {
  
  const {
    type,
    setAgentFlowVersion,
    agentFlow,
    onboardingStep,
    recaptchaRef,
  } = props;

  useEffect(() => {
    // check that a random version has been assigned to the relevant type, else randomly assign a version
    if(type === 'agent') {
      // Simulation logic
      // let testRandomVersions = [];
      // let sampleSize = 100;
      // for(let i = 0; i < sampleSize; i++) {
      //   let testRandomVersion = randomValueInRange(1, 2);
      //   testRandomVersions.push(testRandomVersion);
      // }
      // let numberFlowOne = testRandomVersions.filter((entry) => entry === 1).length;
      // let numberFlowTwo = testRandomVersions.filter((entry) => entry === 2).length;
      // let percentFlowOne = (numberFlowOne * 100) / sampleSize;
      // let percentFlowTwo = (numberFlowTwo * 100) / sampleSize;
      // console.log({sampleSize, testRandomVersions, numberFlowOne, numberFlowTwo, percentFlowOne, percentFlowTwo});
      if(!agentFlow?.version) {
        // let randomVersion = randomValueInRange(1, 2);
        let randomVersion = 2; // we default to flow 2 going forward
        setAgentFlowVersion(randomVersion);
      }
    }
  }, [type, agentFlow, setAgentFlowVersion])

  return (
    <>
      {type === 'agent' &&
        <>
          {agentFlow?.version === 1 &&
            <AgentOnboardingStepsContainerV1
              onboardingStep={onboardingStep}
              recaptchaRef={recaptchaRef}
            />
          }
          {agentFlow?.version === 2 &&
            <AgentOnboardingStepsContainerV2
              onboardingStep={onboardingStep}
              recaptchaRef={recaptchaRef}
            />
          }
        </>
      }
    </>
  )
}

export default ABGate;