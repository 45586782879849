import React from 'react';

import { Link } from "react-router-dom";

import ExternalLink from './ExternalLink';

import { PROJECT_ROOT } from '../constants';

interface ILinkWrapper {
  link?: string | false,
  external?: boolean,
  className?: string,
  hoverUnderline?: boolean,
  children: React.ReactNode,
  actionNameGA?: string,
  onClick?: () => void,
}

// we use this component to dynamically handle internal links and external links

const LinkWrapper = (props: ILinkWrapper) => {
  const {
    link,
    external = false,
    className,
    children,
    hoverUnderline = false,
    actionNameGA,
    onClick,
  } = props;

  if(external && link) {
    return (
      <ExternalLink actionNameGA={actionNameGA} hoverUnderline={hoverUnderline} className={className} href={link}>
        {children}
      </ExternalLink>
    )
  }
  if(!external && link) {
    return (
      <Link onClick={() => onClick && onClick()} className={[className, 'no-decorate', 'inherit-color', hoverUnderline ? "hover-underline" : ''].join(" ")} to={`${PROJECT_ROOT}${link}`}>
        {children}
      </Link>
    )
  }
  return (
    <>
      {children}
    </>
  );
}

export default LinkWrapper;