import { connect, ConnectedProps } from 'react-redux';

import {
    resetBuyerFlow,
} from '../../state/actions';

import BuyerOnboardingThankYou from '../../components/BuyerOnboardingSteps/BuyerOnboardingThankYou';

import { IBuyerFlowData } from '../../interfaces';

interface RootState {
    darkMode: boolean;
    isConsideredMobile: boolean;
    buyerFlow: IBuyerFlowData;
}
  
const mapStateToProps = (state: RootState) => ({
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    buyerFlow: state.buyerFlow,
})

const mapDispatchToProps = {
    resetBuyerFlow,
}
    
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(BuyerOnboardingThankYou)