import numeral from "numeral";
// import BigNumber from 'bignumber.js';
import { ChainId, shortenAddress } from '@usedapp/core'

import {
  IListingValueType,
	IBuyerFlowData,
	IQueryParamCollection,
} from '../interfaces';

export const centerShortenLongString = (string: string, maxLength: number) => {
	if(typeof string === 'string') {
		if(string.length > maxLength) {
			let charCountForRemoval = string.length - maxLength;
			let stringHalfwayPoint = Math.floor(maxLength/2);
			string = string.slice(0, stringHalfwayPoint) + "..." + string.slice(stringHalfwayPoint + charCountForRemoval, string.length);
			return string;
		}else{
			return string;
		}
	}else{
		return '';
	}
}

export const makeActionNameGAFriendly = (string: string): string => {
  // Convert to lowercase and replace spaces with underscores
  let friendlyString = string.toLowerCase().replace(/\s+/g, '_');

  // Define the allowed characters (a-z, 0-9, and underscore)
  const allowedChars = 'abcdefghijklmnopqrstuvwxyz0123456789_';

  // Filter out any characters that aren't in the allowed set
  friendlyString = friendlyString
    .split('')
    .filter(char => allowedChars.includes(char))
    .join('');

  return friendlyString;
};

const getDynamicFormat = (currentFormat = '0,0.00', number: number | string) => {
	let requestedDecimals = 0;
	let preDecimalFormat;
	let postDecimalFormat;
	if(currentFormat.split(".").length > 0) {
			requestedDecimals = currentFormat.split(".")[1].length;
			postDecimalFormat = currentFormat.split(".")[1];
			preDecimalFormat = currentFormat.split(".")[0];
	}
	let currentFormattedNumber = numeral(number).format(currentFormat).toString();
	let currentFormattedDecimals = '';
	if(currentFormattedNumber.split('.') && currentFormattedNumber.split('.')[1]) {
			currentFormattedDecimals = currentFormattedNumber.split('.')[1];
	}
	let currentUnformattedDecimals = '';
	if(number.toString().split(".").length > 0 && number.toString().split(".")[1]) {
			currentUnformattedDecimals = number.toString().split(".")[1];
	}
	let dynamicFormat = currentFormat;
	if((currentFormattedDecimals.replace(/[^1-9]/g,"").length < requestedDecimals) && (currentUnformattedDecimals.replace(/[^1-9]/g,"").length >= requestedDecimals)) {
			let indexOfSignificantFigureAchievement = 0;
			let significantFiguresEncountered = 0;
			let numberString = number.toString();
			let numberStringPostDecimal = "";
			if(numberString.split(".").length > 0) {
					numberStringPostDecimal = numberString.split(".")[1]
			}
			for(let i = 0; i < numberStringPostDecimal.length; i++) {
					// @ts-ignore
					if((numberStringPostDecimal[i] * 1) > 0) {
							significantFiguresEncountered++;
							if(significantFiguresEncountered === requestedDecimals) {
									indexOfSignificantFigureAchievement = i + 1;
							}
					}
			}
			if(indexOfSignificantFigureAchievement > requestedDecimals) {
					let requestedDecimalsToSignificantFiguresDelta = indexOfSignificantFigureAchievement - requestedDecimals;
					dynamicFormat = preDecimalFormat + ".";
					if(postDecimalFormat) {
							dynamicFormat = preDecimalFormat + "." + postDecimalFormat;
					}
					for(let i = 0; i < requestedDecimalsToSignificantFiguresDelta; i++) {
							dynamicFormat = dynamicFormat + "0";
					}
			}
	}
	return dynamicFormat;
}

export const priceFormat = (number: number | string, decimals = 2, currency = "$", prefix = true) => {
	let decimalString = "";
	for(let i = 0; i < decimals; i++){
			decimalString += "0";
	}
	if (currency.length > 1) {
			prefix = false;
	}
	let format = '0,0.' + decimalString;
	if(Number(number) < 10) {
			format = getDynamicFormat(format, number);
	}
	let result = numeral(number).format(format);
	if(result === 'NaN') {
		result = '0.00';
	}
	if (prefix) {
			return `${currency}${'\u00A0'}`+ result;
	} else {
			return result + `${'\u00A0'}${currency}`
	}
}

// Credit for percToColour: https://gist.github.com/mlocati/7210513
export const percToColor = (perc: number) => {
	if(perc > 100){
		perc = 100;
	}
	let r, g, b = 0;
	if(perc < 50) {
		r = 255;
		g = Math.round(5.1 * perc);
	}
	else {
		g = 255;
		r = Math.round(510 - 5.10 * perc);
	}
	let h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]?: string } = {
	1: '',
	3: 'ropsten.',
	4: 'rinkeby.',
	5: 'goerli.',
	42: 'kovan.',
	100: '',
	1337: '',
	56: '',
	97: '',
	137: '',
	361: '',
	365: '',
	1285: '',
	80001: '',
	1666600000: '',
	11297108109: '',
	31337: '',
	250: '',
	43114: '',
	19: '',
	1287: '',
	25: '',
	338: '',
	1284: '',
	42262: '',
	588: '',
	69: '',
	10: '',
	42161: '',
	421611: '',
}
  
export function getEtherscanLink(
	chainId: ChainId,
	data: string,
	type: 'transaction' | 'token' | 'address' | 'block'
): string {
	const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

	switch (type) {
		case 'transaction': {
		return `${prefix}/tx/${data}`
		}
		case 'token': {
		return `${prefix}/token/${data}`
		}
		case 'block': {
		return `${prefix}/block/${data}`
		}
		case 'address':
		default: {
		return `${prefix}/address/${data}`
		}
	}
}

export function isAddress(address: string | undefined): boolean { 
	try {
		shortenAddress(address ? address : '')
		return true
	}catch{
		return false
	}
}

export function extractPropertyLotSize(data: IListingValueType, returnType: "value" | "label" | undefined) {
	let result = {
		value: Math.floor(data?.value),
		label: "",
	}
	if(data?.type === 1) {
		result.label = "ft²"
	}
	if(returnType) {
		return result[returnType]
	}
	return result
}

export function uuid() {
	/*eslint-disable */
	//@ts-ignore
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
	/*eslint-enable */
}

export const isPriorityLocation = (location: string) => {
	let stateNamesFullStrings = ["florida", "colorado", "arizona"];
	let stateMarkersShortStrings = [
		"CO, US",
		"CO, USA",
		"CO, U.S.",
		"CO, U.S.A.",
		"CO, United States",
		"FL, US",
		"FL, USA",
		"FL, U.S.",
		"FL, U.S.A.",
		"FL, United States",
		"AZ, US",
		"AZ, USA",
		"AZ, U.S.",
		"AZ, U.S.A.",
		"AZ, United States",
	];
	if(stateNamesFullStrings.some((str) => location.toLowerCase().includes(str))) {
		return true;
	} else if(stateMarkersShortStrings.some((str) => location.includes(str))) {
		return true;
	}
	return false;
}

export const isBuyerBudgetEligibleForMeeting = (priceRange: IBuyerFlowData["priceRange"]) => {
	if(typeof priceRange === "string") {
		if(["<$200k"].indexOf(priceRange) > -1) {
			return false;
		} else {
			return true;
		}
	}
	return false;
}

export const buildQueryStringFromParams = (queryParamCollection: IQueryParamCollection[]) => {
	let queryString = '';
	if(queryParamCollection && (queryParamCollection.length > 0)) {
		let isFirst = true;
		for(let queryParamEntry of queryParamCollection) {
			if(queryParamEntry['key'] && queryParamEntry['value']) {
				if(isFirst) {
					queryString += `?`
				} else {
					queryString += `&`
				}
				queryString += `${queryParamEntry['key']}=${encodeURIComponent(queryParamEntry['value'])}`;
				isFirst = false;
			}
		}
	}
	return queryString;
}

export const parseJwt = (token: string) => {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
			window
					.atob(base64)
					.split('')
					.map(function (c) {
							return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
	);

	return JSON.parse(jsonPayload);
}

export const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";SameSite=Strict;path=/";
}

export const debounce = (
	func: () => void | false | Promise<unknown>,
	wait: number,
	immediate: boolean
) => {
	// 'private' variable for instance
	// The returned function will be able to reference this due to closure.
	// Each call to the returned function will share this common timer.
	let timeout: NodeJS.Timeout;

	// Calling debounce returns a new anonymous function
	return function() {
		// reference the context and args for the setTimeout function
		// @ts-ignore
		let context = this;
		let args = arguments;

		// Should the function be called now? If immediate is true
		//   and not already in a timeout then the answer is: Yes
		let callNow = immediate && !timeout;

		// This is the basic debounce behaviour where you can call this 
		//   function several times, but it will only execute once 
		//   [before or after imposing a delay]. 
		//   Each time the returned function is called, the timer starts over.
		clearTimeout(timeout);

		// Set the new timeout
		timeout = setTimeout(function() {

			// Inside the timeout function, clear the timeout variable
			// which will let the next execution run when in 'immediate' mode
			// @ts-ignore
			timeout = undefined;

			// Check if the function already ran with the immediate flag
			if (!immediate) {
				// Call the original function with apply
				// apply lets you define the 'this' object as well as the arguments 
				//    (both captured before setTimeout)
				// @ts-ignore
				func.apply(context, args);
			}
		}, wait);

		// Immediate mode and no wait timer? Execute the function..
		// @ts-ignore
		if (callNow) func.apply(context, args);
	}
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getCookieValue = (name: string) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const randomValueInRange = (min: number, max: number, decimal?: number, exclude?: number) => {

	let factor = 1;
	let result;
	if (typeof decimal === "number") {
			factor = Math.pow(10, decimal);
	}

	do {
			result = Math.random() * (max - min) + min;
			result = Math.round(result * factor) / factor;
	} while (result === exclude);

	return result;
}