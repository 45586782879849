import React from 'react';

import { animated, useSpring } from '@react-spring/web';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { PropsFromRedux } from '../containers/CookieConsentBannerContainer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import LinkWrapper from './LinkWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    float: {
      padding: theme.spacing(2),
      position: 'fixed',
      borderRadius: 16,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'calc(100% - 30px)',
      maxWidth: '1350px',
      backgroundColor: 'white',
      textAlign: 'center',
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
    },
    floatMobile: {
    },
    floatDesktop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    typographyDesktop: {
      width: 'calc(100% - 110px)',
    },
    acceptButtonMedium: {
      marginTop: theme.spacing(1),
    },
    acceptButtonMobile: {
      marginTop: theme.spacing(1),
      width: '100%',
    }
  }),
);

function CookieConsentBanner(props: PropsFromRedux) {

    const {
      isConsideredMobile,
      isConsideredMedium,
      giveConsentToCookies,
    } = props;

    const classes = useStyles();

    const getButtonClass = () => {
      if(isConsideredMobile) {
        return classes.acceptButtonMobile;
      }
      if(isConsideredMedium) {
        return classes.acceptButtonMedium;
      }
      return '';
    }

    const bannerSpring = useSpring({
      from: {
        bottom: '-100px',
      },
      to: {
        bottom: isConsideredMedium ? '16px' : '24px',
      },
      delay: 2000,
    })

    const emitConsent = () => {
      giveConsentToCookies();
    }

    return (
      <div className={classes.root}>
        <animated.div style={bannerSpring} className={[classes.float, isConsideredMedium ? classes.floatMobile : classes.floatDesktop].join(" ")}>
          <Typography className={[isConsideredMedium ? "" : classes.typographyDesktop].join(" ")}>
            We use cookies to personalize content and user experience, provide social media features and to analyze our traffic. For these reasons, we also share information about your use of our site with our partners. <LinkWrapper actionNameGA='privacy_policy_from_cookie_consent_banner' link="https://propy.com/browse/privacy-policy/" external={true}>Learn more here.</LinkWrapper> By continuing to browse our website, you agree to our use of cookies.
          </Typography>
          <Button onClick={() => emitConsent()} size="medium" variant="outlined" color="secondary" className={getButtonClass()}>
            I accept
          </Button>
        </animated.div>
      </div>
    )
};

export default CookieConsentBanner;