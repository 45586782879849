import React from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { PropsFromRedux } from '../containers/HighlightedFeaturesContainer';

import SingleVouchCard from './SingleVouchCard';
import MultiVouchCard from './MultiVouchCard';

import BackgroundImage from '../assets/img/research.webp';
import PropyPartnerCambridge from '../assets/img/propy-academic-cambridge.png';
import PropyPartnerOxford from '../assets/img/propy-academic-harvard.png';
import PropyPartnerHarvard from '../assets/img/propy-academic-mit.png';
import PropyPartnerMIT from '../assets/img/propy-academic-oxford.png';
import PropyPartnerPriceton from '../assets/img/propy-academic-princeton.png';
import PropyPartnerStanford from '../assets/img/propy-academic-standard.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    academicPartners: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
      flexWrap: 'wrap',
    },
    backgroundImage: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 8,
      boxShadow: '0px 0px 20px 0px #00000026',
      zIndex: -1,
    },
    content: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
    },
    academyImage: {
      margin: theme.spacing(2),
    }
  }),
);

interface IResearchBackedInventions {
  initialDelay?: number
  hideHeading?: boolean
}

const ResearchBackedInventions = (props: IResearchBackedInventions & PropsFromRedux) => {
    const classes = useStyles();

    const {
      initialDelay = 0,
      hideHeading = false,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const rootSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay
    })

    const partnerSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay + 250
    })

    const backgroundSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(25px)",
      },
      to: {
        opacity: isInView ? 0.15 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(25px)",
      },
      delay: initialDelay
    })

    return (
      <div className={classes.root} ref={ref}>
        <animated.div className={classes.backgroundImage} style={backgroundSpring}/>
        <Container maxWidth="lg">
          <div className={classes.content}>
            {!hideHeading &&
              <animated.div className={classes.headingText} style={rootSpring}>
                <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
                  Research-backed Inventions
                </Typography>
                <Typography className={classes.headingSubtitle} variant="h5" component="h4">
                  Contributing to extensive scientific research from the world’s top academic institutions
                </Typography>
              </animated.div>
            }
            <animated.div style={partnerSpring} className={classes.academicPartners}>
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerCambridge} alt="Cambridge University" />
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerOxford} alt="Oxford University" />
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerHarvard} alt="Harvard University" />
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerMIT} alt="MIT University" />
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerPriceton} alt="Princeton University" />
              <img className={classes.academyImage} style={{height: '48px', maxWidth: '100%'}} src={PropyPartnerStanford} alt="Stanford University" />
            </animated.div>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SingleVouchCard 
                  initialDelay={initialDelay}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MultiVouchCard 
                  initialDelay={initialDelay}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    )
};

export default ResearchBackedInventions;