import { connect, ConnectedProps } from 'react-redux';

import {
  setBuyerFlowCurrentStep,
  setBuyerFlowHasAgent,
  setBuyerFlowAgentInfo,
  setBuyerFlowAgentInfoEmailAddress,
  setBuyerFlowAgentInfoPhoneNumber,
  setBuyerFlowAgentInfoPhoneNumberCountry,
  setBuyerFlowAgentInfoFirstName,
  setBuyerFlowAgentInfoLastName,
  // setBuyerFlowAgentInfoMessage,
} from '../../state/actions';

import BuyerOnboardingStep4HasAgent from '../../components/BuyerOnboardingSteps/BuyerOnboardingStep4HasAgent';

import { IBuyerFlowData } from '../../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  buyerFlow: IBuyerFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  buyerFlow: state.buyerFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setBuyerFlowCurrentStep,
  setBuyerFlowHasAgent,
  setBuyerFlowAgentInfo,
  setBuyerFlowAgentInfoEmailAddress,
  setBuyerFlowAgentInfoPhoneNumber,
  setBuyerFlowAgentInfoPhoneNumberCountry,
  setBuyerFlowAgentInfoFirstName,
  setBuyerFlowAgentInfoLastName,
  // setBuyerFlowAgentInfoMessage,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(BuyerOnboardingStep4HasAgent)