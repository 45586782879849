import React, { useState, useEffect } from 'react';

import {
  animated,
  useSpring,
  useSprings,
  useInView,
} from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { PropsFromRedux } from '../containers/FeaturedListingsContainer';

import FloatingActionButton from './FloatingActionButton';
import LinkWrapper from './LinkWrapper';
import ListingCardUncontained from './ListingCardUncontained';

import {
  IListing
} from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      borderRadius: 8,
      // boxShadow: '0px 0px 20px 0px #00000026',
      padding: theme.spacing(0.5),
      minHeight: 800,
    },
    content: {
      // paddingTop: theme.spacing(12),
      // paddingBottom: theme.spacing(12),
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
    headingText: {
      zIndex: 2,
      position: 'relative',
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    headingTitle: {
      marginBottom: theme.spacing(1),
    },
    headingSubtitle: {

    },
    listedPropertiesButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    }
  }),
);

const FeaturedListings = (props: PropsFromRedux) => {
    const classes = useStyles();

    const [listings, setListings] = useState<IListing[]>([]);

    // const {
    //   isConsideredMobile,
    // } = props;

    useEffect(() => {
      let isMounted = true;
      fetch("https://api.propy.com/api/v3/properties/premium")
        .then(resp => resp.json())
        .then(response => {
          let curatedListings : IListing[] = [];
          let listingIds = new Set(); // Using Set for more efficient lookups
          const totalLimit = 12; // 4 from each line (4*3)

          // Helper function to process entries
          const processEntry = (entry: IListing) => {
            if (listingIds.has(entry.id)) return null;
            
            entry.imageUrls = entry.imageUrls.map(
              (item: string) => `${item}?width=680&height=auto&scale=both&mode=crop`
            );
            entry.accepts_crypto = true;
            
            listingIds.add(entry.id);
            return entry;
          };

          // Combine all lines into a single array
          const allEntries = [
            ...response.line1,
            ...response.line2,
            ...response.line3
          ];

          // First, add featured properties while maintaining their original order
          const featuredProperties = allEntries
            .filter(entry => entry.isFeaturedProperty)
            .map(processEntry)
            .filter(entry => entry !== null);

          // Get remaining non-featured properties
          const remainingProperties = allEntries
            .filter(entry => !entry.isFeaturedProperty)
            .map(processEntry)
            .filter(entry => entry !== null);

          // Shuffle remaining properties
          const shuffleArray = (array: any[]) => {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
          };

          // Combine featured and shuffled remaining properties
          curatedListings = [
            ...featuredProperties,
            ...shuffleArray(remainingProperties)
          ].slice(0, totalLimit);
          
          if (isMounted) {
            setListings(curatedListings);
          }
        })
      return () => {
        isMounted = false;
      };
    }, [])

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const titleSpring = useSpring({
      from: {
        opacity: 0,
        top: -25,
      },
      to: {
        opacity: isInView ? 1 : 0,
        top: isInView ? 0 : -25,
      },
      delay: 250
    })

    const [springs] = useSprings(
      listings.length + 1,
      (springIndex: number) => ({
        from: {
          opacity: 0,
          transform: 'translateY(-35px)',
        },
        to: {
          opacity: isInView ? 1 : 0,
          transform: isInView ? 'translateY(0px)' : 'translateY(-35px)',
        },
        delay: 1200 + (200 * springIndex),
      }),
      [isInView, listings]
    )

    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.content}>
          <animated.div className={classes.headingText} style={titleSpring}>
            <Typography className={classes.headingTitle} style={{fontWeight: 'bold'}} variant="h3">
              Featured Listings
            </Typography>
            <Typography className={classes.headingSubtitle} variant="h5" component="h4">
              A curated collection of our favorite properties
            </Typography>
          </animated.div>
          <Grid container spacing={0}>
            {listings.map(
              (listing, index) => {
                if(index < 7) {
                  return (
                    <Grid key={`featured-listing-${index}`} item xs={12} sm={12} md={(index === 0) ? 8 : 4} lg={(index === 0) ? 6 : 3}>
                      <animated.div style={springs[index]}>
                        <ListingCardUncontained
                          listing={listing}
                        />
                      </animated.div>
                    </Grid>
                  )
                }
                return <></>
              }
            )}
          </Grid>
          {listings && (listings.length > 0) && 
            <animated.div style={springs[listings.length]} className={classes.listedPropertiesButtonContainer}>
              <LinkWrapper actionNameGA='lp_map_from_featured_listings' link="https://propy.com/map" external={true}>
                <FloatingActionButton
                  text="View More Listings"
                />
              </LinkWrapper>
            </animated.div>
          }
        </div>
      </div>
    )
};

export default FeaturedListings;