import { IBuyerFlowData } from '../../interfaces';

interface IAgentInfo {
  agentEmailAddress: false | string;
  agentPhoneNumber: false | string;
  agentPhoneNumberCountry: false | string;
  agentMessage: false | string;
  agentFirstName: false | string;
  agentLastName: false | string;
}

interface IBuyerFlowDataAction {
  type: string;
  agentInfo: IAgentInfo,
}

const defaultState : IBuyerFlowData = {
  currentStep: "",
  profileAvailability: false,
  sessionId: false,
  serviceRequirement: false,
  desiredLocation: false,
  hasAgent: false,
  firstName: false,
  lastName: false,
  emailAddress: false,
  phoneNumber: false,
  phoneNumberCountry: false,
  purchaseType: false,
  priceRange: false,
  agentEmailAddress: false,
  agentPhoneNumber: false,
  agentPhoneNumberCountry: false,
  agentFirstName: false,
  agentLastName: false,
  agentMessage: false,
}

const buyerFlow = (
  state = defaultState,
  action: IBuyerFlowDataAction & IBuyerFlowData
) => {
  switch (action.type) {
    case 'SET_BUYER_FLOW_SESSION_ID': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.sessionId = action.sessionId;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_CURRENT_STEP': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.currentStep = action.currentStep;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_SERVICE_REQUIREMENT': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.serviceRequirement = action.serviceRequirement;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_DESIRED_LOCATION': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.desiredLocation = action.desiredLocation;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO': {
      let {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        phoneNumberCountry,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      stateCopy.lastName = lastName;
      stateCopy.emailAddress = emailAddress;
      stateCopy.phoneNumber = phoneNumber;
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO_FIRST_NAME': {
      let {
        firstName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.firstName = firstName;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO_LAST_NAME': {
      let {
        lastName,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.lastName = lastName;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO_EMAIL_ADDRESS': {
      let {
        emailAddress,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.emailAddress = emailAddress;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO_PHONE_NUMBER': {
      let {
        phoneNumber,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumber = phoneNumber;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_INFO_PHONE_NUMBER_COUNTRY': {
      let {
        phoneNumberCountry,
      } = action;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.phoneNumberCountry = phoneNumberCountry;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PROFILE_AVAILABILITY': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.profileAvailability = action.profileAvailability;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_HAS_AGENT': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.hasAgent = action.hasAgent;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PURCHASE_TYPE': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.purchaseType = action.purchaseType;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_PRICE_RANGE': {
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.priceRange = action.priceRange;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO': {
      let {
        agentEmailAddress,
        agentPhoneNumber,
        agentPhoneNumberCountry,
        agentFirstName,
        agentLastName,
        agentMessage,
      } = action.agentInfo;
      let stateCopy = JSON.parse(JSON.stringify(state));
      stateCopy.agentEmailAddress = agentEmailAddress;
      stateCopy.agentPhoneNumber = agentPhoneNumber;
      stateCopy.agentPhoneNumberCountry = agentPhoneNumberCountry;
      stateCopy.agentFirstName = agentFirstName;
      stateCopy.agentLastName = agentLastName;
      stateCopy.agentMessage = agentMessage;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_EMAIL_ADDRESS': {
      let {
        agentEmailAddress,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentEmailAddress = agentEmailAddress;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_PHONE_NUMBER': {
      let {
        agentPhoneNumber,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentPhoneNumber = agentPhoneNumber;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_PHONE_NUMBER_COUNTRY': {
      let {
        agentPhoneNumberCountry,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentPhoneNumberCountry = agentPhoneNumberCountry;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_FIRST_NAME': {
      let {
        agentFirstName,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentFirstName = agentFirstName;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_LAST_NAME': {
      let {
        agentLastName,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentLastName = agentLastName;
      return stateCopy;
    }
    case 'SET_BUYER_FLOW_AGENT_INFO_MESSAGE': {
      let {
        agentMessage,
      } = action;
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(state));
      stateCopy.agentMessage = agentMessage;
      return stateCopy;
    }
    case 'RESET_BUYER_FLOW': {
      let stateCopy : IBuyerFlowData = JSON.parse(JSON.stringify(defaultState));
      return stateCopy;
    }
    default:
      return state
  }
}

export default buyerFlow;