import React, { useState, useEffect, useRef } from 'react';

import { animated, useSpring, useInView } from '@react-spring/web'

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { PropsFromRedux } from '../containers/TitleAndEscrowBannerContainer';

import LinkWrapper from './LinkWrapper';

import { useWindowSize } from '../hooks';

import PropyAndCoinbaseIllustration from '../assets/img/propy-and-coinbase-prime-black.png';
import CoinbaseLogo from '../assets/svg/coinbase-logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      // boxShadow: '0px 0px 20px 0px #00000026',
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundPosition: 'center right',
      overflow: 'hidden',
      backgroundColor: '#F7F7F7',
      flexDirection: 'column',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      padding: theme.spacing(4)
    },
    typographyContainer: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      color: 'black',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    typographyContainerMobile: {
      maxWidth: 700,
      padding: theme.spacing(4),
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: 'white',
    },
    foregroundImageContainer: {
      marginRight: theme.spacing(8),
      height: '100%',
      width: 400,
    },
    typographySpacer: {
      marginBottom: theme.spacing(2)
    },
    foregroundImage: {
      paddingTop: 52,
      maxWidth: '100%',
    },
    foregroundImageMobile: {
      maxWidth: 300,
    },
    subtitle: {
      // fontWeight: 400,
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    coinbaseLogoZone: {
      marginTop: theme.spacing(4),
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
    }
  }),
);

interface IPropyAndCoinbasePrimeBanner {
  initialDelay?: number
}

const PropyAndCoinbasePrimeBanner = (props: IPropyAndCoinbasePrimeBanner & PropsFromRedux) => {
    const classes = useStyles();

    const [isHovered, setIsHovered] = useState(false);
    const [illustrationPadding, setIllustrationPadding] = useState(0);

    const {
      initialDelay = 0,
      isConsideredMobile,
    } = props;

    const [ref, isInView] = useInView({
      amount: 'any',
      once: true,
    })

    const rootSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(-25px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-25px)",
      },
      delay: initialDelay
    })

    const typographySpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(50px)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(0px)" : "translateY(-50px)",
      },
      delay: initialDelay
    })

    const backgroundImageSpring = useSpring({
      from: {
        opacity: 0,
        transform: "translateY(100%)",
      },
      to: {
        opacity: isInView ? 1 : 0,
        transform: isInView ? "translateY(40%)" : "translateY(100%)",
      },
      delay: initialDelay + 200,
    })

    const backgroundImageRef = useRef<HTMLImageElement>(null);

    const windowSize = useWindowSize();

    useEffect(() => {
      if (backgroundImageRef?.current) {
        const image = backgroundImageRef.current;
        
        const updatePadding = () => {
          setIllustrationPadding(image.height / 2.5);
        };
  
        // Handle already loaded/cached images
        if (image.complete) {
          updatePadding();
        }
  
        // Handle images that load after initial render
        image.addEventListener('load', updatePadding);
  
        return () => {
          image.removeEventListener('load', updatePadding);
        };
      }
    }, [windowSize.width, windowSize.height]);

    return (
      <LinkWrapper link={"https://coinbase.propy.com/escrow/?q=homepage_banner"} external={true}>
        <animated.div onClick={() => setIsHovered(false)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} ref={ref} className={classes.root} style={rootSpring}>
          <animated.div className={classes.typographyContainer} style={{...typographySpring, ...(illustrationPadding && {paddingBottom: `${illustrationPadding}px`})}}>
            <Typography className={[classes.typographySpacer].join(" ")} style={{fontWeight: 'bold', marginBottom: 24, transition: 'all 0.1s ease-in-out', ...(isHovered && { color: "#38A6FA" })}} variant="h3">
              Crypto Escrow with Coinbase Prime
            </Typography>
            <Typography style={{maxWidth: 920}} className={[classes.typographySpacer, classes.subtitle].join(" ")} variant="h5" component="h4">
              Skip early conversions, reduce exposure to market volatility, and avoid premature tax implications—all within a compliant, streamlined process.
            </Typography>
            <Button variant={"contained"} color="primary">
              Learn More
            </Button>
          </animated.div>
          <animated.img ref={backgroundImageRef} alt="Propy & Coinbase Prime" src={PropyAndCoinbaseIllustration} style={{...backgroundImageSpring, maxHeight: 360, position: 'absolute', maxWidth: '70%', zIndex: -1, bottom: '0px', ...(isConsideredMobile && {opacity: 0.1})}} />
          <div className={classes.coinbaseLogoZone}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center'}}>
              <img src={CoinbaseLogo} alt="Coinbase" />
              <Typography variant="body1">
                Secured by Coinbase Prime
              </Typography>
            </div>
          </div>
        </animated.div>
      </LinkWrapper>
    )
};

export default PropyAndCoinbasePrimeBanner;