import React, { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import {
  uuid
} from '../utils';

import {
  PROJECT_ROOT,
} from '../constants';

import AgentOnboardingStep1LocationContainer from '../containers/AgentOnboardingSteps/AgentOnboardingStep1LocationContainer';
import AgentOnboardingStep3DealsClosedContainer from '../containers/AgentOnboardingSteps/AgentOnboardingStep3DealsClosedContainer';
import AgentOnboardingStep2ProfileInfoContainer from '../containers/AgentOnboardingSteps/AgentOnboardingStep2ProfileInfoContainer';
import AgentOnboardingStep4ScheduleMeetingContainer from '../containers/AgentOnboardingSteps/AgentOnboardingStep4ScheduleMeetingContainer';
import AgentOnboardingThankYou from '../containers/AgentOnboardingSteps/AgentOnboardingThankYouContainer';

import { PropsFromRedux } from '../containers/AgentOnboardingStepsContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    formContainer: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
    },
    navigationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
    navigationButton: {

    },
    leftCornerContainer: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
    progressContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      opacity: 0.75,
    },
    circleProgressContainer: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    leftCornerTypography: {
      opacity: 0.7
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    innerContentFirstAndFinal: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    mobilePaddingOverride: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    recaptchaContainer: {
      position: 'relative',
      width: '100%',
    },
  }),
);

interface IAgentOnboarding {
  onboardingStep?: string;
  recaptchaRef: any;
}

const onboardingStepToStepIndex : {[key: string]: number} = {
  "": 0,
  "profile": 1,
  "deals-closed": 2,
  "schedule-onboarding": 3,
  "profile-complete": 3,
  "schedule-onboarding-complete": 4,
}

const onboardingStepToPreviousStep : {[key: string]: string} = {
  "": "",
  "profile": "",
  "deals-closed": "profile",
  "schedule-onboarding": "deals-closed",
  "profile-complete": "deals-closed",
  "schedule-onboarding-complete": "schedule-onboarding",
}

const stepToCompletionPercent : {[key: string]: number} = {
  "": 15,
  "profile": 60,
  "deals-closed": 80,
  "schedule-onboarding": 95,
  "profile-complete": 100,
  "schedule-onboarding-complete": 100,
}

const AgentOnboardingSteps = (props: IAgentOnboarding & PropsFromRedux) => {

    let navigate = useNavigate();

    const [stepSubmitting, setStepSubmitting] = useState(false);

    const {
      onboardingStep = "",
      agentFlow,
      setAgentFlowSessionId,
      setAgentFlowCurrentStep,
      recaptchaRef,
      isConsideredMobile,
    } = props;

    useEffect(() => {
      if(!agentFlow.sessionId) {
        let newSessionId = uuid();
        setAgentFlowSessionId(newSessionId);
      }
      if(onboardingStep !== "schedule-onboarding-complete") {
        if((agentFlow.currentStep || agentFlow.currentStep === '') && (agentFlow.currentStep !== onboardingStep)) {
          navigate(`${PROJECT_ROOT}/get-started/agent/${agentFlow.currentStep}`, { replace: true })
        }
      }
    }, [agentFlow, setAgentFlowSessionId, onboardingStep, navigate])

    useEffect(() => {
      if((onboardingStepToStepIndex[agentFlow.currentStep] === undefined) || (onboardingStepToStepIndex[onboardingStep] === undefined)) {
        setAgentFlowCurrentStep("");
      } else if(onboardingStep === "schedule-onboarding-complete" && (agentFlow.currentStep !== "schedule-onboarding-complete")) {
        setAgentFlowCurrentStep("schedule-onboarding-complete");
      }
    }, [agentFlow, onboardingStep, setAgentFlowCurrentStep])

    const classes = useStyles();

    return (
      <div 
        className={[(onboardingStepToStepIndex[onboardingStep] === 4 || onboardingStepToStepIndex[onboardingStep] === 0) ? classes.innerContentFirstAndFinal : classes.innerContent, "box-shadow-default", isConsideredMobile ? classes.mobilePaddingOverride : ""].join(" ")}
        style={(["schedule-onboarding", "schedule-onboarding-complete", "profile-complete"].indexOf(onboardingStep) > -1)  ? {width: 800, maxWidth: '100%', paddingBottom: 0} : {width: 650, maxWidth: '100%'}}
      >
        <Box className={[classes.formContainer].join(" ")} sx={{ width: '100%' }}>
          <div className={classes.progressContainer}>
            <LinearProgress variant="determinate" color="primary" value={stepToCompletionPercent[onboardingStep]} />
          </div>
          <div className={classes.circleProgressContainer}>
            {stepSubmitting && <CircularProgress style={{height: 25, width: 25}} />}
          </div>
          <div className={classes.leftCornerContainer}>
            {(stepToCompletionPercent[onboardingStep] < 100) && (onboardingStepToStepIndex[onboardingStep] !== 0) &&
              <div className={"grey-chip-interactive"} onClick={() => setAgentFlowCurrentStep(onboardingStepToPreviousStep[agentFlow.currentStep])}>
                <Typography variant="subtitle2" component="div">
                  Go back
                </Typography>
              </div>
            }
          </div>
          {onboardingStep === "" &&
            <AgentOnboardingStep1LocationContainer
              setStepSubmitting={setStepSubmitting}
              recaptchaRef={recaptchaRef}
            />
          }
          {onboardingStep === "profile" &&
            <AgentOnboardingStep2ProfileInfoContainer
              setStepSubmitting={setStepSubmitting}
              recaptchaRef={recaptchaRef}
            />
          }
          {onboardingStep === "deals-closed" &&
            <AgentOnboardingStep3DealsClosedContainer
              setStepSubmitting={setStepSubmitting}
              recaptchaRef={recaptchaRef}
            />
          }
          {onboardingStep === "profile-complete" &&
            <AgentOnboardingThankYou
              showWelcomeAboard={true}
            />
          }
          {onboardingStep === "schedule-onboarding" &&
            <AgentOnboardingStep4ScheduleMeetingContainer />
          }
          {onboardingStep === "schedule-onboarding-complete" &&
            <AgentOnboardingThankYou
              showListNow={true}
            />
          }
        </Box>
      </div>
    )
};

export default AgentOnboardingSteps;