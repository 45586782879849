import React from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/BuyerOnboardingSteps/BuyerOnboardingStep1ServiceRequirementContainer';

import { 
  PROPY_BLUE,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
  PURCHASE_TYPE,
} from '../../constants';

import FloatingActionButton from '../FloatingActionButton';

import { FlowService } from '../../services/api';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import {
  IBuyerFlowData
} from "../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      fontSize: '26px',
    },
    optionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    optionSpacer: {
      marginTop: theme.spacing(2),
    },
    optionButton: {
      fontSize: '20px',
      padding: theme.spacing(4),
      borderRadius: 40,
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    }
  }),
);

interface IServiceRequirementOption {
  value: IBuyerFlowData["serviceRequirement"];
  label: string;
}

const serviceRequirementOptions : IServiceRequirementOption[] = [
  {
    value: "looking",
    label: "Looking for Property",
  },
  {
    value: "title-and-escrow",
    label: "Need Title & Escrow",
  },
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function BuyerOnboardingStep1ServiceRequirement(props: PropsFromRedux & IStepProps) {

  const {
    setBuyerFlowServiceRequirement,
    setBuyerFlowCurrentStep,
    buyerFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const classes = useStyles();

  const setSelectedServiceRequirement = async (serviceRequirement: IBuyerFlowData["serviceRequirement"]) => {
    setBuyerFlowServiceRequirement(serviceRequirement);
    // // submit data to backend
    if(!recaptchaRef?.current) {
      toast.error("Recaptcha expired, please refresh the page.");
    }
    if(buyerFlow.sessionId && recaptchaRef.current) {
      setStepSubmitting(true);
      const token = await getToken();
      try {
        await FlowService.patchSession(buyerFlow.sessionId, {
          Type: FLOW_TYPE_TO_LEAD_TYPE["buyer"],
          ...(serviceRequirement && { Actions: [SERVICE_REQUIREMENT[serviceRequirement]] }),
          ...(buyerFlow.desiredLocation && { Location: { placeDesc: buyerFlow.desiredLocation } }),
          ...(buyerFlow.purchaseType && { Purchase: PURCHASE_TYPE[buyerFlow.purchaseType] }),
          ...(buyerFlow.firstName && { FirstName: buyerFlow.firstName }),
          ...(buyerFlow.lastName && { LastName: buyerFlow.lastName }),
          ...(buyerFlow.emailAddress && { Email: buyerFlow.emailAddress }),
          ...(buyerFlow.phoneNumber && { PhoneNumber: buyerFlow.phoneNumber }),
          ...(buyerFlow.phoneNumberCountry && { PhoneCountryCode: buyerFlow.phoneNumberCountry }),
          ...(buyerFlow.priceRange && { PriceRange: buyerFlow.priceRange }),
          ...(buyerFlow.hasAgent && { HasAgent: buyerFlow.hasAgent === "has-agent" ? true : false }),
          ...(buyerFlow.agentEmailAddress && { AgentEmail: buyerFlow.agentEmailAddress }),
          ...(buyerFlow.agentPhoneNumber && { AgentPhoneNumber: buyerFlow.agentPhoneNumber }),
          ...(buyerFlow.agentPhoneNumberCountry && { AgentPhoneCountryCode: buyerFlow.agentPhoneNumberCountry }),
          ...(buyerFlow.agentFirstName && { AgentFirstName: buyerFlow.agentFirstName }),
          ...(buyerFlow.agentLastName && { AgentLastName: buyerFlow.agentLastName }),
          ...(buyerFlow.agentMessage && { MessageToAgent: buyerFlow.agentMessage }),
        }, token);
        setStepSubmitting(false);
        toast.success('Saved progress!');
        if(serviceRequirement === "looking") {
          setBuyerFlowCurrentStep("describe-desired-property");
        } else if (serviceRequirement === "title-and-escrow") {
          setBuyerFlowCurrentStep("pick-location");
        }
      } catch (e) {
        console.log({e})
        setStepSubmitting(false);
        toast.error("Unable to save progress, please try again or contract support");
      }
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        Where are you in the buying process?
      </Typography>
      <div className={classes.optionContainer} id="buyer-onboarding-step-1-service-requirement-option-list">
        {serviceRequirementOptions.map((entry, index) => 
          <FloatingActionButton 
            key={`service-requirement-${index}-${entry.value}`}
            onClick={() => setSelectedServiceRequirement(entry.value)}
            className={
              [
                classes.optionButton,
                index > 0 ? classes.optionSpacer : "",
                (buyerFlow.serviceRequirement === entry.value) ? classes.optionButtonSelected : classes.optionButtonUnselected
              ].join(" ")
            }
            textColor={(buyerFlow.serviceRequirement === entry.value) ? 'white' : PROPY_BLUE}
            size="large"
            buttonColor={(buyerFlow.serviceRequirement !== entry.value) ? "default" : "primary"}
            text={entry.label}
          />
        )}
      </div>
    </animated.div>
  );
}