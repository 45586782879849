import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';

import { Link } from "react-router-dom";

import { useIntercom } from 'react-use-intercom';

import { animated, useSpring, useInView } from '@react-spring/web';

import ExternalLink from '../ExternalLink';
import FloatingActionButton from '../FloatingActionButton';
import AgentCourseCard from '../AgentCourseCard';
import VerticalImageCard from '../VerticalImageCard';

import RealEstateNftsImage from '../../assets/img/real-estate-nfts.webp';
import PropyBlogImage from '../../assets/img/propy-blog-house.webp';

import { PropsFromRedux } from '../../containers/SellerOnboardingSteps/SellerOnboardingThankYouContainer';

import RefreshIcon from '@mui/icons-material/Refresh';

import {
  BASE_URL,
  PROPY_BLUE,
  PROJECT_ROOT,
} from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
    },
    formSubtitle: {
      fontSize: '18px',
    },
    formGetCertifiedTitle: {
      fontSize: '26px',
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    helperTextContainer: {
      fontSize: '22px',
    },
    startOverText: {
      fontSize: '16px',
    },
    createListingButton: {
      marginTop: theme.spacing(2),
    },
    courseCardContainer: {
      marginBottom: theme.spacing(2),
    },
    quickLinkCardContainer: {
      marginBottom: theme.spacing(4),
    },
    postTitleSpacing: {
      marginTop: theme.spacing(6),
    },
    helpText: {
      color: PROPY_BLUE,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    bottomSpacing: {
      marginBottom: theme.spacing(8),
    }
  }),
);

export default function SellerOnboardingThankYou(props: PropsFromRedux) {

  const {
    show,
  } = useIntercom();

  const classes = useStyles();

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <>
        <Typography className={classes.formTitle} gutterBottom variant="h4" component="div">
          Thank you!
        </Typography>
        <Typography className={classes.formSubtitle} variant="body1">
          Your Propy account has been created, we look forward to working together!
        </Typography>
        <div className={'flex-align-all'}>
          <ExternalLink href={`${BASE_URL}/map`}>
            <FloatingActionButton
              className={classes.createListingButton}
              text="Browse Listings"
            />
          </ExternalLink>
        </div>
      </>
      <div className={classes.postTitleSpacing}>
        <Typography className={classes.formGetCertifiedTitle} gutterBottom variant="h5" component="div">
          Transact & Close on Propy
        </Typography>
        <div className={classes.quickLinkCardContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <VerticalImageCard
                image={PropyBlogImage}
                title="Propy Blog"
                description='Stay in the loop with the latest'
                link="https://propy.com/browse/blog/"
                external={true}
                actionNameGA='blog_from_seller_onboarding_thank_you'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <VerticalImageCard
                image={RealEstateNftsImage}
                title="Real Estate NFTs"
                description="Create and sell real estate as NFTs"
                link="https://propy.com/browse/propytitle/"
                external={true}
                actionNameGA='propytitle_from_seller_onboarding_thank_you'
              />
            </Grid>
          </Grid>
        </div>
        <Typography className={classes.formGetCertifiedTitle} gutterBottom variant="h5" component="div">
          Get Certified
        </Typography>
        <div className={classes.courseCardContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AgentCourseCard
                courseId="crypto-agent"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AgentCourseCard
                courseId="meta-agent"
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.bottomSpacing}>
        <Typography className={classes.helperTextContainer} gutterBottom variant="h6" component="div">
          Need help? <span className={classes.helpText} onClick={() => show()}>Reach out</span> for assistance
        </Typography>
        <Link replace to={`${PROJECT_ROOT}/get-started/seller/reset`} style={{color: 'inherit'}}>
          <Typography className={[classes.helperTextContainer, 'flex-vertical-center', 'interactive'].join(' ')} gutterBottom variant="h6" component="div">
            <RefreshIcon style={{marginRight: 8}} /> Start over
          </Typography>
        </Link>
      </div>
    </animated.div>
  );
}