import React, { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import {
  uuid
} from '../utils';

import {
  PROJECT_ROOT,
} from '../constants';

import {
  ServiceRequirementsSellerEnum,
} from '../interfaces';

import { PropsFromRedux } from '../containers/SellerOnboardingStepsContainer';

import SellerOnboardingStep1ServiceRequirementContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep1ServiceRequirementContainer';
import SellerOnboardingStep2ProfileInfoContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep2ProfileInfoContainer';
import SellerOnboardingStep3TitleAndEscrowEligibilityContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep3TitleAndEscrowEligibilityContainer';
import SellerOnboardingStep4TitleAndEscrowScheduleMeetingContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep4TitleAndEscrowScheduleMeetingContainer';
import SellerOnboardingStep3CommunityNftLocationFinderContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep3CommunityNftLocationFinderContainer';
import SellerOnboardingStep4CommunityNftAgentDetailsContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep4CommunityNftAgentDetailsContainer';
import SellerOnboardingStep5ScheduleMeetingContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep5ScheduleMeetingContainer';
import SellerOnboardingStep5ListingOptionsContainer from '../containers/SellerOnboardingSteps/SellerOnboardingStep5ListingOptionsContainer';
import SellerOnboardingThankYouContainer from '../containers/SellerOnboardingSteps/SellerOnboardingThankYouContainer';

// import { PROJECT_ROOT } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    formContainer: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
    },
    navigationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
    navigationButton: {

    },
    leftCornerContainer: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
    progressContainer: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      opacity: 0.75,
    },
    circleProgressContainer: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    leftCornerTypography: {
      opacity: 0.7
    },
    innerContent: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      paddingTop: theme.spacing(10),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    innerContentFirstAndFinal: {
      position: 'relative',
      backgroundColor: 'white',
      padding: theme.spacing(8),
      borderRadius: 16,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    mobilePaddingOverride: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    recaptchaContainer: {
      position: 'relative',
      width: '100%',
    },
  }),
);

const getOnboardingStepToStepIndex = (
  serviceRequirements: Array<keyof typeof ServiceRequirementsSellerEnum>,
  step: string,
) => {
  if(serviceRequirements.length === 0) {
    // default config
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "schedule-session-complete": 5,
      "schedule-onboarding-complete": 5,
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("title-and-escrow") > -1
  ) {
    // Title and Escrow Flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "title-and-escrow-services": 2,
      "title-and-escrow-meeting": 3,
      "listing-options": 4,
      "schedule-session-complete": 5,
      "schedule-onboarding-complete": 5,
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("exposure-to-propy-community") > -1
  ) {
    // Community flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "location-finder": 2,
      "agent-details": 3,
      "schedule-session": 4,
      "listing-options": 4,
      "schedule-session-complete": 5,
      "schedule-onboarding-complete": 5,
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("mint-property-as-nft") > -1
  ) {
    // NFT flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "location-finder": 2,
      "agent-details": 3,
      "schedule-session": 4,
      "listing-options": 4,
      "schedule-session-complete": 5,
      "schedule-onboarding-complete": 5,
    }
    return config[step];
  }
  return undefined;
}

const isFirstOrLastStep = (
  serviceRequirements: Array<keyof typeof ServiceRequirementsSellerEnum>,
  step: string,
) => {
  if(serviceRequirements.length === 0) {
    // default config
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "listing-options": 4,
      // "schedule-session-complete": 5,
      // "schedule-onboarding-complete": 5,
    }
    if(config[step] === 0 || config[step] === 4) {
      return true;
    }
  } else if (
    serviceRequirements.indexOf("title-and-escrow") > -1
  ) {
    // Title and Escrow Flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "title-and-escrow-services": 2,
      "title-and-escrow-meeting": 3,
      "listing-options": 4,
      // "schedule-session-complete": 5,
      // "schedule-onboarding-complete": 5,
    }
    if(
      config[step] === 0 ||
      config[step] === 4
      // config[step] === 5
    ) {
      return true;
    }
  } else if (
    serviceRequirements.indexOf("exposure-to-propy-community") > -1
  ) {
    // Community flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "location-finder": 2,
      "agent-details": 3,
      "schedule-session": 4,
      "listing-options": 4,
      // "schedule-session-complete": 5,
      // "schedule-onboarding-complete": 5,
    }
    if(
      config[step] === 0 ||
      config[step] === 4
      // config[step] === 5
    ) {
      return true;
    }
  } else if (
    serviceRequirements.indexOf("mint-property-as-nft") > -1
  ) {
    // NFT flow
    let config : {[key: string]: number} = {
      "": 0,
      "profile": 1,
      "location-finder": 2,
      "agent-details": 3,
      "schedule-session": 4,
      "listing-options": 4,
      // "schedule-session-complete": 5,
      // "schedule-onboarding-complete": 5,
    }
    if(
      config[step] === 0 || 
      config[step] === 4
      // config[step] === 5
    ) {
      return true;
    }
  }
  return false;
}

const getOnboardingStepToPreviousStep = (
  serviceRequirements: Array<keyof typeof ServiceRequirementsSellerEnum>,
  step: string,
) => {
  if(serviceRequirements.length === 0) {
    // default config
    let config : {[key: string]: string} = {
      "": "",
      "profile": "",
      "schedule-session-complete": "",
      "schedule-onboarding-complete": ""
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("title-and-escrow") > -1
  ) {
    // Title and Escrow Flow
    let config : {[key: string]: string} = {
      "": "",
      "profile": "",
      "title-and-escrow-services": "profile",
      "title-and-escrow-meeting": "title-and-escrow-services",
      "listing-options": "title-and-escrow-services",
      "schedule-session-complete": "",
      "schedule-onboarding-complete": ""
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("exposure-to-propy-community") > -1
  ) {
    // Community flow
    let config : {[key: string]: string} = {
      "": "",
      "profile": "",
      "location-finder": "profile",
      "agent-details": "location-finder",
      "schedule-session": "agent-details",
      "listing-options": "agent-details",
      "schedule-session-complete": "",
      "schedule-onboarding-complete": ""
    }
    return config[step];
  } else if (
    serviceRequirements.indexOf("mint-property-as-nft") > -1
  ) {
    // NFT flow
    let config : {[key: string]: string} = {
      "": "",
      "profile": "",
      "location-finder": "profile",
      "agent-details": "location-finder",
      "schedule-session": "agent-details",
      "listing-options": "agent-details",
      "schedule-session-complete": "",
      "schedule-onboarding-complete": ""
    }
    return config[step];
  }
  return "";
}

const getStepToCompletionPercent = (
  serviceRequirements: Array<keyof typeof ServiceRequirementsSellerEnum>,
  step: string,
) => {
  if(serviceRequirements.length === 0) {
    // default config
    let config : {[key: string]: number} = {
      "": 15,
      "profile": 30,
      "schedule-session-complete": 100,
    }
    return config[step]
  } else if (
    serviceRequirements.indexOf("title-and-escrow") > -1
  ) {
    // Title and Escrow Flow
    let config : {[key: string]: number} = {
      "": 15,
      "profile": 35,
      "title-and-escrow-services": 70,
      // "title-and-escrow-meeting": 80,
      "schedule-session-complete": 100,
    }
    return config[step]
  } else if (
    serviceRequirements.indexOf("exposure-to-propy-community") > -1
  ) {
    // Community flow
    let config : {[key: string]: number} = {
      "": 15,
      "profile": 30,
      "location-finder": 50,
      "agent-details": 75,
      // "schedule-session": 90,
      "listing-options": 100,
      "schedule-session-complete": 100,
    }
    return config[step]
  } else if (
    serviceRequirements.indexOf("mint-property-as-nft") > -1
  ) {
    // NFT flow
    let config : {[key: string]: number} = {
      "": 15,
      "profile": 30,
      "location-finder": 50,
      "agent-details": 75,
      // "schedule-session": 90,
      "listing-options": 100,
      "schedule-session-complete": 100,
    }
    return config[step]
  }
  return 0;
}

interface ISellerOnboarding {
  onboardingStep?: string;
  recaptchaRef: any;
}

const SellerOnboardingSteps = (props: ISellerOnboarding & PropsFromRedux) => {
  let navigate = useNavigate();

  const [stepSubmitting, setStepSubmitting] = useState(false);

  const {
    onboardingStep = "",
    sellerFlow,
    setSellerFlowSessionId,
    setSellerFlowCurrentStep,
    recaptchaRef,
    isConsideredMobile,
  } = props;

  useEffect(() => {
    if(!sellerFlow.sessionId) {
      let newSessionId = uuid();
      setSellerFlowSessionId(newSessionId);
    }
    if(onboardingStep !== "schedule-session-complete") {
      if((sellerFlow.currentStep || sellerFlow.currentStep === '') && (sellerFlow.currentStep !== onboardingStep)) {
        navigate(`${PROJECT_ROOT}/get-started/seller/${sellerFlow.currentStep}`, { replace: true })
      }
    }
  }, [sellerFlow, setSellerFlowSessionId, onboardingStep, navigate])

  useEffect(() => {
    if((getOnboardingStepToStepIndex(sellerFlow.serviceRequirements, sellerFlow.currentStep) === undefined) || (getOnboardingStepToStepIndex(sellerFlow.serviceRequirements, onboardingStep) === undefined)) {
      setSellerFlowCurrentStep("");
    } else if(onboardingStep === "schedule-session-complete" && (sellerFlow.currentStep !== "schedule-session-complete")) {
      setSellerFlowCurrentStep("schedule-session-complete");
    }
  }, [sellerFlow, onboardingStep, setSellerFlowCurrentStep])

  const classes = useStyles();

  return (
    <div 
      className={[isFirstOrLastStep(sellerFlow.serviceRequirements, onboardingStep) ? classes.innerContentFirstAndFinal : classes.innerContent, "box-shadow-default", isConsideredMobile ? classes.mobilePaddingOverride : ""].join(" ")}
      style={(["schedule-session", "title-and-escrow-meeting", "profile-complete", 'schedule-session-complete'].indexOf(onboardingStep) > -1)  ? {width: 800, maxWidth: '100%', paddingBottom: 0} : {width: 700, maxWidth: '100%'}}
    >
      <Box className={[classes.formContainer].join(" ")} sx={{ width: '100%' }}>
        <div className={classes.progressContainer}>
          <LinearProgress variant="determinate" color="primary" value={getStepToCompletionPercent(sellerFlow.serviceRequirements, onboardingStep)} />
        </div>
        <div className={classes.circleProgressContainer}>
          {stepSubmitting && <CircularProgress style={{height: 25, width: 25}} />}
        </div>
        <div className={classes.leftCornerContainer}>
          {!isFirstOrLastStep(sellerFlow.serviceRequirements, onboardingStep) &&
            <div className={"grey-chip-interactive"} onClick={() => setSellerFlowCurrentStep(getOnboardingStepToPreviousStep(sellerFlow.serviceRequirements, sellerFlow.currentStep))}>
              <Typography variant="subtitle2" component="div">
                Go back
              </Typography>
            </div>
          }
        </div>
        {onboardingStep === "" &&
          <SellerOnboardingStep1ServiceRequirementContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "profile" &&
          <SellerOnboardingStep2ProfileInfoContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "title-and-escrow-services" &&
          <SellerOnboardingStep3TitleAndEscrowEligibilityContainer
            setStepSubmitting={setStepSubmitting}
          />
        }
        {onboardingStep === "title-and-escrow-meeting" &&
          <SellerOnboardingStep4TitleAndEscrowScheduleMeetingContainer />
        }
        {onboardingStep === "location-finder" &&
          <SellerOnboardingStep3CommunityNftLocationFinderContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "agent-details" &&
          <SellerOnboardingStep4CommunityNftAgentDetailsContainer
            setStepSubmitting={setStepSubmitting}
            recaptchaRef={recaptchaRef}
          />
        }
        {onboardingStep === "schedule-session" &&
          <SellerOnboardingStep5ScheduleMeetingContainer />
        }
        {onboardingStep === "listing-options" &&
          <SellerOnboardingStep5ListingOptionsContainer />
        }
        {onboardingStep === "schedule-session-complete" &&
          <SellerOnboardingThankYouContainer />
        }
      </Box>
    </div>
  )
};

export default SellerOnboardingSteps;