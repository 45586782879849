import React, { useState } from 'react';

import { toast } from 'sonner'

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormHelperText from '@mui/material/FormHelperText';

import { animated, useSpring, useInView } from '@react-spring/web';

import { PropsFromRedux } from '../../containers/SellerOnboardingSteps/SellerOnboardingStep1ServiceRequirementContainer';

import { 
  PROPY_BLUE,
  FLOW_TYPE_TO_LEAD_TYPE,
  SERVICE_REQUIREMENT,
} from '../../constants';

import useRecaptchaToken from '../../hooks/useRecaptchaToken';

import FloatingActionButton from '../FloatingActionButton';

import { FlowService } from '../../services/api';

import {
  ServiceRequirementsSellerEnum,
} from "../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '50%'
    },
    formTitle: {
      fontWeight: 'bold',
      fontSize: '26px',
    },
    formSubtitle: {
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      fontSize: '18px',
    },
    optionContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    optionSpacer: {
      marginTop: theme.spacing(2),
    },
    optionButton: {
      fontSize: '20px',
      padding: theme.spacing(4),
      borderRadius: 40,
    },
    optionButtonUnselected: {
      backgroundColor: 'white',
    },
    optionButtonSelected: {
      backgroundColor: PROPY_BLUE,
    },
    submitButtonContainer: {
      marginTop: theme.spacing(6),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      width: '100%',
      maxWidth: '250px',
    },
    customErrorHelperExtraSpace: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface IServiceRequirementOption {
  value: keyof typeof ServiceRequirementsSellerEnum;
  label: string;
}

const serviceRequirementOptions : IServiceRequirementOption[] = [
  {
    value: "exposure-to-propy-community",
    label: "Exposure to Propy Community",
  },
  {
    value: "title-and-escrow",
    label: "Title & Escrow Services",
  },
  {
    value: "mint-property-as-nft",
    label: "Mint Property as NFT",
  }
]

interface IStepProps {
  setStepSubmitting: (arg0: boolean) => void;
  recaptchaRef: any;
}

export default function SellerOnboardingStep1ServiceRequirement(props: PropsFromRedux & IStepProps) {

  const {
    setSellerFlowServiceRequirements,
    setSellerFlowCurrentStep,
    sellerFlow,
    setStepSubmitting,
    recaptchaRef,
  } = props;

  const getToken = useRecaptchaToken(recaptchaRef);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMissingInputWarning, setShowMissingInputWarning] = useState(false);

  const classes = useStyles();

  const toggleSelectedOptionIndex = (toggleOption: keyof typeof ServiceRequirementsSellerEnum) => {
    let newSelectedIndexes : Array<keyof typeof ServiceRequirementsSellerEnum> = [];
    for(let existingSelectionEntry of sellerFlow.serviceRequirements) {
      if((existingSelectionEntry !== toggleOption)) {
        newSelectedIndexes.push(existingSelectionEntry);
      }
    }
    if(sellerFlow.serviceRequirements.indexOf(toggleOption) === -1) {
      newSelectedIndexes.push(toggleOption);
    }
    if(newSelectedIndexes?.length > 0) {
      setShowMissingInputWarning(false);
    }
    setSellerFlowServiceRequirements(newSelectedIndexes);
  }

  const nextStep = async () => {
    // setBuyerFlowServiceRequirement(serviceRequirement);
    // // submit data to backend
    if(sellerFlow?.serviceRequirements?.length > 0) {
      if(!recaptchaRef?.current) {
        toast.error("Recaptcha expired, please refresh the page.");
      }
      if(sellerFlow.sessionId && recaptchaRef.current) {
        setStepSubmitting(true);
        setIsSubmitting(true);
        try {
          const token = await getToken();
          await FlowService.patchSession(sellerFlow.sessionId, {
            Type: FLOW_TYPE_TO_LEAD_TYPE["seller"],
            ...(sellerFlow.serviceRequirements && { Actions: sellerFlow.serviceRequirements.map((entry: keyof typeof ServiceRequirementsSellerEnum) => SERVICE_REQUIREMENT[entry]) }),
            ...(sellerFlow.firstName && { FirstName: sellerFlow.firstName }),
            ...(sellerFlow.lastName && { LastName: sellerFlow.lastName }),
            ...(sellerFlow.emailAddress && { Email: sellerFlow.emailAddress }),
            ...(sellerFlow.phoneNumber && { PhoneNumber: sellerFlow.phoneNumber }),
            ...(sellerFlow.phoneNumberCountry && { PhoneCountryCode: sellerFlow.phoneNumberCountry }),
            ...(sellerFlow.hasAgent && { HasAgent: sellerFlow.hasAgent === "has-agent" ? true : false }),
            ...(sellerFlow.agentEmailAddress && { AgentEmail: sellerFlow.agentEmailAddress }),
            ...(sellerFlow.agentPhoneNumber && { AgentPhoneNumber: sellerFlow.agentPhoneNumber }),
            ...(sellerFlow.agentPhoneNumberCountry && { AgentPhoneCountryCode: sellerFlow.agentPhoneNumberCountry }),
            ...(sellerFlow.agentFirstName && { AgentFirstName: sellerFlow.agentFirstName }),
            ...(sellerFlow.agentLastName && { AgentLastName: sellerFlow.agentLastName }),
            ...(sellerFlow.agentMessage && { MessageToAgent: sellerFlow.agentMessage }),
            ...(sellerFlow?.propertyLocationDescription && sellerFlow?.propertyLocationPlaceId && { location: { placeId: sellerFlow?.propertyLocationPlaceId, placeDesc: sellerFlow?.propertyLocationDescription } }),
            ...(!sellerFlow?.propertyLocationDescription && !sellerFlow?.propertyLocationPlaceId && { location: { placeId: null, placeDesc: null } }),
            ...(sellerFlow?.propertyMLS && { MLSNumber: sellerFlow?.propertyMLS }),
            ...(!sellerFlow?.propertyMLS && { MLSNumber: null }),
          }, token);
          setIsSubmitting(false);
          setStepSubmitting(false);
          toast.success('Saved progress!');
          setSellerFlowCurrentStep("profile");
        } catch (e) {
          setIsSubmitting(false);
          setStepSubmitting(false);
          console.log({e})
          setStepSubmitting(false);
          toast.error("Unable to save progress, please try again or contract support");
        }
      }
    } else {
      setShowMissingInputWarning(true);
    }
  }

  const [ref, isInView] = useInView({
    amount: 'any',
    once: true,
  })

  const stepperSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    to: {
      opacity: isInView ? 1 : 0,
      transform: isInView ? 'translateY(0)' : 'translateY(-25px)',
    },
    delay: 150
  })

  return (
    <animated.div ref={ref} style={stepperSpring}>
      <Typography className={classes.formTitle} gutterBottom variant="h5" component="div">
        How may we help?
      </Typography>
      <Typography className={classes.formSubtitle} variant="h6" component="h6">
        You may select multiple options.
      </Typography>
      <div className={classes.optionContainer}>
        {serviceRequirementOptions.map((entry, index) => 
          <FloatingActionButton 
            key={`deals-closed-option-${index}`}
            checkboxMode={true}
            checked={(sellerFlow.serviceRequirements && sellerFlow.serviceRequirements.indexOf(entry.value) > -1) ? true : false}
            onClick={() => typeof entry.value === "string" && toggleSelectedOptionIndex(entry.value)}
            className={
              [
                classes.optionButton,
                index > 0 ? classes.optionSpacer : "",
                (typeof entry.value === "string" && sellerFlow.serviceRequirements && sellerFlow.serviceRequirements.indexOf(entry.value) > -1) ? classes.optionButtonSelected : classes.optionButtonUnselected
              ].join(" ")
            }
            textColor={(sellerFlow.serviceRequirements && sellerFlow.serviceRequirements.indexOf(entry.value) > -1) ? 'white' : PROPY_BLUE}
            size="large"
            buttonColor={(sellerFlow.serviceRequirements && sellerFlow.serviceRequirements.indexOf(entry.value) > -1) ? "primary" : "default"}
            text={entry.label}
          />
        )}
      </div>
      {showMissingInputWarning &&
        <div className={classes.customErrorHelperExtraSpace}>
          <FormHelperText error={true}>Please select at least one option</FormHelperText>
        </div>
      }
      <div className={classes.submitButtonContainer}>
        <FloatingActionButton
          className={classes.submitButton}
          disabled={isSubmitting}
          onClick={nextStep}
          text="Next"
        />
      </div>
    </animated.div>
  );
}