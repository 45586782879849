import { connect, ConnectedProps } from 'react-redux';

import {
  setSellerFlowCurrentStep,
  setSellerFlowHasAgent,
  setSellerFlowAgentInfo,
  setSellerFlowAgentInfoEmailAddress,
  setSellerFlowAgentInfoPhoneNumber,
  setSellerFlowAgentInfoPhoneNumberCountry,
  setSellerFlowAgentInfoFirstName,
  setSellerFlowAgentInfoLastName,
  setSellerFlowAgentInfoMessage,
} from '../../state/actions';

import SellerOnboardingStep4CommunityNftAgentDetails from '../../components/SellerOnboardingSteps/SellerOnboardingStep4CommunityNftAgentDetails';

import { ISellerFlowData } from '../../interfaces';

interface RootState {
  darkMode: boolean;
  isConsideredMobile: boolean;
  sellerFlow: ISellerFlowData,
}
  
const mapStateToProps = (state: RootState) => ({
  sellerFlow: state.sellerFlow,
  darkMode: state.darkMode,
  isConsideredMobile: state.isConsideredMobile,
})

const mapDispatchToProps = {
  setSellerFlowCurrentStep,
  setSellerFlowHasAgent,
  setSellerFlowAgentInfo,
  setSellerFlowAgentInfoEmailAddress,
  setSellerFlowAgentInfoPhoneNumber,
  setSellerFlowAgentInfoPhoneNumberCountry,
  setSellerFlowAgentInfoFirstName,
  setSellerFlowAgentInfoLastName,
  setSellerFlowAgentInfoMessage,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)
  
export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SellerOnboardingStep4CommunityNftAgentDetails)